import React from "react";
import {IProduct} from "types";
import {PopularProductsWrapper} from "./PopularProductList.style";
import {ProductPreview} from "components/ProductPreview";
import ScrollContainer from "react-indiana-drag-scroll";
import {useGlobalSettings, useSettings} from '../../context';
import {useScrollOnStart} from '../../libs/hooks';


interface IPopularProductListProps {
  products: IProduct[]

}

export const PopularProductList: React.FC<IPopularProductListProps> = React.memo(({ products }) => {

  const { settings } = useGlobalSettings()
  const setRef = useScrollOnStart();

  return (
    <PopularProductsWrapper>
      {/*// @ts-ignore*/}
      <ScrollContainer innerRef={r => setRef(r)}  className="scroll-container" vertical={false} horizontal={true}>
        {products.map((product: IProduct) => (
          <div className={"swiper-slide"} key={product.id}>
            <ProductPreview product={product} isSmall={true} settings={settings}/>
          </div>
        ))}
      </ScrollContainer>
    </PopularProductsWrapper>
  )
})
