import React, {useMemo} from "react";
import {ProductModalWrapper} from "./ProductModal.style";
import {useCartContext, useGlobalSettings, useUI} from "context";
import { unitsByCode } from "libs/utils";
import {Modal, CustomButton} from "components/ui";
import {ProductImage} from "components/ProductImage";
import {Divider} from "antd";
import {useTranslate} from "@refinedev/core";
import {useChangeProductCount} from "context/hooks";
import {MoneyUnits} from "components/common/MoneyUnits";
import {LocaleName} from '../common/LocaleName';


export const ProductModal: React.FC = () => {
  const t = useTranslate()

  const { displayProductModal, selectedProduct, closeProductModal} = useUI();
  const { cart, products } = useCartContext();
  const { settings } = useGlobalSettings();

  const {
    productQuantity: addingProductQuantity,
    onAddItemWithDebounce,
    onDeleteItemWithDebounce,
  } = useChangeProductCount(selectedProduct, settings)

  const productQuantity = useMemo(() => {
    const q = products.reduce((count, p) => p.id === selectedProduct?.id ? count + p.quantity : count, 0) || 0
    return q + addingProductQuantity <= 0 ? 0 : q + addingProductQuantity
  }, [addingProductQuantity, products, selectedProduct])

  return (
    <Modal setVisible={closeProductModal} visible={displayProductModal}>
      {!!selectedProduct && (
        <ProductModalWrapper>
          <ProductImage product={selectedProduct} className={"modal-full-image"}/>
          <div className={"product-info-container"}>
            <div className={"product-title"}>
              <LocaleName entity={selectedProduct} langs={settings?.languages || []}/>
            </div>
            <div className={"product-specifications-container"}>
              <div className={"product-specifications"}>
                <div className={"flex"}>
                  {!!selectedProduct?.weight && (
                    <span className={"product-specification"}>{selectedProduct?.weight}<span className={"info-unit"}>
                    {t(`product.${unitsByCode(selectedProduct?.unit)}`)}</span></span>
                  )}

                  {!!selectedProduct?.calories && (
                    <span className={"product-specification"}>{selectedProduct?.calories}<span className={"info-unit"}>
                    {t("product.kcal")}</span></span>
                  )}
                </div>

                {(!!selectedProduct?.weight || !!selectedProduct?.calories) && (
                  <Divider/>
                )}

                <div className={"flex"}>
                  {!!selectedProduct?.proteins && (
                    <span className={"product-specification"}>{selectedProduct?.proteins}<span className={"info-unit"}>
                    {t("product.proteins")}</span></span>
                  )}

                  {!!selectedProduct?.fats && (
                    <span className={"product-specification"}>{selectedProduct?.fats}<span className={"info-unit"}>
                    {t("product.fats")}</span></span>
                  )}

                  {!!selectedProduct?.carbohydrates && (
                    <span className={"product-specification"}>{selectedProduct?.carbohydrates}<span className={"info-unit"}>
                    {t("product.carbohydrates")}</span></span>
                  )}
                </div>
              </div>
            </div>

            {products?.every(p => p.id !== selectedProduct.id) ? (
              <CustomButton className={"green-button"} onClick={onAddItemWithDebounce}>
                {selectedProduct?.price}&nbsp;<MoneyUnits/>
              </CustomButton>
            ) : (
              <div className={"product-counter"}>
                <span onClick={onDeleteItemWithDebounce}>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.8055 21.25H26.1857C26.8867 21.25 27.5 20.6807 27.5 19.9959C27.5 19.3193 26.8867 18.75 26.1857 18.75H13.8055C13.1396 18.75 12.5 19.3193 12.5 19.9959C12.5 20.6807 13.1396 21.25 13.8055 21.25Z" fill="white"/>
                  </svg>
                </span>
                <span className={"count"}>
                  <span style={{marginRight: '12px'}}>
                    {productQuantity}&nbsp;{t("product.thing")}
                  </span>
                  <span>
                    {cart?.lines.find(l => l.product_id === selectedProduct.id)?.amount}&nbsp;<MoneyUnits/>
                  </span>
                </span>
                <span onClick={onAddItemWithDebounce}>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 19.9956C12.5 20.7295 13.0944 21.3235 13.8287 21.3235H18.6801V26.1721C18.6801 26.8972 19.2657 27.5 20 27.5C20.7343 27.5 21.3287 26.8972 21.3287 26.1721V21.3235H26.1801C26.9056 21.3235 27.5 20.7295 27.5 19.9956C27.5 19.2705 26.9056 18.6765 26.1801 18.6765H21.3287V13.8279C21.3287 13.1028 20.7343 12.5 20 12.5C19.2657 12.5 18.6801 13.1028 18.6801 13.8279V18.6765H13.8287C13.0944 18.6765 12.5 19.2705 12.5 19.9956Z" fill="white"/>
                  </svg>
                </span>
              </div>
            )}
          </div>
        </ProductModalWrapper>
      )}
    </Modal>
  )
}