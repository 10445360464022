import {Button, ButtonProps} from "antd";
import React from "react";
import styled from "styled-components";


export const CustomButton: React.FC<CustomButtonProps> = ((props) => {
  const {
    children
  } = props;

  return (
    <ButtonWrapper className={props?.wrapClassName || ''}>
      <Button {...props} className={`button ${props.className} button-text`}>
        {children}
      </Button>
    </ButtonWrapper>
  );
});

CustomButton.displayName = "CustomButton";


interface CustomButtonProps extends ButtonProps {
  className?: string;
  wrapClassName?: string;
}

export const ButtonWrapper = styled.span`
  &.wrap-absolute {
    position: absolute;
    bottom: 40px;
  }

  &.wrap-end {
    display: flex;
    justify-content: end;
  }

  /* BUTTON */

  .button {
    min-width: 70px;
    display: flex;
    justify-content: center;
    
    padding-block: 20px 22px;
    padding-inline: 24px;
    height: auto;
    border: none;

    ${({ theme: { fonts } }) => fonts.subheaderXSBolder};
  }

  .button:focus, .button:hover {
    border: none;
    color: ${({ theme: { colors } }) => colors.white};

    &.red-button,
    .button-text {
      color: ${({ theme: { colors } }) => colors.white};
      border: none
    }
  }

  .button-text {
    /* identical to box height, or 111% */
    display: flex;
    align-items: center;

    /* white */
    color: ${({ theme: { colors } }) => colors.white};
  }

  .gray-button {
    padding-block: 20px 22px;
    padding-inline: 24px;
    /* gp-gray-93 */

    background: ${({ theme: { colors } }) => colors.lightGray};
    border-radius: 20px;

    color: ${({ theme: { colors } }) => colors.textBlack};

    &.button:focus, &.button:hover {
      color: ${({ theme: { colors } }) => colors.textBlack};

      .button-text {
        color: ${({ theme: { colors } }) => colors.textBlack};
      }
    }
  }

  .red-button {
    /* gp-red */
    background: ${({ theme: { colors } }) => colors.red};
    border-radius: 20px;

    &:disabled {
      background: ${({ theme: { colors } }) => colors.gray};
    }
  }

  .white-button {
    background: ${({ theme: { colors } }) => colors.white};
    border-radius: 20px;
    color: ${({ theme: { colors } }) => colors.red};

    &.button:focus, &.button:hover {
      color: ${({ theme: { colors } }) => colors.red};

      .button-text {
        color: ${({ theme: { colors } }) => colors.red};
      }
    }
  }

  .violet-button {
    background: ${({ theme: { colors } }) => colors.white};
    border-radius: 20px;
    color: ${({ theme: { colors } }) => colors.violet};

    &.button:focus, &.button:hover {
      color: ${({ theme: { colors } }) => colors.violet};

      .button-text {
        color: ${({ theme: { colors } }) => colors.violet};
      }
    }
  }

  .transparent-button {
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.20);
    color: ${({ theme: { colors } }) => colors.white};

    &.button:focus, &.button:hover {
      color: ${({ theme: { colors } }) => colors.white};

      .button-text {
        color: ${({ theme: { colors } }) => colors.white};
      }
    }
  }

  .green-button {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-block: 20px 22px;
    padding-inline: 24px;
    gap: 8px;

    background: ${({ theme: { colors } }) => colors.green};
    border-radius: 20px;

    ${({ theme: { fonts } }) => fonts.subheaderXSBolder};
    color: ${({ theme: { colors } }) => colors.white};

    &.big-size {
      max-height: 84px;
      white-space: break-spaces;
      font-size: 20px;
      line-height: 24px;
      padding-block: 30px;
      padding-inline: 24px;
      border-radius: 28px;
    }

    &.button:focus, &.button:hover {
      color: ${({ theme: { colors } }) => colors.white};

      .button-text {
        color: ${({ theme: { colors } }) => colors.white};
      }
    }
    
    &:disabled {
      background: ${({ theme: { colors } }) => colors.gray};
    }
  }
`
