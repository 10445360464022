import React, {useCallback, useEffect} from "react";
import {PaymentModalWrapper} from "./Payment.style";
import {SelectPaymentMethod} from "./PaymentModalStates/PaymentMethods";
import {EBonusSystemType, EPaymentMethods, EPaymentSteps} from "types/constants";
import {WriteOffBonus} from "./PaymentModalStates/WriteOffBonus";
import {FinishPayment} from "./PaymentModalStates/FinishPayment";
import {useCartContext, useGlobalSettings, usePayment} from "context";
import {CustomButton, Modal} from "components/ui";
import {ConfirmResetCartModalWrapper} from "containers/Cart/Cart.style";
import {useTranslate} from "@refinedev/core";
import {AdditionalProducts} from "./PaymentModalStates/AddtionalProducts";
import {YandexFoodCodeModal} from "./PaymentModalStates/YandexFoodCodeModal";
import {useGetMaxBonusesValue} from '../../libs/hooks';


export const PaymentModal: React.FC = () => {

  const t = useTranslate()
  const { settings } = useGlobalSettings()
  const { isLoading: isRecalculateLoading } = useCartContext()
  const { displayPayment, paymentStep, isPaymentLoading, confirmClosedPaymentModal, onClearBonusCard,
    isCheckingBonusCardNumber, selectedPaymentMethod, bonusCard, paymentError, paymentErrorMessage,
    onChangePaymentStep, onConfirmClosedPaymentModal, onCloseDisplayPayment, onCancelClosedPaymentModal, onSelectPaymentMethod } = usePayment()

  const { canFullPayment, payWithMaxBonuses } = useGetMaxBonusesValue()

  const _makeBackStep = useCallback((currentStep: EPaymentSteps | null) => {
    const showWriteOffBonusScreen = (Object.values(EBonusSystemType).includes(selectedPaymentMethod as unknown as EBonusSystemType) && bonusCard?.cardId && !payWithMaxBonuses) || (
      payWithMaxBonuses && bonusCard?.userId && !canFullPayment)

    const step = currentStep ?? (showWriteOffBonusScreen ? EPaymentSteps.WRITE_OFF_BONUSES : EPaymentSteps.PAYMENT_METHODS)

    if (step === EPaymentSteps.PAYMENT_METHODS) {
      onSelectPaymentMethod(null)
      onClearBonusCard()
    } else {
      if (payWithMaxBonuses) {
        onSelectPaymentMethod(null)
      }
    }

    onChangePaymentStep(step)
  }, [EBonusSystemType, EPaymentSteps, selectedPaymentMethod, bonusCard, payWithMaxBonuses, canFullPayment])

  const handleModalClose = () => {
    if (isCheckingBonusCardNumber || isPaymentLoading && ([EPaymentMethods.PAYMENT_TYPE_CARD, EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE].includes(selectedPaymentMethod as unknown as EPaymentMethods) ||
      bonusCard?.cardId || [EPaymentMethods.PAYMENT_TYPE_YANDEX_EDA, EPaymentMethods.PAYMENT_TYPE_YANDEX_QR].includes(selectedPaymentMethod as EPaymentMethods))) {
      return;
    }
    if (confirmClosedPaymentModal) {
      onCloseDisplayPayment()
    } else {
      onConfirmClosedPaymentModal()
    }
  }

  return (
    <>
      <Modal maxWidth={paymentStep === EPaymentSteps.ADDITIONAL_PRODUCT ? '1088px' : '1074px'} visible={displayPayment}
             setVisible={handleModalClose}>
        {confirmClosedPaymentModal ? (
          <ConfirmResetCartModalWrapper className={"small-height"}>
            <div className={"confirm-title"}>
              {t("cart.cancelShopping.title")}
            </div>

            <div className={"confirm-buttons"}>
              <CustomButton className={"gray-button"} onClick={() => onCancelClosedPaymentModal()}>{t("cart.cancelShopping.buttons.cancel")}</CustomButton>
              <CustomButton className={"red-button"} onClick={() => {
                onCloseDisplayPayment()
              }}>{t("cart.cancelShopping.buttons.confirm")}</CustomButton>
            </div>
          </ConfirmResetCartModalWrapper>
        ) : (
          <PaymentModalWrapper additionalPaymentStep={paymentStep === EPaymentSteps.ADDITIONAL_PRODUCT}
                               paymentMethodsStep={paymentStep === EPaymentSteps.PAYMENT_METHODS}>
            {paymentStep === EPaymentSteps.ADDITIONAL_PRODUCT && (
              <AdditionalProducts
                goNextStep={() => onChangePaymentStep(EPaymentSteps.PAYMENT_METHODS)}
                goPreviousStep={handleModalClose}
              />
            )}

            {paymentStep === EPaymentSteps.PAYMENT_METHODS && (
              <SelectPaymentMethod
                goNextStep={(step) => onChangePaymentStep(step ?? EPaymentSteps.FINISH_PAYMENT)}
                goPreviousStep={handleModalClose}
              />
            )}

            {paymentStep === EPaymentSteps.WRITE_OFF_BONUSES && (
              <WriteOffBonus
                goNextStep={() => onChangePaymentStep(EPaymentSteps.FINISH_PAYMENT)}
                goPreviousStep={() => _makeBackStep(EPaymentSteps.PAYMENT_METHODS)}
              />
            )}

            {paymentStep === EPaymentSteps.FINISH_PAYMENT && (
              <FinishPayment
                goNextStep={() => console.log('stop!')}
                goPreviousStep={() => _makeBackStep(null)}
              />
            )}
          </PaymentModalWrapper>
        )}
      </Modal>
      <YandexFoodCodeModal/>
    </>
  )
}
