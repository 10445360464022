import styled from "styled-components";

export const CatalogContainer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;

  .ant-skeleton {
    height: 278px;
    overflow: hidden;
    
    .ant-skeleton-title {
      display: none;
    }

    .ant-skeleton-paragraph {
      height: 278px;
      margin-top: 0;
      margin-bottom: 8px;
      margin-block-start: 0!important;

      li {
        height: 278px;
        border-start-start-radius: 40px;
        border-start-end-radius: 0;
        border-end-end-radius: 0;
        border-end-start-radius: 40px;
      }
    }

    &.catalog-skeleton {
      height: 223px;

      .ant-skeleton-paragraph {
        height: 223px;

        li {
          height: 223px;
        }
      }
    }
    
    &.popular-skeleton {
      height: 390px;

      .ant-skeleton-paragraph {
        height: 390px;
        margin-bottom: 0;
        
        li {
          height: 390px;
        }
      }
    }
  }
`;

export const CatalogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  
  &.promotion-name {
    text-align: center;
    .default-header {
      flex: 1;
      ${({ theme: { fonts } }) => fonts.subheaderSBolder};
    }
  }
`;


export const SectionWrapper = styled.div<{
  isEmpty?: boolean
}>`
  padding-block-start: 26px;
  padding-block-end: 36px;
  padding-inline-start: 40px;

  &.dark-background {

  }

  max-height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  background-size: 80vw!important;
  animation: gradient 1.8s ease infinite;

  border-start-start-radius: 40px;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 40px;

  @keyframes gradient {
    0% {
      background-position: -30vw;
    }
    100% {
      background-position: 50vw;
    }
  }

  &.preview {
    transition: all ease 0.5s;

    &.popular {
      max-height: 390px;
      min-height: 390px;
    }

    &.promotions {
      max-height: 278px;
      min-height: 278px;
    }

    &.catalog {
      max-height: 223px;
      min-height: 223px;
    }
    
    &.opened {
      transform: translateX(0);
      opacity: 1;
    }
    
    &.closed {
      transform: translateX(${({theme: {rtl}}) => rtl ? '-' : ''}1500px);
      opacity: 0;
    }
  }
  
  &.expand-version {
    padding-block-start: 26px;
    padding-block-end: 0;
    padding-inline-start: 40px;
    
    transition: all ease 0.5s;
    position: absolute;
    width: 50vw;
    //min-height: 907px;

    height: calc(100vh - 133px);

    &.opened {
      top: 0;
      inset-inline-start: 0;
      opacity: 1;
    }

    &.closed {
      inset-inline-start: 1000px;
      opacity: 0;
    }
    
    @media (max-width: 760px) {
      width: 100%;
    }
  }
  
  &.margin-bottom {
    margin-bottom: 8px;
  }
  
  .preview-header-icon {
    padding-inline-end: 40px;
    svg {
      cursor: pointer;
    }
  }
`;
