import React, {useMemo, useState} from "react";
import {useTranslate} from "@refinedev/core";
import {Divider, Slider} from "antd";
import {SliderMarks} from "antd/es/slider";
import {useCartContext, useCatalogData, useGlobalSettings, usePayment} from "context";
import {IPaymentStepsProps} from "types";
import {AdditionalProductsWrapper} from "components/Payment/Payment.style";
import {CustomButton} from "components/ui";
import {ProductList} from "components/ProductList";


export const AdditionalProducts: React.FC<IPaymentStepsProps> = ({goNextStep, goPreviousStep}) => {

  const t = useTranslate()
  const { settings } = useGlobalSettings();
  const { extraForOrderProducts } = useCatalogData()

  return (
    <AdditionalProductsWrapper>
      <div className={"modal-header"}>{t("payment.additionalProducts.header")}</div>

      <ProductList settings={settings} products={extraForOrderProducts}/>

      <div className={"payment-buttons"}>
        <CustomButton className={"gray-button"} onClick={() => goPreviousStep()}>{t("payment.buttons.back")}</CustomButton>
        <CustomButton className={"red-button"} onClick={() => goNextStep()}>{t("payment.buttons.next")}</CustomButton>
      </div>
    </AdditionalProductsWrapper>
  )
}