import React, {FormEvent, InputHTMLAttributes, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useGlobalSettings, useUI} from "context";
import { useNavigate } from 'react-router-dom';
import {API_URL, SCANNING_CODE_TIMEOUT_MS} from "types/constants";
import urlJoin from "url-join";


interface IInputForScanning {
  elementId: "bonus-cart" | "scan-marking-code" | "scan-product" | "yandex-payment" | "promo"
  onSubmit: (code: string, codeWithoutSpace?: string) => void,
  active?: boolean
}

let scanTimeout: { [key: string] : string | number | NodeJS.Timeout | null | undefined } = {};

const InputForScanning: React.FC<IInputForScanning> = ({ elementId, onSubmit, active = true }) => {

  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)
  let flagRef = useRef<any>(false)
  const { settings } = useGlobalSettings()


  useEffect(() => {
    if (!flagRef?.current && active) {
      flagRef = {current: true}

      if (inputRef?.current?.id && document.activeElement?.id !== inputRef?.current?.id) {
        // TODO: двойное срабатывание фокуса убирает курсор
        setTimeout(() => {
          inputRef?.current?.focus()
        }, 50)
      }
    }
  }, [inputRef, active])

  const handleScanInputBlur = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (elementId && active) {
      // const input = document.getElementById(elementId)
      if (inputRef?.current && document.activeElement?.id !== inputRef?.current?.id) {
        inputRef?.current?.focus({preventScroll: true})
      }
    }
  }

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('Таймаут:', scanTimeout[elementId])
    if (scanTimeout?.[elementId]) {
      // @ts-ignore
      clearTimeout(scanTimeout[elementId]);
    }

    scanTimeout[elementId] = setTimeout(async () => {
      const scannedCode = e?.target?.value?.trim() || '';
      await _scanningFinish(scannedCode);
    }, SCANNING_CODE_TIMEOUT_MS);
  }

  const handleScanCodeFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.stopPropagation()
    e.preventDefault()

    // const scannedCode = inputRef?.current?.value?.trim() || '';
    //
    // if (!scannedCode) {
    //   return;
    // }
    //
    // if (scanTimeout) {
    //   clearTimeout(scanTimeout);
    // }
    //
    // await _scanningFinish(scannedCode)
  }

  const _scanningFinish = async (scannedCode: string) => {
    const textWithoutSpace = scannedCode.replace(/(\s|\n|\r)/gm, "");

    if (!textWithoutSpace) {
      return;
    }

    console.log(`Marking code: ${scannedCode}`);
    console.log(`Marking code without space: ${textWithoutSpace}`);
    console.log(`Marking code elementId: ${elementId}`);


    if (elementId === "yandex-payment" || elementId === "scan-marking-code") {
      onSubmit(scannedCode)
    } else {
      if (settings?.maintenance.activationCardNumbers.some(n => n === textWithoutSpace)) {
        try {
          await fetch(urlJoin(API_URL, '/maintenance/signin/'), {
            method: 'POST',
            headers: { 'content-type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ card_number: textWithoutSpace }),
          })
            .then(() => {
              navigate('./maintenance')
            })
          return;
        } catch (e) {}
      }
      if (elementId === "bonus-cart" || elementId === "promo") {
        onSubmit(textWithoutSpace)
      } else {
        onSubmit(scannedCode, textWithoutSpace)
      }
    }

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  //console.log("Отрендерен инпут с elementId: ", elementId)
  //console.log("scanTimeout в данный момент: ", scanTimeout)

  return (
    <form style={{ position: 'fixed', bottom: '100054px', opacity: 0}}
          id={`form-${elementId}`}
          onKeyUp={e => { e.preventDefault(); e.stopPropagation(); }}
          onSubmit={handleScanCodeFormSubmit}
    >
      <input className={"marking-input"} type="text" name={elementId} id={elementId} onChange={onChangeValue}
             ref={inputRef} onBlur={handleScanInputBlur}/>
    </form>
  )
}

export default React.memo(InputForScanning)
