import React, {FC, PropsWithChildren, useCallback, useMemo, useState} from "react";
import {IProduct} from "types";

export enum ECatalogMode {
  promo = "promo",
  catalog = "catalog",
  popular = "popular"
}


export interface UIState {
  isInactivityUser?: boolean;
  displayCatalog: boolean;
  displayBonusAuthModal: boolean;
  catalogMode: ECatalogMode | null;
  displayProductModal?: boolean;
  displayPromoModal?: boolean;
  displayKeyBoard?: boolean;
  displaySearchResults?: boolean;
  selectedProduct: IProduct | null;
}

export interface UIContextType extends UIState {
  resetUIState: () => void;
  openCatalog: () => void;
  closeCatalog: () => void;
  setCatalogMode: (mode: ECatalogMode | null) => void;
  openProductModal: () => void;
  closeProductModal: () => void;
  togglePromoModal: () => void;
  toggleInactivityUser: () => void;
  toggleDisplaySearchResults: (isDisplay: boolean) => void;
  toggleDisplayBonusAuthModal: (isDisplay?: boolean) => void;
  openKeyBoard: () => void;
  closeKeyBoard: () => void;
  setSelectedProduct: (product: IProduct | null) => void;
}

const initialState = {
  isInactivityUser: true,
  displaySearchbar: false,
  displayCatalog: false,
  catalogMode: null,
  displayProductModal: false,
  displayPromoModal: false,
  displayKeyBoard: false,
  displaySearchResults: false,
  selectedProduct: null,
  displayBonusAuthModal: false
};

type Action =
  | {
  type: "OPEN_PRODUCT_MODAL";
}
  | {
  type: "CLOSE_PRODUCT_MODAL";
}
  | {
  type: "TOGGLE_PROMO_MODAL";
}
  | {
  type: "TOGGLE_DISPLAY_SEARCH_RESULTS";
  isDisplay: boolean
}
  | {
  type: "SELECT_PRODUCT_MODAL";
  product: IProduct | null
}
  | {
  type: "SET_CATALOG_MODE";
  mode: ECatalogMode | null
}
  | {
  type: "OPEN_CATALOG";
}
  | {
  type: "CLOSE_CATALOG";
}
  | {
  type: "OPEN_KEYBOARD";
}
  | {
  type: "CLOSE_KEYBOARD";
}
  | {
  type: "RESET_STATE";
}
  | {
  type: "TOGGLE_INACTIVITY_USER";
} | {
  type: "TOGGLE_DISPLAY_BONUS_AUTH_MODAL";
  isDisplay?: boolean
}


export const UIContext = React.createContext<UIState>(initialState);

UIContext.displayName = "UIContext";

function uiReducer(state: UIState, action: Action) {
  switch (action.type) {
    case "OPEN_CATALOG": {
      return {
        ...state,
        displayCatalog: true,
      };
    }
    case "CLOSE_CATALOG": {
      return {
        ...state,
        displayCatalog: false,
      };
    }
    case "TOGGLE_INACTIVITY_USER": {
      return {
        ...state,
        isInactivityUser: !state?.isInactivityUser,
      };
    }
    case "TOGGLE_PROMO_MODAL": {
      return {
        ...state,
        displayPromoModal: !state?.displayPromoModal,
      };
    }
    case "TOGGLE_DISPLAY_SEARCH_RESULTS": {
      return {
        ...state,
        displaySearchResults: action.isDisplay,
      };
    }
    case "TOGGLE_DISPLAY_BONUS_AUTH_MODAL": {
      return {
        ...state,
        displayBonusAuthModal: !!action.isDisplay,
      };
    }
    case "SELECT_PRODUCT_MODAL": {
      return {
        ...state,
        selectedProduct: action.product,
      };
    }
    case "SET_CATALOG_MODE": {
      return {
        ...state,
        catalogMode: action.mode,
      };
    }
    case "OPEN_PRODUCT_MODAL": {
      return {
        ...state,
        displayProductModal: true,
      };
    }
    case "CLOSE_PRODUCT_MODAL": {
      return {
        ...state,
        displayProductModal: false,
        selectedProduct: null,
      };
    }
    case "OPEN_KEYBOARD": {
      return {
        ...state,
        displayKeyBoard: true,
      };
    }
    case "CLOSE_KEYBOARD": {
      return {
        ...state,
        displayKeyBoard: false,
      };
    }
    case "RESET_STATE": {
      return {
        ...state,
        ...initialState,
        isInactivityUser: state.isInactivityUser
      }
    }
  }
}

export const UIProvider: FC<PropsWithChildren> = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);

  const openCatalog = useCallback(
    () => dispatch({type: "OPEN_CATALOG"}),
    [dispatch],
  );
  const closeCatalog = useCallback(
    () => dispatch({type: "CLOSE_CATALOG"}),
    [dispatch],
  );

  const openProductModal = useCallback(
    () => dispatch({type: "OPEN_PRODUCT_MODAL"}),
    [dispatch],
  );
  const closeProductModal = useCallback(
    () => dispatch({type: "CLOSE_PRODUCT_MODAL"}),
    [dispatch],
  );

  const openKeyBoard = useCallback(
    () => dispatch({type: "OPEN_KEYBOARD"}),
    [dispatch],
  );
  const closeKeyBoard = useCallback(
    () => dispatch({type: "CLOSE_KEYBOARD"}),
    [dispatch],
  );

  const togglePromoModal = useCallback(
    () => dispatch({type: "TOGGLE_PROMO_MODAL"}),
    [dispatch],
  );

  const toggleDisplaySearchResults = useCallback(
    (isDisplay: boolean) => dispatch({type: "TOGGLE_DISPLAY_SEARCH_RESULTS", isDisplay}),
    [dispatch],
  );

  const toggleInactivityUser = useCallback(
    () => dispatch({type: "TOGGLE_INACTIVITY_USER"}),
    [dispatch],
  );

  //TOGGLE_DISPLAY_BONUS_AUTH_MODAL
  const toggleDisplayBonusAuthModal = useCallback(
    (isDisplay: boolean) => dispatch({type: "TOGGLE_DISPLAY_BONUS_AUTH_MODAL", isDisplay}),
    [dispatch],
  );

  const setSelectedProduct = useCallback(
    (product: IProduct | null) => dispatch({type: "SELECT_PRODUCT_MODAL", product}),
    [dispatch],
  );

  const setCatalogMode = useCallback(
    (mode: ECatalogMode | null) => dispatch({type: "SET_CATALOG_MODE", mode}),
    [dispatch],
  );

  const resetUIState = useCallback(
    () => dispatch({type: "RESET_STATE"}),
    [dispatch],
  );

  const value = useMemo(
    () => ({
      ...state,
      resetUIState: resetUIState,
      openCatalog: openCatalog,
      closeCatalog: closeCatalog,
      setCatalogMode: setCatalogMode,
      openProductModal: openProductModal,
      closeProductModal: closeProductModal,
      openKeyBoard: openKeyBoard,
      closeKeyBoard: closeKeyBoard,
      togglePromoModal: togglePromoModal,
      toggleDisplaySearchResults: toggleDisplaySearchResults,
      toggleInactivityUser: toggleInactivityUser,
      setSelectedProduct: setSelectedProduct,
      toggleDisplayBonusAuthModal: toggleDisplayBonusAuthModal,
    }),
    [state, dispatch],
  );

  return <UIContext.Provider value={value} {...props} />;
};

export const useUI = (): UIContextType => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context as UIContextType;
};

export const ManagedUIContext: FC<PropsWithChildren> = ({children}) => (
  <UIProvider>
    {children}
  </UIProvider>
);
