import {LoaderWrapper} from "components/common/Loader/Loader";
import styled from "styled-components";


export const BonusCardAuthWrapper = styled.div<{
  isError?: boolean,
  isLogin?: boolean,
}>`

  display: flex;
  align-items: center;

  .keyboard {
    display: flex;
    flex-direction: column;
  }

  .vertical-divider {
    margin: 0 70px;
    width: 2px;
    height: 180px;
    background: ${({theme: {colors}}) => colors.yellow};
  }

  .method-image {
    position: relative;
    min-width: ${({isLogin}) => isLogin ? '360px' : '320px'};
    max-width: ${({isLogin}) => isLogin ? '360px' : '320px'};
    height: ${({isLogin}) => isLogin ? '362px' : '322px'};
    background: ${({theme: {colors}}) => colors.white};
    overflow: hidden;

    &.method-with-qr-code {
      border-radius: 0;
    }

    .img-wrapper {
      border: 10px solid ${({theme: {colors}, isError}) => isError ? colors.coralRed : colors.lightGray};
      border-radius: 1000px;
      overflow: hidden;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 270px;
        height: 270px;
        object-fit: contain;
      }
      
      &.circle-image {
        img {
          width: 105%;
          height: 105%;
          
          &.obed-ru-image {
            width: 138%;
            margin-left: 50px;
            margin-top: -16px;
          }
        }
      }

      &.go-poedim {
        img {
          width: 220px;
          height: 220px;
        }
      }
    }
  }

  .loader-container {
    position: absolute;
    z-index: 90;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 4px;
    min-width: ${({isLogin}) => isLogin ? '360px' : '320px'};
    max-width: ${({isLogin}) => isLogin ? '360px' : '320px'};
    height: ${({isLogin}) => isLogin ? '360px' : '320px'};

    background: ${({theme: {colors}}) => colors.white};
    background: transparent;

    ${LoaderWrapper} {
      transform: translateY(0);

      svg {
        min-width: ${({isLogin}) => isLogin ? '360px' : '320px'};
        max-width: ${({isLogin}) => isLogin ? '360px' : '320px'};
        height: ${({isLogin}) => isLogin ? '360px' : '320px'};

        circle {
          stroke-width: 8px;
          r: 80px
        }
      }
    }
  }

  .bonus-card-input-wrapper {
    cursor: pointer;
    display: flex;
    min-width: 380px;
    align-items: center;

    max-width: 380px;
    border: none;
    background: ${({theme: {colors}}) => colors.lightGray};
    border-radius: 16px;
    padding: 14px 16px;
    height: 68px;
    margin-bottom: 8px;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }

  .bonus-card-input {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({theme: {fonts}}) => fonts.captionS};

    text-align: center;
    color: ${({theme: {colors}}) => colors.gray};
    background: transparent;

    &.success-value {
      color: ${({theme: {colors}}) => colors.green};
    }
  }

  .bonus-card-input-suffix {
    cursor: pointer;
    
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ${LoaderWrapper} {
      transform: translateY(0);

      svg {
        width: 40px;
        height: 40px;

        circle {
          stroke-width: 10px;
          r: 44px;

          &.path {
            stroke-dasharray: 60, 360;
          }
        }
      }
    }
  }

  @media (max-width: 760px) {
    .bonus-card-input-wrapper {
      min-width: 360px;
    }
  }
`;
