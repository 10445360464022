import React, {FC, PropsWithChildren, useCallback, useEffect, useState} from 'react';
import {Layout, Menu, Modal, Spin} from 'antd';
import {
  CheckCircleOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PoweroffOutlined,
  LoadingOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons';
import MainApi from 'maintenance/api/MainApi';
import {TOperation} from 'maintenance/libs/types';
import { DEFAULT_ERROR_MESSAGE, OperationStatus, ProcessTypes, UNKNOWN_ERROR_MESSAGE } from 'maintenance/libs/helpers/common';
import {useGetLocale, useSetLocale, useTranslate} from '@refinedev/core';
import i18n from 'i18next';

const api = new MainApi();

const {Content, Sider} = Layout;
const antIcon = <LoadingOutlined style={{fontSize: 36}} spin/>;

interface MaintenanceAppLayout extends PropsWithChildren {
  operations: TOperation[], onPauseIdleTimer?: () => void, onStartIdleTimer?: () => void
}

const AppLayout: FC<MaintenanceAppLayout> = ({
                                                       children,
                                                       operations, onPauseIdleTimer, onStartIdleTimer
                                                     }) => {

  const t = useTranslate()

  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const currentLocale = locale();

  const [initLocale, setInitLocale] = useState(currentLocale || 'ru')

  useEffect(() => {
    if (i18n.languages.includes('ar')) {
      changeLanguage('en')
    }
  }, [])

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [siderOperations, setSiderOperations] = useState<TOperation[]>(operations);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalProcessVisible, setIsModalProcessVisible] = useState(false);
  const [currentCommand, setCurrentCommand] = useState<TOperation | null>(null);

  const [error, setError] = useState<boolean>(false);
  const [isNetworkError, setIsNetworkError] = useState<boolean>(false);
  const [pendingExecution, setPendingExecution] = useState<boolean>(false);
  const [successExecution, setSuccessExecution] = useState<boolean>(false);

  useEffect(() => {
    setSiderOperations(operations);
  }, [operations]);

  useEffect(() => {
    if (currentCommand) {
      handleDoOperation(currentCommand);
    }
  }, [currentCommand]);


  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleHideModal = () => {
    setIsModalVisible(false);
  };

  const handleHideModalProcess = useCallback(() => {
    setCurrentCommand(null);
    setError(false);
    setIsNetworkError(false);
    setSuccessExecution(false);
    setIsModalProcessVisible(false);
    if (onStartIdleTimer) {
      onStartIdleTimer();
    }

  }, [onStartIdleTimer]);

  const handleDoOperation = async (command: TOperation | null) => {
    setIsModalProcessVisible(true);
    setPendingExecution(true);
    if (onPauseIdleTimer) {
      onPauseIdleTimer();
    }
    try {
      const result = await api.doOperation(command?.id || null, ProcessTypes.START);
      if (result.data?.status === OperationStatus.SUCCESS) {
        setError(false);
        setSuccessExecution(true);
      } else {
        setError(true);
      }
    } catch (e: any) {
      //setIsModalProcessVisible(false);
      setIsNetworkError(true);
    }
    setPendingExecution(false);
  };

  const trigger = (
    <>
      <span className="arrow-left"><ArrowLeftOutlined/></span>
      <span className="arrow-right"><ArrowRightOutlined/></span>
    </>
  );

  return (
    <Layout style={{minHeight: '100vh'}}>
      <Modal
        title="Modal"
        width={800}
        visible={isModalVisible}
        onOk={() => {
          changeLanguage(initLocale)
          window.location.href = '/';
        }}
        onCancel={handleHideModal}
        wrapClassName={"modal-mode"}
        okText={t("layout.modalOut.ok", {
          ns: "maintenance"
        })}
        cancelText={t("layout.modalOut.cancel", {
          ns: "maintenance"
        })}
      >
        <p>{t("layout.modalOut.description", {
          ns: "maintenance"
        })}</p>
      </Modal>

      <Modal
        title={currentCommand?.title}
        width={600}
        visible={isModalProcessVisible}
        onCancel={handleHideModalProcess}
        wrapClassName={"modal-mode"}
        footer={null}
        closable={!pendingExecution}
      >
        {isNetworkError ? (
          <p><ExclamationCircleOutlined style={{color: 'red'}}/> {UNKNOWN_ERROR_MESSAGE}</p>
        ) : (
          <>
            {successExecution ? (
              <p><CheckCircleOutlined
                style={{color: 'green'}}/> {`${t("layout.modalStatus.success", {
                ns: "maintenance"
              })} "${currentCommand?.title}"`}</p>
            ) : (
              <>
                {error ? (
                  <p><ExclamationCircleOutlined style={{color: 'red'}}/> {currentCommand?.error_instruction || DEFAULT_ERROR_MESSAGE}</p>
                ) : (
                  <Spin spinning={true} size="large" delay={500} indicator={antIcon}>
                    <p>{`${t("layout.modalStatus.process", {
                      ns: "maintenance"
                    })} "${currentCommand?.title}"...`}</p>
                  </Spin>
                )}
              </>
            )}
          </>
        )}
      </Modal>

      <Sider collapsible
             collapsedWidth={0}
             collapsed={collapsed}
             className={"custom-sider-width"}
             onCollapse={handleCollapse}
             zeroWidthTriggerStyle={{
               width: '62px',
               height: '67px',
               right: 'auto',
               top: '36px',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center'
             }}
             trigger={trigger}
             width={420}
      >
        <div className="logo"/>
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline"
              onClick={({key}) => {
                setCurrentCommand(siderOperations.find(c => `${c?.id}` === key) || null);
              }}
              style={{
                padding: '112px 0 32px 0',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                fontSize: '24px'
              }}>
          {siderOperations.map(command => (
            <Menu.Item key={command?.id} className={'custom-sider-item'}>
              {command?.title}
            </Menu.Item>
          ))}


          <Menu.Item key="9"
                     className={'power-off-button'}>
            <span style={{display: 'flex', flex: 1, alignItems: 'center', letterSpacing: '-0.6px'}}
                  onClick={() => {
                    setIsModalVisible(true)
                  }}>
              <PoweroffOutlined style={{marginInlineEnd: '10px', fontSize: '24px'}}/>
              {t("layout.sider.out", {
                ns: "maintenance"
              })}
            </span>

          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content>
          <div className="site-layout-background" style={{minHeight: 360}}>
            {children}
          </div>
        </Content>
        {/*<Footer style={{textAlign: 'center'}}>Ant Design ©2018 Created by Ant UED</Footer>*/}
      </Layout>
    </Layout>
  );
};

export default AppLayout;