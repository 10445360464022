import urlJoin from 'url-join';

type TUrl = string | null
export const API_URL: TUrl = urlJoin(process.env.REACT_APP_BACKEND_BASE_URL || '', '/api/v1') || 'https://127.0.0.1:8080/api/v1';

export const INITIAL_ITEM_INDEX = 2;

export const UNKNOWN_ERROR_MESSAGE: string = 'Произошла ошибка соединения с сервером';

export const DEFAULT_ERROR_MESSAGE: string = 'Произошла ошибка при выполнении';

export const DATE_FORMAT: string = 'D MMMM YYYY';

export const DATETIME_FORMAT: string = 'DD MMMM YYYY HH:mm:ss';

export const SERVER_DATE_FORMAT: string = 'D/M/YYYY';

export enum ProcessTypes {
  START = 'start',
  CONTINUE = 'continue',
  RETRY = 'retry'
}

export enum OperationStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}
