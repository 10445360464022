import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Layout, Modal, Timeline, Typography } from 'antd';
import MainApi from 'maintenance/api/MainApi';
import { useIdleTimer } from 'react-idle-timer';
import styles from 'maintenance/styles/Globals.module.css';
import {
  LikeOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import {TDiagnosticsItem, TDoActionItem, IDiagnosticsProcess} from 'maintenance/libs/types';
import DiagnosticsItem from './DiagnosticsItem';
import { OperationStatus, ProcessTypes, INITIAL_ITEM_INDEX, UNKNOWN_ERROR_MESSAGE } from 'maintenance/libs/helpers/common';
import AppLayout from 'maintenance/components/AppLayout/AppLayout';
import {useTranslate} from '@refinedev/core';


const {Header} = Layout;
const {Text} = Typography;
const api = new MainApi();

export const DiagnosticsProcess: FC<IDiagnosticsProcess> = ({initialDItems, diagnosticsItems,
                                                              operations, inactivityTimeout}) => {

  const t = useTranslate()

  const [startingDiagnostics, setStartingDiagnostics] = useState(false);

  const [pending, setPending] = useState(false);
  const [performedDiagnosticsItems, setPerformedDiagnosticsItems] = useState<TDiagnosticsItem[]>([]);
  const [currentItem, setCurrentItem] = useState<TDiagnosticsItem>(null);
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);

  const [diagnosticsStatus, setDiagnosticsStatus] = useState(ProcessTypes.START);
  const [error, setError] = useState<{ isError: boolean, operation: number } | null>(null);
  const [isNetworkError, setIsNetworkError] = useState<boolean>(false);

  const [countRunningProcess, setCountRunningProcess] = useState(0);

  const [successfulDiagnosticsCompletion, setSuccessfulDiagnosticsCompletion] = useState(false);

  useEffect(() => {
    return () => {
      setPerformedDiagnosticsItems([]);
      setError(null);
      setCurrentItemIndex(INITIAL_ITEM_INDEX);
    };
  }, []);

  useEffect(() => {
    if (startingDiagnostics) {
      setCurrentItemIndex(INITIAL_ITEM_INDEX);
    }
  }, [startingDiagnostics]);

  useEffect(() => {
    if (currentItemIndex) {
      setCurrentItem(diagnosticsItems[currentItemIndex]);
    }
  }, [diagnosticsItems, currentItemIndex]);

  useEffect(() => {
    if (currentItem) {
      loadNewItem();
    }
  }, [currentItem]);

  useEffect(() => {
    if (diagnosticsStatus === ProcessTypes.CONTINUE) {
      loadNewItem();
    }
  }, [diagnosticsStatus]);

  useEffect(() => {
    if (countRunningProcess > 0) {
      setPending(true);
    } else {
      setPending(false);
    }
  }, [countRunningProcess]);


  const handleOnIdle = () => {
    //console.log('handleOnIdle');
    window.location.href = '/';
  };

  const onPauseIdleTimer = () => {
    idleTimer.pause();
  };

  const onStartIdleTimer = useCallback(() => {
    if (!startingDiagnostics || successfulDiagnosticsCompletion) {
      idleTimer.start();
    }
  }, [startingDiagnostics, successfulDiagnosticsCompletion]);

  const idleTimer = useIdleTimer({
    timeout: 1000 * inactivityTimeout,
    onIdle: handleOnIdle
  });

  useEffect(() => {
    if (successfulDiagnosticsCompletion) {
      onStartIdleTimer();
    }
  }, [successfulDiagnosticsCompletion, idleTimer]);

  const startDiagnostics = () => {
    setPerformedDiagnosticsItems(initialDItems);
    setStartingDiagnostics(true);
    onPauseIdleTimer();
  };

  const loadNewItem = useCallback(async () => {
    //console.log('currentItem', currentItem);
    try {
      setCountRunningProcess(c => c + 1);
      setPerformedDiagnosticsItems(prev => prev.some(item => item?.id === currentItem?.id) ? prev : [...prev, currentItem]);

      const result = await api.doOperation(currentItem?.id || null, diagnosticsStatus);
      if (result.data?.status === OperationStatus.SUCCESS) {

        if (currentItemIndex + 1 === diagnosticsItems.length) {
          setSuccessfulDiagnosticsCompletion(true);
        } else {
          if (!currentItem?.dialog) {
            //автоматически начинаем выполнять следующий шаг только если не нужно действие пользователя
            setCurrentItemIndex(prev => prev + 1);
          }
        }
      } else {
        if (!currentItem?.dialog) {
          //автоматически начинаем выполнять следующий шаг только если не нужно действие пользователя
          setError({isError: true, operation: currentItem?.id || 0});
        }
      }
      //
    } catch (e: any) {
      console.log('e', e.message);
      setIsNetworkError(true);
      setError({isError: true, operation: currentItem?.id || 0});
    } finally {
      setCountRunningProcess(c => c > 0 ? c - 1 : c);
    }

  }, [currentItem, currentItemIndex, diagnosticsStatus, error, diagnosticsItems]);

  const reloadItem = useCallback(() => {
    setPerformedDiagnosticsItems(prev =>  prev.slice(0, prev.length - 1));
    setError(null);
    //console.log('reloadItem', currentItem);
    if (diagnosticsStatus !== ProcessTypes.CONTINUE) {
      setDiagnosticsStatus(ProcessTypes.CONTINUE);
    } else {
      loadNewItem();
    }
  }, [diagnosticsStatus, currentItem, currentItemIndex]);

  const restartDiagnostics = useCallback(() => {
    setSuccessfulDiagnosticsCompletion(false);
    setError(null);
    setDiagnosticsStatus(ProcessTypes.RETRY);
    setStartingDiagnostics(false);
  }, [diagnosticsItems, currentItemIndex]);


  const doItemAction: TDoActionItem = useCallback(async (choice = true, id, buttonId) => {
    try {
      await api.clickDialogButton(currentItem?.id || null, buttonId);

      setPerformedDiagnosticsItems(prev => prev.some(item => item?.id === currentItem?.id) ? prev : [...prev, currentItem]);

      if (!choice) {
        setError({isError: true, operation: currentItem?.id || 0});
        //setCountRunningProcess(c => c > 0 ? c - 1 : 0);
      } else {
        setCurrentItemIndex(prev => prev < diagnosticsItems.length - 1 ? prev + 1 : prev);
        setError(null);
      }
    } catch (e: any) {
      console.log('e', e.message);
      setIsNetworkError(true);
    }
  }, [currentItem]);


  const getDot = useCallback( (type = 'success', pending: boolean) => {
    if (pending) return <LoadingOutlined className={styles['diagnostics-dot-icon']} spin/>;
    // @ts-ignore
    return {
      success: <CheckCircleOutlined className={styles['diagnostics-dot-icon']}/>,
      error: <CloseCircleOutlined className={styles['diagnostics-dot-icon']}/>,
      action: <ExclamationCircleOutlined className={styles['diagnostics-dot-icon']}/>
    }[type];
  }, [pending]);

  // console.log('countRunningProcess', countRunningProcess);
  // console.log('performedDiagnosticsItems', performedDiagnosticsItems);
  // console.log('currentItem', currentItem, currentItemIndex);

  return (
    <AppLayout operations={operations} onStartIdleTimer={onStartIdleTimer} onPauseIdleTimer={onPauseIdleTimer}>
      <Modal
        title={t("diagnostics.error")}
        width={600}
        visible={isNetworkError}
        wrapClassName={'modal-mode'}
        onCancel={() => setIsNetworkError(!isNetworkError)}
        footer={null}
        closable={true}
      >
        <p>
          <ExclamationCircleOutlined style={{color: 'red'}}/> {UNKNOWN_ERROR_MESSAGE}
        </p>
      </Modal>

      <>
        {!startingDiagnostics ? (
          <main className="main">
            <p className="start-wrapper">
              <Button type="primary" size="large" className="start-button"
                      onClick={startDiagnostics}>
                {t("diagnostics.start", {
                  ns: "maintenance"
                })}
              </Button>
              {(diagnosticsItems && diagnosticsItems[1]) && (
                <Text type="secondary">
                  * {diagnosticsItems[1].instruction}
                </Text>
              )}
            </p>
          </main>
        ) : (
          <>
            <Header className="header-background">
              <div className="diagnostics-button-wrapper">
                <Button size="large" disabled={!error && !isNetworkError} className="button-continue-diagnostics"
                        onClick={reloadItem}>
                  {t("diagnostics.continue", {
                    ns: "maintenance"
                  })}
                </Button>
                <Button size="large" disabled={!error && !isNetworkError && !successfulDiagnosticsCompletion}
                        onClick={restartDiagnostics}>
                  {t("diagnostics.restart", {
                    ns: "maintenance"
                  })}
                </Button>
              </div>
            </Header>
            <main className="diagnostics-main">
              <div className="diagnostics-timeline">
                <Timeline
                  items={performedDiagnosticsItems.map((item, i) => {
                    const isItemPending = pending && (item?.id === currentItem?.id) && !error;
                    const isItemError = !!error && (error.operation === currentItem?.id) && (item?.id === currentItem?.id)
                    return {
                      key: item?.id || i,
                      className: "diagnostics-item",
                      color: isItemPending ? 'blue' : (item?.dialog && item?.id === currentItem?.id ? (isItemError ? 'red' : 'blue')
                        : (isItemError ? 'red' : 'green')),
                      dot: getDot(item?.dialog && (item?.id === currentItem?.id) ? (isItemError ? 'error' : 'action')
                        : (isItemError ? 'error' : 'success'), isItemPending),
                      children: <DiagnosticsItem item={item} key={item?.id || ''} isCurrentItem={item?.id === currentItem?.id}
                                                 pending={isItemPending} isError={isItemError} doItemAction={doItemAction}/>
                    }
                  })}
                >
                </Timeline>

                {successfulDiagnosticsCompletion && (
                  <div className="diagnostics-successful-execution">
                    <LikeOutlined style={{color: 'green'}} className="diagnostics-dot-icon"/>
                    {t("diagnostics.end", {
                      ns: "maintenance"
                    })}
                  </div>
                )}
              </div>
            </main>
          </>
        )}
      </>

    </AppLayout>
  );
};
