import React from "react";
import {useTranslate} from "@refinedev/core";
import {useGlobalSettings, usePayment, useUI} from "context";
import {EBonusSystemType, EPaymentSteps} from "types/constants";
import {BonusCardCloseIcon, SuccessAction} from "components/Payment/PaymentModalStates/icons";
import {Loader} from "components/common";
import {NumericKeyBoard} from "components/common/NumericKeyBoard/NumericKeyBoard";
import {BonusCardAuthWrapper} from "./BonusCardAuth.style";
import InputForScanning from 'components/common/InputForScanning/InputForScanning';
import {normalizeCurrencyValue} from '../../libs/utils';


export const BonusCardAuth: React.FC<{
  isLogin?: boolean,
  displayKeyboard: boolean,
  onChangeDisplayKeyboard: (v: boolean) => void
  onChangePaymentStep?: (v: EPaymentSteps, allBonus?: number) => void
}> = ({ isLogin = false, displayKeyboard, onChangeDisplayKeyboard, onChangePaymentStep }) => {

  const t = useTranslate();
  const { settings } = useGlobalSettings();
  const { toggleDisplayBonusAuthModal } = useUI();
  const { bonusCard, bonusCardNumber, isErrorBonusCard, onChangeBonusCardNumber, onValidateBonusCard,
    onClearBonusCard, isCheckingBonusCardNumber } = usePayment()

  const _changeBonusCardNumber = (value: string) => {
    if (!isCheckingBonusCardNumber) {
      if (bonusCard?.cardId) {
        onClearBonusCard()
      }
      onChangeBonusCardNumber(value)
    }
  }

  const handleApplyBonus = async (value?: string) => {
    if (isCheckingBonusCardNumber) {
      return;
    }
    try {
      const result = await onValidateBonusCard(value)
      toggleDisplayBonusAuthModal(false)
      if (!result?.data?.error && onChangePaymentStep) {
        const data = result?.data

        onChangePaymentStep?.(EPaymentSteps.WRITE_OFF_BONUSES, data?.PointOnCart ? data?.PointOnCart :
          !data?.userBalance100 ? 0 : normalizeCurrencyValue(data?.userBalance100 / 100))
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <BonusCardAuthWrapper isLogin={isLogin}>
      <div className={"method-image"}>
        {isCheckingBonusCardNumber && (
          <div className={"loader-container small"}>
            <Loader/>
          </div>
        )}
        {(settings?.bonusSystemType === EBonusSystemType.GOPOEDIM) && (
          <div className={"img-wrapper go-poedim"}>
            <img src={"/images/gopoedim-face.jpg"}/>
          </div>
        )}

        {(settings?.bonusSystemType === EBonusSystemType.OZON) && (
          <div className={"img-wrapper circle-image"}>
            <img src={"/images/ozon-logo.png"}/>
          </div>
        )}

        {(settings?.bonusSystemType === EBonusSystemType.OBEDRU) && (
          <div className={"img-wrapper circle-image"}>
            <img src={"/images/card-gif.gif"} className={"obed-ru-image"}/>
          </div>
        )}
      </div>

      {/*{!isLogin && (*/}
      {/*  <div className={"vertical-divider"}></div>*/}
      {/*)}*/}
      {/*{!isLogin && ((settings?.posTerminal?.paymentEnabled || settings?.payWithBonusPointsOnly) || (settings?.bonusSystemType === EBonusSystemType.OZON || settings?.bonusSystemType === EBonusSystemType.OBEDRU)) && (*/}
      {/*  <div className={"keyboard"}>*/}
      {/*    <div className={"bonus-card-input-wrapper"} onClick={() => {*/}
      {/*      if (!displayKeyboard) {*/}
      {/*        onChangeDisplayKeyboard(true)*/}
      {/*      }*/}
      {/*    }}>*/}
      {/*      <div className={`bonus-card-input ${!!bonusCard?.cardId && !isErrorBonusCard ? 'success-value' : ''}`}>*/}
      {/*        {!!bonusCard?.cardId && !isErrorBonusCard && (*/}
      {/*          <div className={"go-input-prefix"}>*/}
      {/*            <SuccessAction color={"#39BF71"}/>*/}
      {/*          </div>*/}
      {/*        )}*/}
      {/*        {bonusCardNumber.replace(/(\d{4})(\d{1,4})(\d{0,4})/,"$1 $2 $3") || "0000 0000 0000"}*/}
      {/*      </div>*/}

      {/*      <div className={"bonus-card-input-suffix"}>*/}
      {/*        {isCheckingBonusCardNumber ? (*/}
      {/*          <Loader/>*/}
      {/*        ) : (*/}
      {/*          bonusCardNumber ? <span onClick={(e) => {*/}
      {/*              e.stopPropagation()*/}
      {/*              onClearBonusCard()*/}
      {/*            }}><BonusCardCloseIcon/></span> :*/}
      {/*            null*/}
      {/*        )}*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div style={{paddingBottom: '36px'}}>*/}
      {/*      <NumericKeyBoard value={bonusCardNumber} isValid={bonusCardNumber.length > 0}*/}
      {/*                       onSubmit={() => handleApplyBonus(bonusCardNumber)} onChange={_changeBonusCardNumber} />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}

      <InputForScanning elementId={"bonus-cart"} onSubmit={async (value) => {
        _changeBonusCardNumber(value)
        await handleApplyBonus(value)
      }}/>
    </BonusCardAuthWrapper>
  )
}
