import React, {useCallback, useEffect, useRef, useState} from "react";
import {PromoCodeModalWrapper} from "./PromoCodeModal.style";
import {useTranslate} from "@refinedev/core";
import {CustomButton, Modal} from "components/ui";
import {useCartContext, useUI} from "context";
import {CustomKeyBoard, Loader} from "components/common";
import InputForScanning from 'components/common/InputForScanning/InputForScanning';
import {KeyboardReactInterface} from "react-simple-keyboard";
import {ERRORS} from "types/constants";


export const PromoCodeModal: React.FC = () => {
  const t = useTranslate()

  const keyboardRef = useRef<KeyboardReactInterface | null>(null)

  const { displayPromoModal, togglePromoModal, openKeyBoard, closeKeyBoard } = useUI();
  const { selectedPromoCode, onChangePromoCode, onUpdateCart, onValidatePromoCode, onApplyPromoCode,
    isLoading: isCartUpdating, isError, promoCodes, promoCodeError: error } = useCartContext();

  const [promoCode, setPromoCode] = useState<string>('')
  const [checkingCode, setCheckingCode] = useState<boolean>(false)
  const [checkingCodeProcess, setCheckingCodeProcess] = useState<boolean>(false)
  const [promoCodeError, setPromoCodeError] = useState<ERRORS | null>(null)

  useEffect(() => {
    if (displayPromoModal && selectedPromoCode) {
      setPromoCode(selectedPromoCode)
    }
  }, [selectedPromoCode, displayPromoModal])

  useEffect(() => {
    if (isError) {
      setPromoCodeError(error || null)
      setCheckingCode(false)

      if (error === ERRORS.PROMO_CODE_NOT_UNIQUE) {
        // debugger;
        closeModal()
      }
    }
  }, [isError, error])

  const handlePromoCodeCheck = async () => {
    if (onValidatePromoCode(promoCode)) {
      closeKeyBoard()
      setCheckingCodeProcess(true)
      await onApplyPromoCode(promoCode)
      setCheckingCodeProcess(false)
      setCheckingCode(true)
    } else {
      setPromoCodeError(ERRORS.PROMO_CODE_IS_NOT_EXIST)
    }
  }

  const handlePromoCodeApply = async () => {
    await closeModal()
  }

  const handleSelectedCodeClear = async () => {
    handlePromoCodeClear()
    if (selectedPromoCode) {
      await onApplyPromoCode('')
    }
  }

  const handlePromoCodeClear = useCallback(() => {
    setPromoCodeError(null)
    setCheckingCode(false)
    setPromoCode('')
    keyboardRef.current?.clearInput("default")
  }, [selectedPromoCode])

  const closeModal = () => {
    closeKeyBoard()
    handlePromoCodeClear()
    if (displayPromoModal) {
      togglePromoModal()
    }
  }

  return (
    <Modal setVisible={() => closeModal()} visible={displayPromoModal} maxWidth={'100vw'} containerClassName={"full-width"} closeInContainer={true}>
      <PromoCodeModalWrapper>
        <div className={"code-container"}>
          <div className={"code-header"}>
            {(checkingCodeProcess || isCartUpdating) ? t(`modal.promoCode.checking`) : (
              checkingCode ? t(`modal.promoCode.success`) :
              (!promoCodeError) ? t(`modal.promoCode.header`) : t(`errors.${promoCodeError}`)
            )}
          </div>

          <div className={"code-input-wrapper"}>
            {checkingCode && (<SuccessIcon/>)}
            {(!!promoCode && !checkingCode) && (<div onClick={() => handleSelectedCodeClear()}><ErrorIcon/></div>)}
            {promoCode ? (
              <div className={""}>
                {promoCode}
              </div>
            ) : (
              <div className={"editable-placeholder"} onClick={() => openKeyBoard()}>
                0000
              </div>
            )}
          </div>

          {!(checkingCodeProcess || isCartUpdating) && (
            (!!promoCode && checkingCode) ? (
              <CustomButton className={"red-button"} onClick={() => handlePromoCodeApply()}>{t(`modal.promoCode.buttons.continueShopping`)}</CustomButton>
            ) : (
              <>{promoCodeError ? (
                <div className={"code-buttons-wrapper"}>
                  <CustomButton className={"gray-button"} onClick={() => handlePromoCodeClear()}>{t(`modal.promoCode.buttons.addNewCode`)}</CustomButton>
                  <CustomButton className={"red-button"} onClick={() => closeModal()}>{t(`modal.promoCode.buttons.continueShopping`)}</CustomButton>
                </div>
              ) : (
                <div className={"code-buttons-wrapper"}>
                  <CustomButton className={"gray-button"} onClick={() => closeModal()}>{t(`modal.promoCode.buttons.cancel`)}</CustomButton>
                  <CustomButton className={"red-button"} disabled={!promoCode?.length}
                                onClick={() => handlePromoCodeCheck()}>{t(`modal.promoCode.buttons.continue`)}</CustomButton>
                </div>
              )}</>
            )
          )}
        </div>

        <CustomKeyBoard keyboardRef={keyboardRef} onChange={(value) => setPromoCode(value?.length <= 50 ? value : value.slice(0, 50))}/>

        {(checkingCodeProcess) && (<Loader/>)}

        <InputForScanning elementId={'promo'} onSubmit={(value) => {
          setPromoCode(value?.length <= 50 ? value : value.slice(0, 50))
          if (keyboardRef?.current) {
            keyboardRef.current?.clearInput('default')
          }
        }}/>
      </PromoCodeModalWrapper>
    </Modal>
  )
}

const SuccessIcon: React.FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4375 30.8594C18.2344 30.8594 18.8555 30.5547 19.2773 29.9102L30.1992 13.2109C30.5039 12.7422 30.6328 12.3086 30.6328 11.8984C30.6328 10.7969 29.8125 10 28.6875 10C27.9141 10 27.4336 10.2812 26.9648 11.0195L17.3789 26.1367L12.5156 20.1016C12.082 19.5859 11.6133 19.3516 10.957 19.3516C9.82031 19.3516 9 20.1602 9 21.2617C9 21.7539 9.15234 22.1875 9.57422 22.668L15.6211 29.9922C16.125 30.5898 16.6875 30.8594 17.4375 30.8594Z" fill="white"/>
    </svg>
  )
}

const ErrorIcon: React.FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.36354 30.7793C10.0471 31.4629 11.1956 31.4356 11.8518 30.793L20.0003 22.6446L28.135 30.793C28.8049 31.4629 29.9534 31.4629 30.6233 30.7793C31.2932 30.0958 31.3069 28.961 30.637 28.2911L22.5022 20.1426L30.637 12.0079C31.3069 11.3379 31.3069 10.2032 30.6233 9.51958C29.9397 8.84966 28.8049 8.83599 28.135 9.50591L20.0003 17.6543L11.8518 9.50591C11.1956 8.86333 10.0335 8.83599 9.36354 9.51958C8.69362 10.2032 8.70729 11.3516 9.34987 11.9942L17.4983 20.1426L9.34987 28.3047C8.70729 28.9473 8.67994 30.1094 9.36354 30.7793Z" fill="white"/>
    </svg>
  )
}
