import React, {useCallback, useEffect, useState} from "react";
import {useTranslate} from "@refinedev/core";
import {Divider, QRCode} from "antd";
import {IPaymentStepsProps} from "types";
import {useCartContext, useGlobalSettings, usePayment} from "context";
import {CustomButton} from "components/ui";
import {FinishPaymentWrapper} from "components/Payment/Payment.style";
import {EBonusSystemType, EPaymentMethods, ERRORS} from "types/constants";
import {Loader} from "components/common";
import InputForScanning from 'components/common/InputForScanning/InputForScanning';
import {getYBadgeLastPaymentUnixTime, normalizeCurrencyValue} from "libs/utils";
import {WarningOutlined} from "@ant-design/icons";
import {MoneyUnits} from "components/common/MoneyUnits";
import {useGetBrandName, useGlobalClean} from "libs/hooks";


export const FinishPayment: React.FC<IPaymentStepsProps> = ({goPreviousStep}) => {

  const t = useTranslate();
  const { settings } = useGlobalSettings()
  const { paymentError, isSuccessPayment, bonusCard, spentBonusCount, selectedPaymentMethod, isPaymentLoading, orderData,
    isPaymentProcess, paymentErrorMessage, onMakeYandexPayment, onMakePayment,
    isPaymentCardLoading, onStartPaymentAgain } = usePayment()
  const [timeoutYandexPayExpired, setTimeoutYandexPayExpired ] = useState(false);

  const { onRefreshData } = useGlobalClean();
  const brandName = useGetBrandName();

  const { cart } = useCartContext()

  useEffect(() => {
    return () => {
      setTimeoutYandexPayExpired(false)
    }
  }, [])

  useEffect(() => {
    if (paymentError === ERRORS.TERMINAL_TIMEOUT) {
      //onCloseDisplayPayment()
      onRefreshData()
    }
  }, [paymentError])

  useEffect(() => {
    if (selectedPaymentMethod && !isPaymentProcess && !paymentError && !isSuccessPayment) {

      console.log('start payment with', selectedPaymentMethod)

      if ([EPaymentMethods.PAYMENT_TYPE_SBP, EPaymentMethods.PAYMENT_TYPE_ON_LUNCH, EPaymentMethods.PAYMENT_TYPE_CARD].includes(selectedPaymentMethod as EPaymentMethods)) {
        onMakePayment()
      } else {
        if (selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE) {
          delayYBadgeRequest(onMakeYandexPayment, (settings?.ybadgePaymentMinDelay || 12))
        } else {
          if ([EBonusSystemType.GOPOEDIM, EBonusSystemType.OBEDRU, EBonusSystemType.OZON].includes(selectedPaymentMethod as EBonusSystemType)) {
            onMakePayment()
          }
        }
      }
    }
  }, [selectedPaymentMethod, settings?.ybadgePaymentMinDelay, isPaymentProcess, paymentError, isSuccessPayment])

  useEffect(() => {
    if (!paymentError && !selectedPaymentMethod && bonusCard) {
      console.log('start payment with bonus')
      onMakePayment()
    }
  }, [selectedPaymentMethod, bonusCard, paymentError])

  const delayYBadgeRequest = (reqCallback: () => void, delaySinceLastPayment: number | null = null) => {
    const nowUnixTime = +new Date() / 1000;
    const lastPaymentUnixTime = getYBadgeLastPaymentUnixTime();

    const logDelayData = (leftDelay: number | null) => {
      const data = {
        delaySinceLastPayment,
        nowUnixTime,
        lastPaymentUnixTime,
        leftDelay: null
      };
      if (leftDelay != null) {
        // @ts-ignore
        data.leftDelay = leftDelay;
      }
      console.log('YBadge payment delay data:')
      console.log(data)
    };

    if (!delaySinceLastPayment || !lastPaymentUnixTime) {
      logDelayData(null);
      setTimeoutYandexPayExpired(false);
      reqCallback();
    } else {
      const leftDelay = delaySinceLastPayment - (nowUnixTime - lastPaymentUnixTime);
      logDelayData(leftDelay ?? null);
      if (leftDelay < 0) {
        setTimeoutYandexPayExpired(false);
        reqCallback();
      } else {
        if (leftDelay > 1.5) {
          setTimeoutYandexPayExpired(true)
          //this.isWaitingForFinishingOnYandexBadgeSide = true;
        }
        setTimeout(() => {
          setTimeoutYandexPayExpired(false)
          //this.isWaitingForFinishingOnYandexBadgeSide = false;
          reqCallback();
        }, leftDelay * 1000);
      }
    }
  }

  const backIntoPrevious = useCallback(() => {
    goPreviousStep()
    onStartPaymentAgain()
  }, [isPaymentLoading, bonusCard])

  const submit = useCallback(async (value: string) => {

    console.log('start payment with', selectedPaymentMethod)
    if (selectedPaymentMethod && [EPaymentMethods.PAYMENT_TYPE_YANDEX_EDA, EPaymentMethods.PAYMENT_TYPE_YANDEX_QR].includes(selectedPaymentMethod as EPaymentMethods) && !isPaymentLoading) {
      //setTokenForYandex(value)
      onMakeYandexPayment(value)
    }
  }, [isPaymentLoading])

  return (
    <FinishPaymentWrapper isError={!!paymentError}>

      <InputForScanning elementId={"yandex-payment"} active={!isSuccessPayment && !isPaymentLoading} onSubmit={submit}/>
      {((isPaymentLoading && [EPaymentMethods.PAYMENT_TYPE_SBP, EPaymentMethods.PAYMENT_TYPE_ON_LUNCH,
          EPaymentMethods.PAYMENT_TYPE_YANDEX_QR].includes(selectedPaymentMethod as unknown as EPaymentMethods)
        && (spentBonusCount < cart?.total_amount)) || (isPaymentCardLoading &&
        [EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE, EPaymentMethods.PAYMENT_TYPE_YANDEX_EDA].includes(selectedPaymentMethod as unknown as EPaymentMethods))) && (
        <div className={"loader-container"}>
          <Loader/>
        </div>
      )}

      <div className={"modal-header"}>
        {t(`payment.finish.headers.${selectedPaymentMethod || settings?.bonusSystemType || 'default'}`, {
          brandName,
          interpolation: {
            escapeValue: false,
          }
        })}
      </div>

      {paymentError === ERRORS.TERMINAL_TIMEOUT ? (
        <div style={{width: '450px', height: '350px', background: 'white'}}></div>
      ) : (
        <>
          {(
            <div className={"method-description"}>
              {(selectedPaymentMethod && [EPaymentMethods.PAYMENT_TYPE_ON_LUNCH, EPaymentMethods.PAYMENT_TYPE_SBP].includes(selectedPaymentMethod as EPaymentMethods)) && (
                <>{!paymentError && (
                  <div className={"method-image method-with-qr-code"}>
                    <QRCode value={orderData?.payment_url || ''} size={180} type={"svg"} errorLevel="H" style={{
                      height: '100%',
                      width: '100%'
                    }}/>
                  </div>
                )}</>
              )}

              {(selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_CARD ||
                Object.values(EBonusSystemType).includes(selectedPaymentMethod as unknown as EBonusSystemType) && (spentBonusCount < cart?.total_amount)) && (
                <div className={"method-image"}>
                  {isPaymentLoading && (
                    <div className={"loader-container small"}>
                      <Loader/>
                    </div>
                  )}
                  <div className="img-wrapper">
                    {paymentError ? (
                      <WarningOutlined />
                    ) : (
                      <img src={"/images/bank-terminal-img.png"}/>
                    )}
                  </div>
                </div>
              )}

              {timeoutYandexPayExpired ? (
                <div className={"method-text"}>{t(`payment.finish.process.waitingForFinishingOnYandexBadgeSide`)}</div>
              ) : (
                <>
                  {(isPaymentLoading && [EPaymentMethods.PAYMENT_TYPE_YANDEX_EDA].includes(selectedPaymentMethod as unknown as EPaymentMethods)) ? (
                    <div className={"method-text"}>{t(`payment.finish.process.waitingForFinishingOnYandexEdaSide`)}</div>
                  ) : (
                    <>
                      {(paymentError || paymentErrorMessage) ? (
                        <div className={"method-text"}>{(paymentErrorMessage || t(`payment.finish.errors.payment.${paymentError || 'somethingWentWrong'}`))}</div>
                      ) : (
                        <div className={"method-text"}>{(
                          (settings?.payWithBonusPointsOnly || !(spentBonusCount < cart?.total_amount)) ? t(`payment.finish.description.onlyBonus`) :
                            t(`payment.finish.description.${selectedPaymentMethod || EPaymentMethods.PAYMENT_TYPE_CARD}`)
                        )}</div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
          <div className={"payment-info-container"}>
            <div className={"payment-info"}>
              <span>{t("payment.finish.items")}</span>
              <span>{cart.lines?.reduce((number, line) => line.quantity + number, 0)}</span>
            </div>

            <Divider/>

            <div className={"payment-info"}>
              <span>{t("payment.finish.bonus")}</span>
              <div>
                {/*{!!bonusCard?.cardId && (<span className={"success"}>+{Math.floor((cart?.total_amount - spentBonusCount) * 0.1)}</span>)}*/}
                {!spentBonusCount ? <span>{spentBonusCount}</span> : <span className={"alert"}>-{spentBonusCount}</span>}
              </div>
            </div>

            <Divider/>

            <div className={"payment-info"}>
              <span>{t("payment.finish.totalAmount")}</span>
              <span>{normalizeCurrencyValue(cart.total_amount - spentBonusCount)}&nbsp;<MoneyUnits/></span>
            </div>
          </div>

          {!(isPaymentLoading && (settings?.payWithBonusPointsOnly || [EPaymentMethods.PAYMENT_TYPE_CARD, EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE].includes(selectedPaymentMethod as unknown as EPaymentMethods) ||
            [EBonusSystemType.GOPOEDIM, EBonusSystemType.OBEDRU, EBonusSystemType.OZON].includes(selectedPaymentMethod as EBonusSystemType))) && (
            <div className={"payment-buttons"}>
              <CustomButton className={"gray-button"}
                            onClick={backIntoPrevious}>{t("payment.buttons.back")}</CustomButton>
            </div>
          )}
        </>
      )}

    </FinishPaymentWrapper>
  )
}
