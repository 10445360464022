import styled from "styled-components";
import {ProductWrapper} from "components/ProductPreview/ProductPreview.style";

export const PopularProductsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 24px;

  .scroll-container {
    display: flex;
    height: 280px;
    padding-inline-end: 26px;
    gap: 0;
    align-items: center;
    width: 100%;
  }

  .swiper-slide {
    max-width: 158px;
    //margin-right: 0!important;
  }
  
  ${ProductWrapper} {
    margin-inline-start: 0;
    margin-bottom: 0;
  }
`;