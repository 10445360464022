import styled from "styled-components";

export const CustomModalContentWrapper = styled.div`
  max-width: 1000px;
  
  .modal-header {
    ${({ theme: { fonts } }) => fonts.headerS};
    color: ${({ theme: { colors } }) => colors.textBlack};
  }
  
  .modal-subheader {
      ${({ theme: { fonts } }) => fonts.subheaderSBold};
    color: ${({ theme: { colors } }) => colors.textBlack};
  }

  .modal-text {
    ${({ theme: { fonts } }) => fonts.subheaderS};
    color: ${({ theme: { colors } }) => colors.gray};
  }
  
  .image-not-exist {
    background: ${({ theme: { colors } }) => colors.yellow};
  }
  
  @media (max-width: 1090px) {
    min-width: 100%;
    width: 100%;
  }
  
`

export const FullScreenModalWrapper = styled(CustomModalContentWrapper)`
  width: 100vw;
  height: 100vh;  
  background-size: cover;

  max-width: none;
  min-width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  .code-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-138px);
  }
  
  .code-header {
    ${({ theme: { fonts } }) => fonts.headerS};
    color: ${({ theme: { colors } }) => colors.white};

    margin-bottom: 92px;
  }

  .code-buttons-wrapper {
    display: flex;

    .red-button {
      margin-inline-start: 8px;
    }
  }

  .code-input-wrapper {
    cursor: pointer;
    margin-bottom: 122px;
    font-size: 56px;
    font-weight: 700;
    line-height: 60px;
    color: ${({ theme: { colors } }) => colors.white};
    text-align: center;
    background: transparent;
    max-width: 1000px;
    word-break: break-all;

    display: flex;
    align-items: center;

    svg {
      margin-inline-end: 21px;
    }

    .editable-placeholder {
      opacity: 0.6;
      color: ${({ theme: { colors } }) => colors.white};
    }
  }
`