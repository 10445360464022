import styled from "styled-components";
import {CustomModalContentWrapper} from "components/ui/styled/common.styles";

export const MarkedProductModalWrapper = styled(CustomModalContentWrapper)`
  padding-block: 50px 40px;
  padding-inline: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1000px;
  overflow: auto;
  
  .modal-header {
    margin-bottom: 24px;
  }

  .modal-text {
    margin-bottom: 38px;
    text-align: center;
    max-width: 768px;
  }
  
  .red-text {
    color: ${({ theme: { colors } }) => colors.red};
  }

  .product-full-image {

    .image-not-exist {
      background: ${({ theme: { colors } }) => colors.yellow};
    }

    img {
      object-fit: cover;
    }
  }

  .tooltip-images {
    display: flex;
    justify-content: space-between;
    margin-bottom: 72px;
    
    .tooltip-image {
      display: flex;
      width: 200px;
      height: 200px;
      border-radius: 100px;
      margin-inline-end: 24px;
      
      img {
        width: 200px;
        height: 200px;
        border-radius: 100px;
        border: 10px solid rgb(235, 236, 237);
      }
    }
    
    :last-child {
      margin-inline-end: 0;
    }
  }
`