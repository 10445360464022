import {useEffect, useState} from 'react';
import MainApi from 'maintenance/api/MainApi';
import {DConfig} from 'maintenance/libs/types';


const api = new MainApi();

export const useGetConfig = () => {
  const [result, setResult] = useState<DConfig>();

  useEffect(() => {
    handleGetConfig();
  }, []);

  const handleGetConfig = async () => {
    try {
      const response = await api.getConfig();
      setResult(response?.data);
    } catch (e) {
      console.log('getConfig e', e);
    }
  };

  return result;
};