import {useTranslate} from '@refinedev/core';
import {useGlobalSettings, useSettings} from "context";


export const MoneyUnits = () => {

  const t = useTranslate()
  const { settings } = useGlobalSettings()

  return (
    <>{t(`layout.moneyUnits.${settings?.moneyUnits?.toLowerCase()}`)}</>
  )
}