import styled from "styled-components";
import {FullScreenModalWrapper} from "components/ui/styled/common.styles";

export const SuccessfulPaymentModalWrapper = styled(FullScreenModalWrapper)<{
  displayEmailForm: boolean
}>`
  z-index: 1001;
  
  background: ${({displayEmailForm, theme: {rtl}}) => displayEmailForm ? '#39BF71' :
          rtl ? 'url("/images/payment-background-rtl.png")' : 'url("/images/payment-background.png")'} center;
  
  .left-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .like-icon {
    margin-bottom: 65px;
  }
  
  .payment-success-text {
    font-size: 24px;
    font-weight: 600;
    line-height: 42px;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.white};
    margin-bottom: 80px;
    
    span {
      display: block;
    }
  }
  
  .buttons-wrapper {
    display: flex;
    
    .transparent-button {
      margin-inline-end: 8px;
    }
  }
`;
