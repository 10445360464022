import React from "react";

export const Search: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6" clipPath="url(#clip0_104_799)">
        <path d="M19.379 9.44197C19.379 6.91734 18.3982 4.54635 16.6175 2.76589C14.8343 0.982258 12.4637 0 9.94146 0C7.42005 0 5.04886 0.982258 3.26589 2.76589C1.48226 4.54912 0.5 6.92011 0.5 9.44197C0.5 11.9634 1.48226 14.3346 3.26548 16.118C5.0485 17.9013 7.41908 18.8835 9.94156 18.8835C12.4634 18.8835 14.8346 17.9013 16.6176 16.118C18.3985 14.3376 19.3793 11.9668 19.3793 9.44197H19.379ZM14.3876 13.8878C13.1999 15.0752 11.6211 15.7291 9.94171 15.7291C8.26291 15.7291 6.68413 15.0751 5.49641 13.8878C4.30873 12.7 3.65495 11.1213 3.65495 9.44197C3.65495 7.76317 4.30894 6.18439 5.49641 4.99646C6.68372 3.80879 8.2623 3.15501 9.94171 3.15501C11.6211 3.15501 13.1999 3.80899 14.3872 4.99646C16.8382 7.44765 16.8384 11.4364 14.3876 13.888L14.3876 13.8878ZM24.0376 21.3075L19.2264 16.4966C18.9391 16.2089 18.5324 16.0443 18.1112 16.0443C17.6898 16.0443 17.2831 16.2089 16.9959 16.4966C16.3807 17.1117 16.3807 18.112 16.9959 18.7269L21.8073 23.5379C22.1053 23.836 22.5013 24 22.9225 24C23.3438 24 23.7399 23.836 24.0382 23.5377C24.3359 23.2396 24.4997 22.8437 24.4997 22.4226C24.4995 22.0011 24.3355 21.6052 24.0376 21.3076L24.0376 21.3075Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_104_799">
          <rect width="23.9997" height="24" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
}