import Decimal from "decimal.js";
import {Howl} from "howler";
import {PRODUCT_IMAGES_DIR, SOUNDS_DIR} from "types/constants";
import urlJoin from "url-join";

export const unitsByCode = (code: string) => ({
  gram: 'gr'
}[code] || '')


export const getExtensionFromFileUrl = (fileUrl: string) => {
  const parts = fileUrl.split('/').reverse()[0].split('.').reverse();
  return ((parts.length > 1) ? parts[0] : null) || null;
};


export const getImageUrl = (url: string | null | undefined) => {
  return urlJoin(PRODUCT_IMAGES_DIR, url || '');
};

export const normalizeCurrencyValue = (value: number) => {
  // Метод `Decimal.toFixed()` сам делает округление по математическим правилам
  return +(new Decimal(value).toFixed(2));
};

export const uuidV4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};


export const makeSound = (fileUrl: string) => {
  const soundUrl = urlJoin(SOUNDS_DIR, fileUrl || '')
  let howlOptions: {
    src: string[],
    volume?: number | undefined,
    format?: string
  } = {
    src: [soundUrl],
    //volume: 0.5
  }

  if (!getExtensionFromFileUrl(soundUrl)) {
    howlOptions = {
      ...howlOptions,
      format: 'mp3'
    };
  }

  new Howl(howlOptions).play();
}

export const saveYBadgeLastPaymentUnixTime = () => {
  const nowUnixTime = Math.round((+new Date()) / 1000 * 10) / 10;
  localStorage.setItem('last_payment_unix_time', '' + nowUnixTime);
};

export const getYBadgeLastPaymentUnixTime = () => {
  const value = localStorage.getItem('last_payment_unix_time');
  return value == null ? null : +value;
};
