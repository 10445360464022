import React from "react";
import styled from "styled-components";

export const LoaderWrapper = styled.div`
  // Here is where the magic happens

  .spinner {
    line-height: 10px;
    animation: rotator 1s linear infinite;
    width: 200px;
    height: 200px;
  }

  .spinner {
    animation: rotate 2s linear infinite;
    //z-index: 2;
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //margin: -25px 0 0 -25px;
    //width: 50px;
    //height: 50px;
    
    .full-path {
      stroke: ${({ theme: { colors } }) => colors.red};
    }

    .path {
      stroke: ${({ theme: { colors } }) => colors.yellow};
      stroke-linecap: round;
      stroke-dasharray: 90 360;
      //animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: 0;
    }
  }
`

export const Loader: React.FC = () => {
  return (
    <LoaderWrapper>
      <svg className="spinner" viewBox="0 0 170 170">
        <circle className="full-path" cx="85" cy="85" r="75" fill="none" strokeWidth="20"></circle>

        <circle className="path" cx="85" cy="85" r="75" fill="none" strokeWidth="20"></circle>
      </svg>
      {/*<div className={"spinner"}>*/}
      {/*  <svg width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
      {/*    <path fillRule="evenodd" clipRule="evenodd" d="M85 18.8889C48.4878 18.8889 18.8889 48.4878 18.8889 85C18.8889 121.512 48.4878 151.111 85 151.111C121.512 151.111 151.111 121.512 151.111 85C151.111 48.4878 121.512 18.8889 85 18.8889ZM0 85C0 38.0558 38.0558 0 85 0C131.944 0 170 38.0558 170 85C170 131.944 131.944 170 85 170C38.0558 170 0 131.944 0 85Z" fill="#D9214F"/>*/}
      {/*    <path fillRule="evenodd" clipRule="evenodd" d="M49.556 7.56888C62.5359 1.71772 76.7869 -0.807133 91.0041 0.22553C105.221 1.25819 118.95 5.81537 130.934 13.4798C142.918 21.1442 152.774 31.6708 159.599 44.0959C166.424 56.521 170 70.4474 170 84.6C170 89.7915 165.761 94 160.532 94C155.303 94 151.064 89.7915 151.064 84.6C151.064 73.5925 148.282 62.7608 142.974 53.0968C137.665 43.4329 130 35.2455 120.679 29.2843C111.358 23.3231 100.68 19.7786 89.6224 18.9754C78.5645 18.1722 67.4804 20.136 57.385 24.6869C52.6237 26.8332 47.0113 24.7412 44.8494 20.0142C42.6875 15.2872 44.7947 9.71522 49.556 7.56888Z" fill="#F2C43C"/>*/}
      {/*  </svg>*/}
      {/*</div>*/}
    </LoaderWrapper>
  )
}