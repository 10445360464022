import React from "react";
import {useTranslate} from "@refinedev/core";
import {MarkedProductModalWrapper} from "./MarketProductModal.style";
import {useCartContext} from "context";
import {Modal, CustomButton} from "components/ui";
import InputForScanning from 'components/common/InputForScanning/InputForScanning';


export const MarkedProductModal: React.FC = () => {
  const t = useTranslate()

  const { displayProductMarketModal, onAddItem, closeMarkedModal, selectedMarkedProduct, markingCodeError } = useCartContext();

  const handleAddProduct = async (code: string) => {
    if (selectedMarkedProduct) {
      await onAddItem(selectedMarkedProduct, 1, code)
      //closeMarkedModal()
    }
  }

  return (
    <Modal setVisible={closeMarkedModal} visible={displayProductMarketModal}>
      <MarkedProductModalWrapper className={"contact-info"}>
        <div className={"modal-header"}>{t("modal.markedProduct.header")}</div>
        {markingCodeError ? (
          <div className={"modal-text red-text"}>
            {t(`errors.${markingCodeError}`)}
          </div>
        ) : (
          <div className={"modal-text"}>
            {t("modal.markedProduct.description")}
          </div>
        )}
        <div className={"tooltip-images"}>
          <div className={"tooltip-image"}>
            <img src={"images/image 33.jpg"}/>
          </div>
          <div className={"tooltip-image"}>
            <img src={"images/image 34.jpg"}/>
          </div>
          <div className={"tooltip-image"}>
            <img src={"images/image 35.jpg"}/>
          </div>
        </div>

        <CustomButton className={"gray-button"} onClick={closeMarkedModal}>
          {t("modal.buttons.cancel")}
        </CustomButton>

        <InputForScanning elementId={"scan-marking-code"} onSubmit={handleAddProduct}/>
      </MarkedProductModalWrapper>
    </Modal>
  )
}
