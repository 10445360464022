import React, {useEffect, useMemo, useState} from "react";
import {useGetLocale, useTranslate} from "@refinedev/core";
import {Col, Divider, Row, Slider} from "antd";
import {SliderMarks} from "antd/es/slider";
import {useCartContext, useGlobalSettings, usePayment} from "context";
import {IPaymentMethod, IPaymentStepsProps} from "types";
import {PaymentMethodsWrapper, PaymentMethodWrapper, WriteOffBonusWrapper} from "components/Payment/Payment.style";
import {CustomButton} from "components/ui";
import {normalizeCurrencyValue} from "libs/utils";
import {MoneyUnits} from "components/common/MoneyUnits";
import {EBonusSystemType, EPaymentMethods} from '../../../types/constants';
import {BankCartIcon, SBPIcon} from './icons';
import {useGetMaxBonusesValue} from '../../../libs/hooks';
import {Trans} from 'react-i18next';


export const WriteOffBonus: React.FC<IPaymentStepsProps> = ({goNextStep, goPreviousStep}) => {

  const locale = useGetLocale();
  const currentLocale = locale();
  const t = useTranslate()
  const { settings } = useGlobalSettings();
  const { cart } = useCartContext();
  const { spentBonusCount, bonusCard, selectedPaymentMethod, onChangeSpentBonusCount, onSelectPaymentMethod } = usePayment();

  const {
    maxSliderValue,
    payWithMaxBonuses
  } = useGetMaxBonusesValue()

  const marks: SliderMarks = useMemo(() => ({
    0: {
      style: {
        transform: 'translateX(0%)',
        color: '#797D80'
      },
      label: <span>{t("payment.bonus.slider.min")}</span>,
    },
    [maxSliderValue <= 0 ? 1 : maxSliderValue]: {
      style: {
        transform: currentLocale === 'ar' ? 'translateX(100%)' : 'translateX(-100%)',
        color: '#797D80'
      },
      label: <span>{t("payment.bonus.slider.max")}</span>,
    },
  }), [maxSliderValue]);

  const paymentMethods: IPaymentMethod[] = useMemo(() => (
    [
      {
        title: t("payment.methods.card"),
        key: EPaymentMethods.PAYMENT_TYPE_CARD,
        visible: !!settings?.posTerminal?.paymentEnabled,
        icon: <BankCartIcon isSelected={selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_CARD}/>
      },
      {
        title: t("payment.methods.sbp"),
        key: EPaymentMethods.PAYMENT_TYPE_SBP,
        visible: !!settings?.sbpQrEnabled,
        icon: <SBPIcon/>
      }
    ].filter(m => m.visible)
  ), [settings, selectedPaymentMethod, t])

  const handlePaymentMethodSelect = (method: EPaymentMethods | EBonusSystemType | null) => {
    onSelectPaymentMethod(method)
    goNextStep()
  }

  return (
    <WriteOffBonusWrapper showPaymentMethodsBlock={payWithMaxBonuses}>
      <div className={"modal-header"}>
        {payWithMaxBonuses ? (
          <>{(bonusCard?.userName || bonusCard?.firstName) ? (
              <>
                {t("payment.bonus.headerWithCount",
                  {userName: bonusCard?.firstName || bonusCard?.userName, count: bonusCard?.allBonus })}&nbsp;
                {t("payment.bonus.headerWithCount_interval", {postProcess: 'interval', count:
                    Math.abs(bonusCard?.allBonus) })}
              </>
            ) : (
              <>
                {t("payment.bonus.headerWithCountWithoutUser", { count: bonusCard?.allBonus })}&nbsp;
                {t("payment.bonus.headerWithCount_interval", { postProcess: 'interval', count:
                    Math.abs(bonusCard?.allBonus) })}
              </>
          )}</>
        ) : (
          <>{(bonusCard?.userName || bonusCard?.firstName) ?
            t("payment.bonus.header", { userName: bonusCard?.firstName || bonusCard?.userName }) :
            t("payment.bonus.headerWithoutUser")}</>
        )}
      </div>

      <div className={"modal-description"}>
        {!payWithMaxBonuses ? (
          t("payment.bonus.description")
        ) : (
          <>
            {(!settings?.payWithBonusPointsOnly && paymentMethods?.length) && (
              <>
                <Trans i18nKey="payment.bonus.descriptionWithCount"
                       tOptions={{money_unit: t(`layout.moneyUnits.${settings?.moneyUnits?.toLowerCase()}`) || settings?.moneyUnits}}
                       count={normalizeCurrencyValue(cart.total_amount - spentBonusCount)}/>
              </>
            )}
          </>
        )}
      </div>

      {payWithMaxBonuses ? (
        <div className={"bonus-action-container show-payment-methods-block"}>
          <PaymentMethodsWrapper methodCount={paymentMethods?.length}>
            <Row className={`payment-methods-container`} gutter={[8, 8]}>
              {!settings?.payWithBonusPointsOnly && paymentMethods?.length ? (
                <>
                  {paymentMethods.map(m => (
                    <Col xs={12} sm={12} md={8} lg={8} key={m.key} className={"payment-methods-col"}>
                      <PaymentMethodWrapper
                        className={m.key !== selectedPaymentMethod ? '' : 'selected-method'}
                        onClick={() => handlePaymentMethodSelect(selectedPaymentMethod === m.key ? null : m.key)}>
                        {m.icon ? m.icon : <div className={"mock-image"}/>}
                        <span>{m.title}</span>
                      </PaymentMethodWrapper>
                    </Col>
                  ))}
                </>
              ) : (
                <div className={"bonus-slider"} style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                  {(settings?.payWithBonusPointsOnly && bonusCard?.allBonus < cart.total_amount ) && (
                    <span className={"error-text"}>{t("payment.bonus.error.header")}</span>
                  )}
                  {!paymentMethods?.length && (<span className={"error-text"}>{t("payment.bonus.errorPayMaxBonuses.description")}</span>)}
                </div>
              )}
            </Row>
          </PaymentMethodsWrapper>
        </div>
      ) : (
        <div className={"bonus-action-container"}>
          {!!settings?.payWithBonusPointsOnly ? (
            <div className={"bonus-slider"}>
              {bonusCard?.allBonus < cart.total_amount ?
                <span className={"error-text"}>{t("payment.bonus.error.header")}</span> :
                <span className={"description-text"}>{t("payment.bonus.error.description")}</span>
              }
            </div>
          ) : (
            <div className={"bonus-slider"}>
              <Slider value={spentBonusCount} range={false}
                      onChange={(v) => onChangeSpentBonusCount(v)} defaultValue={0}
                      min={0} max={maxSliderValue <= 0 ? 1 : maxSliderValue}
                      disabled={maxSliderValue <= 0}
                      reverse={currentLocale === 'ar'}
                      marks={marks}
                      railStyle={{
                        height: '16px',
                        background: '#EBECED',
                        borderRadius: '7px'
                      }}
                      trackStyle={{
                        height: '16px',
                        borderRadius: '7px',
                        background:  maxSliderValue <= 0 ? '#b4b4b4' : '#D9214F',
                      }}
                      handleStyle={{
                        transform: `translate(-26px, -14px)`
                      }}
              />
            </div>
          )}
          <div className={"bonus-info"}>
            <span>{t("payment.bonus.allCount")}</span>
            <span>{bonusCard.allBonus || 0}</span>
          </div>

          <Divider/>

          <div className={"bonus-info"}>
            <span>{t("payment.bonus.writeOffCount")}</span>
            <span>{spentBonusCount}</span>
          </div>

          <Divider/>

          <div className={"bonus-info"}>
            <span>{t("payment.bonus.totalAmount")}</span>
            <div>
              {!!spentBonusCount && (<span className={"old-price"}>{normalizeCurrencyValue(cart.total_amount)}&nbsp;<MoneyUnits/></span>)}
              <span>{normalizeCurrencyValue(cart.total_amount - spentBonusCount)}&nbsp;<MoneyUnits/></span>
            </div>
          </div>
        </div>
      )}


      <div className={"payment-buttons"}>
        <CustomButton className={"gray-button"} onClick={() => goPreviousStep()}>{t("payment.buttons.back")}</CustomButton>
        {!payWithMaxBonuses && (
          <CustomButton className={"red-button"} onClick={() => goNextStep()}
                        disabled={!settings?.payWithBonusPointsOnly ? !selectedPaymentMethod :
                          (cart.total_amount !== spentBonusCount && !selectedPaymentMethod || bonusCard?.allBonus < cart.total_amount)}
          >{t("payment.buttons.nextBonus")}</CustomButton>
        )}
      </div>
    </WriteOffBonusWrapper>
  )
}
