import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {SuccessfulPaymentModalWrapper} from "./SuccessfulPaymentModal.style";
import {Col, Row} from "antd";
import {useIdleTimer} from "react-idle-timer";
import {CustomButton, Modal} from "components/ui";
import {CustomKeyBoard} from "components/common";
import {KeyboardReactInterface} from "react-simple-keyboard";
import {useCartContext, usePayment, useUI} from "context";
import {useGlobalClean} from "libs/hooks";
import {useTranslate} from "@refinedev/core";
import {EBonusSystemType, EPaymentMethods, SENDING_EMAIL_TIME_SEC} from "types/constants";
import isEmail from 'validator/lib/isEmail';


export const SuccessfulPaymentModal: React.FC = React.memo(() => {

  const t = useTranslate()
  const keyboardRef = useRef<KeyboardReactInterface | null>(null)

  const {cart} = useCartContext();
  const { openKeyBoard, isInactivityUser, toggleInactivityUser } = useUI();
  const { onRefreshData } = useGlobalClean();
  const { orderData, isSuccessPayment, onSendEmail, selectedPaymentMethod, spentBonusCount } = usePayment();

  const [displaySendingEmailForm, setDisplaySendingEmailForm] = useState(false)
  const [successSending, setSuccessSending] = useState(false)

  const [email, setEmail] = useState('')
  const [isValidateEmailError, setIsValidateEmailError] = useState(false);

  const showEmailButton = useMemo(() => (selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_CARD || selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_SBP ||
    (Object.values(EBonusSystemType).includes(selectedPaymentMethod as unknown as EBonusSystemType) && (spentBonusCount < cart?.total_amount))),
    [selectedPaymentMethod, spentBonusCount, cart, EPaymentMethods, EBonusSystemType])

  const collectEmailForReceipt = useMemo(() => orderData?.collect_email_for_receipt, [orderData])

  useEffect(() => {
    if (successSending && displaySendingEmailForm) {
      setTimeout(handleIdle, 3500)
    }
  }, [successSending, displaySendingEmailForm])

  const validateEmail = useCallback(async () => {
    if (!isEmail(email)) {
      setIsValidateEmailError(true)
    } else {
      await onSendEmail(email, orderData.order_id)
      setSuccessSending(true)
    }
  }, [email, orderData])

  const handleIdle = useCallback(async () => {
    if (isSuccessPayment) {
      if (!successSending && showEmailButton) {
        await onSendEmail('', orderData?.order_id)
      }
      await _clearAllData();
    } else {
      _clearLocalData()
    }
  }, [isSuccessPayment, successSending, orderData, showEmailButton])

  const _returnToCatalog = async () => {
    if (!successSending && showEmailButton) {
      await onSendEmail('', orderData?.order_id)
    }
    _clearLocalData()
    await onRefreshData()
  }

  const _clearAllData = async () => {
    _clearLocalData()
    if (!isInactivityUser) {
      toggleInactivityUser()
    }
    await onRefreshData()
  }

  const _clearLocalData = () => {
    setEmail('')
    setSuccessSending(false)
    setDisplaySendingEmailForm(false)
    setIsValidateEmailError(false)
  }

  const { getElapsedTime } = useIdleTimer({
    timeout: SENDING_EMAIL_TIME_SEC * 1000,
    disabled: !isSuccessPayment,
    throttle: 0,
    onIdle: handleIdle
  })

  return (
    <Modal visible={isSuccessPayment} maxWidth={'100vw'} containerClassName={"full-width"}
           displayCloseButton={false} wrapClassName={'full-screen-modal'}>
      <SuccessfulPaymentModalWrapper displayEmailForm={displaySendingEmailForm}>
        {displaySendingEmailForm ? (
          <>
            <div className={"code-container"}>
              <div className={"code-header"}>
                {isValidateEmailError ? (
                  t("payment.success.email.invalidEmail")
                ) : (
                  successSending ? t("payment.success.email.success") : t("payment.success.email.header")
                )}
              </div>

              <div className={"code-input-wrapper"}>
                {email ? (
                  <div className={""}>
                    {email}
                  </div>
                ) : (
                  <div className={"editable-placeholder"} onClick={() => openKeyBoard()}>
                    example@mail.com
                  </div>
                )}
              </div>

              <div className={"code-buttons-wrapper"}>
                {successSending ? (
                  <CustomButton className={"gray-button"} onClick={_clearLocalData}>
                    {t("payment.success.email.buttons.return")}</CustomButton>
                ) : (
                  <>
                    <CustomButton className={"gray-button"} onClick={_clearLocalData}>
                      {t("payment.success.email.buttons.cancel")}</CustomButton>
                    <CustomButton className={"red-button"} disabled={!email?.length} onClick={() => validateEmail()}>
                      {t("payment.success.email.buttons.send")}</CustomButton>
                  </>
                )}
              </div>
            </div>

            <CustomKeyBoard keyboardRef={keyboardRef} onChange={(value) => {
              setEmail(value)
              setIsValidateEmailError(false)
            }}/>
          </>
        ) : (
          <Row style={{width: '100%', height: '100%'}}>
            <Col span={12} className={"left-column"}>
              <div className={"like-icon"}>
                <svg width="145" height="135" viewBox="0 0 145 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M72.19 134.107C74.5501 134.107 77.9513 132.094 81.1443 130.011C119.391 105.092 144.38 75.383 144.38 45.6047C144.38 18.3252 125.43 0 102.732 0C88.5716 0 78.2984 7.77431 72.19 19.1581C66.151 7.84372 55.8084 0 41.6481 0C18.9499 0 0 18.3252 0 45.6047C0 75.383 24.9888 105.092 63.2357 130.011C66.4287 132.094 69.8299 134.107 72.19 134.107Z" fill="white"/>
                </svg>
              </div>
              <div className={"payment-success-text"}>
                <span>{t("payment.success.orderPaid")}</span>
                <span>{t("payment.success.wordsOfGratitude")}</span>
              </div>
              <div className={"buttons-wrapper"}>
                {collectEmailForReceipt && (
                  <CustomButton className={"transparent-button"} onClick={() => setDisplaySendingEmailForm(true)}>
                    {t("payment.success.buttons.sendEmail")}
                  </CustomButton>
                )}

                <CustomButton className={"white-button"} onClick={_returnToCatalog}>
                  {t("payment.success.buttons.return")}
                </CustomButton>
              </div>
            </Col>
            <Col span={12}/>
          </Row>
        )}
      </SuccessfulPaymentModalWrapper>
    </Modal>
  )
})
