import {useGetLocale, useSetLocale, useTranslate} from '@refinedev/core';
import {useSettings} from "context";
import React, { useMemo } from "react";


export const LocaleName: React.FC<{
  entity: any,
  langs: string[]
}> = ({ entity, langs }) => {

  const locale = useGetLocale();
  const currentLocale = locale();

  const nameIndex = useMemo(() => !currentLocale ? -1 : langs?.indexOf(currentLocale), [currentLocale, langs]) || -1

  return (
    <>{nameIndex < 0 ? entity?.name : entity?.names[nameIndex] || entity?.name || ''}</>
  )
}