import React from "react";

export const RedSend: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_241_1190)">
        <path d="M28.4419 16.2058C28.4543 15.2502 27.9367 14.3719 27.0961 13.9109L7.5069 3.14805C6.62949 2.65216 5.59519 2.71308 4.76842 3.27765C3.92648 3.85172 3.49554 5.21831 3.7309 6.20533L5.55964 13.867C5.74737 14.6527 6.45052 15.2059 7.25946 15.2024L18.1608 15.1686C18.719 15.1574 19.1718 15.6101 19.1606 16.1684C19.1589 16.7171 18.7128 17.1632 18.1546 17.1744L7.24286 17.1997C6.43392 17.2013 5.72733 17.7569 5.53472 18.5438L3.64132 26.2359C3.41143 27.1449 3.67353 28.0524 4.3149 28.6938C4.39036 28.7692 4.47525 28.8541 4.56019 28.9201C5.39079 29.5609 6.47858 29.6426 7.40731 29.1478L27.0629 18.4902C27.9064 18.0429 28.4295 17.1614 28.4419 16.2058Z" fill="#D92351"/>
      </g>
      <defs>
        <clipPath id="clip0_241_1190">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}