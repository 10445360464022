import {EBonusSystemType, EPaymentMethods} from "types/constants";
import {ICart, ICartProduct} from "types";
import {normalizeCurrencyValue, uuidV4} from "libs/utils";

interface formatterPaymentBodyType {
  products: ICartProduct[],
  paymentType: any,
  selectedPaymentMethod: EPaymentMethods | EBonusSystemType | null,
  selectedPromoCode: string,
  bonusCard: any,
  cart: ICart,
  spentBonusCount: number
}

export const formatterPaymentBody = ({
  products, paymentType, selectedPaymentMethod, selectedPromoCode, bonusCard, cart, spentBonusCount
}: formatterPaymentBodyType) => {
  return {
    basket: products?.reduce((obj: any, p) => ({
      ...obj,
      [p.id]: {
        quantity: obj[p.id] ? obj[p.id]?.quantity + 1 : p.quantity || 1,
        marking_codes: !p.markingCode ? [] : obj[p.id]?.marking_codes ? [...obj[p.id]?.marking_codes, p.markingCode] : [p.markingCode]
      }
    }), {}),
    payment_type: paymentType,
    payment_method: selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_SBP ? 'sbp' : null,
    //order_sum: selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_ON_LUNCH ? 0 : cart?.total_amount, // сумма - бонусы
    spent_points: [EPaymentMethods.PAYMENT_TYPE_ON_LUNCH, EPaymentMethods.PAYMENT_TYPE_YANDEX_EDA, EPaymentMethods.PAYMENT_TYPE_YANDEX_QR,
      EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE].includes(selectedPaymentMethod as EPaymentMethods) ? cart?.total_amount : spentBonusCount, // бонусы ?
    promo_code: selectedPromoCode, // промокод
    // bonus_system_user_id: bonusSystemUserId == null ? bonusSystemUserId : '' + bonusSystemUserId,
    bonus_system_data: bonusCard && {
      user_id: bonusCard.userId == null ? null : ('' + bonusCard.userId),
      card_id: bonusCard.cardId || null,
    } || null,
    email_for_receipt: '' //emailForReceipt,
  }
}

interface formatterYPaymentBodyType {
  products: ICartProduct[],
  paymentType: any,
  paymentToken: string,
  cart: ICart,
  spentBonusCount: number
}

export const formatterYPaymentBody = ({
                                       products, paymentType, paymentToken, cart
                                     }: formatterYPaymentBodyType) => {
  return {
    // @ts-ignore
    yandex_payment_type: {
      [EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE]: 'badge',
      [EPaymentMethods.PAYMENT_TYPE_YANDEX_QR]: 'qr',
      [EPaymentMethods.PAYMENT_TYPE_YANDEX_EDA]: 'eda',
    }[paymentType],
    order_data: {
      unique_id: uuidV4(),
      sum: normalizeCurrencyValue(cart.total_amount),
      discount: normalizeCurrencyValue(
        cart.initial_amount - cart.total_amount
      ),
      items: (cart.lines || []).reduce((arr: any[], item: any) => {
        const product = products.find(p => p.id === item.product_id)
        return [...arr, {
          name: product?.name,
          quantity: +item.quantity,
          price: +item.product_total_price,
          sum: +item.amount,
          discount: normalizeCurrencyValue(
            (+item.initial_amount) - (+item.amount)
          ),
          vat_type: product?.vatType,
        }];
      }, []),
      payment_method: (
        (paymentType === EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE)
          ? null
          : {
            name: 'token',
            data: {
              token: paymentToken || null //paymentToken,
            }
          }
      ),
    },
  }
}