import styled from "styled-components";

export const ProductImageWrapper = styled.div`
  width: 126px;
  height: 126px;
  min-height: 126px;
  margin-bottom: 14px;
  position: relative;

  &.small-catalog-preview {
    width: 110px;
    height: 110px;
    min-height: 110px;
  }

  &.cart-preview {
    width: 60px;
    min-width: 60px;
    height: 60px;
    min-height: 60px;
    margin-inline-end: 20px;
  }

  &.modal-full-image {
    width: 400px;
    height: 400px;
    min-height: 400px;
    margin-bottom: 0;

    .image-not-exist, img {
      border-radius: 32px;
    }
  }

  .quantity-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(0, 0, 0, 0.6);
    border-radius: 20px;

    color: ${({theme: {colors}}) => colors.white};
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
  }
  
  .info-button {
    position: absolute;
    bottom: 8px;
    right: 8px;
    height: 24px;
    cursor: pointer;
  }
`;


export const BadgeContainer = styled.div<{
  discount?: boolean
}>`
  &.product-badge-container {
    position: absolute;
    inset-inline-start: -9px;
    top: -2px;

    .rotate {
      transform: rotate(${({theme: {rtl}}) => rtl ? '' : '-'}30deg);
    }

    .product-badge {
      display: flex;
      align-items: center;
      padding-block: 4px 5px;
      padding-inline: 6px;    
      border-radius: 30px;
      margin-bottom: 4px;

      ${({theme: {fonts}}) => fonts.captionXS};

      transform: rotate(${({theme: {rtl}}) => rtl ? '' : '-'}30deg)  translateX(-4px) translateY(0);

      &.badge-discount, &.badge-promo {
        z-index: 2;
        color: ${({ theme: { colors } }) => colors.white};
        background: ${({ theme: { colors } }) => colors.red};
        font-size: 12px;
      }

      &.badge-discount {
        max-width: 65px;
      }

      &.badge-promo {
        max-width: 49px;
        ${({ discount, theme: { rtl } }) => !discount ? '' : `transform: rotate${rtl ? '' : '-'}30deg) translateX(-4px) translateY(-6px)`};
      }

      &.badge-new {
        z-index: 4;
        position: relative;
        background: ${({ theme: { colors } }) => colors.yellow};
        color: ${({ theme: { colors } }) => colors.textBlack};
      }
    }
  }
`