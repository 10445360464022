import {useGetLocale, useList} from "@refinedev/core";
import React, {FC, PropsWithChildren, ReactNode, useMemo} from "react";
import {ICategory, IProduct, IPromotion, ITag} from "types";


interface StateCatalogData extends PropsWithChildren {
    products: IProduct[],
    promotions: IPromotion[],
    categories: ICategory[],
    tags: ITag[],
    popularProducts: IProduct[],
    extraForOrderProducts: IProduct[],

    isLoading: boolean,

    onRefetchCatalogData?: () => void,

    children?: ReactNode | undefined
}


const initialState = {
    products: [],
    promotions: [],
    categories: [],
    tags: [],
    extraForOrderProducts: [],
    popularProducts: [],
    isLoading: false
};


export const CatalogDataContext = React.createContext<StateCatalogData>(initialState);


export const CatalogDataProvider: React.FC<PropsWithChildren> = React.memo((props) => {

    const locale = useGetLocale();
    const currentLocale = locale();

    // @ts-ignore
    const {data: catalogData, isLoading, refetch: handleProductRefetch} = useList({
        resource: "catalog",
        dataProviderName: "catalog",
        meta: {
            lang: currentLocale
        }
    })

    const handleCatalogDataRefetch = async () => {
        await handleProductRefetch()
    }

    const value = useMemo(
      () => ({
          isLoading: isLoading,
          // @ts-ignore
          products: catalogData?.data?.products || [],
          // @ts-ignore
          categories: catalogData?.data?.categories,
          // @ts-ignore
          tags: catalogData?.data?.tags || [],
          // @ts-ignore
          promotions: catalogData?.data?.promotions || [],
          // @ts-ignore
          popularProducts: catalogData?.data?.popularProducts || [],
          // @ts-ignore
          extraForOrderProducts: catalogData?.data?.extraForOrderProducts|| [],
      }),
      [catalogData, isLoading],
    );

    return <CatalogDataContext.Provider value={{...value,
        onRefetchCatalogData: handleCatalogDataRefetch}} { ... props}/>;
});

export const useCatalogData = (): StateCatalogData => {
    const context = React.useContext(CatalogDataContext);
    if (context === undefined) {
        throw new Error(`useUI must be used within a UIProvider`);
    }
    return context as StateCatalogData;
};

export const ManagedCatalogDataContext: FC<PropsWithChildren> = React.memo(({ children }) => (
    <CatalogDataProvider>
        {children}
    </CatalogDataProvider>
));
