import styled from "styled-components";
import {LoaderWrapper} from "components/common";
import {FullScreenModalWrapper} from "components/ui/styled/common.styles";


export const PromoCodeModalWrapper = styled(FullScreenModalWrapper)`
  background-image: url("/images/b269d9a8f85c3b751dae839011dd7aa5.png");
  
  ${LoaderWrapper} {
    position: absolute;
    bottom: 294px
  }
`;
