import {ITag} from "types";
import React from "react";
import {TagsWrapper, TagWrapper} from "./TagList.style";
import ScrollContainer from "react-indiana-drag-scroll";
import {useTranslate} from '@refinedev/core';
import {LocaleName} from '../common/LocaleName';
import {useGlobalSettings} from '../../context';


interface ICategoryListProps {
  tags: ITag[],
  selectedTagIds: string[]
  onSelectTags: (ids: string[]) => void
}

export const TagList: React.FC<ICategoryListProps> = React.memo(({ tags, selectedTagIds, onSelectTags }) => {

  const t = useTranslate();
  const { settings } = useGlobalSettings();

  if (!tags?.length) {
    return null
  }

  const _selectTags = (id: string) => {
    onSelectTags?.(!selectedTagIds?.includes(id) ? [...selectedTagIds, id] : selectedTagIds?.filter(tId => tId !== id))
  }

  return (
    <TagsWrapper>
      <ScrollContainer className="scroll-container" vertical={false} horizontal={true}>
        <div className={"swiper-slide"}>
          <TagWrapper className={!selectedTagIds?.length ? 'selected-tag' : ''} onClick={() => onSelectTags?.([])}>
            <div className={"tag-title"}>{t("catalog.tags.all")}</div>
          </TagWrapper>
        </div>

        {tags.map((tag: ITag) => (
          <div className={"swiper-slide"} key={tag.id}>
            <TagWrapper className={selectedTagIds?.includes(tag.id) ? 'selected-tag' : ''} onClick={() => _selectTags(tag.id)}>
              <div className={"tag-title"}>
                <LocaleName entity={tag} langs={settings?.languages || []}/>
              </div>
            </TagWrapper>
          </div>
        ))}
      </ScrollContainer>
    </TagsWrapper>
  )
})