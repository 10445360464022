import axios, {AxiosInstance, AxiosResponse, AxiosRequestConfig} from 'axios';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {
  }
}

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(config: AxiosRequestConfig) {
    this.instance = axios.create({...config, withCredentials: true});

    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError,
    );
  };

  private _handleResponse = ({data}: AxiosResponse) => data;

  protected _handleError = (error: any) => Promise.reject(error);
}