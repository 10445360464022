import {useGetLocale, useSetLocale, useTranslate} from '@refinedev/core';
import {useGlobalSettings, useSettings} from "context";
import React, { useMemo } from "react";


export const LocaleDescription: React.FC<{
  entity: any
}> = ({ entity }) => {

  const locale = useGetLocale();
  const currentLocale = locale();

  const { settings } = useGlobalSettings()

  const nameIndex = useMemo(() => !currentLocale ? -1 : settings?.languages?.indexOf(currentLocale), [currentLocale, settings]) || -1

  return (
    <>{nameIndex < 0 ? entity?.description || '' : entity?.descriptions[nameIndex] || entity?.description || ''}</>
  )
}