import styled from "styled-components";

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 24px;

  .scroll-container {
    display: flex;
    padding-inline-end: 26px;
    height: 62px;
    gap: 8px;
    align-items: center;
    width: 100%;
  }

  .swiper-slide {
    max-width: max-content;
  }
`;

export const TagWrapper = styled.div`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding-block: 20px 22px;
  padding-inline: 24px;
  white-space: nowrap;

  position: relative;
  overflow: hidden;

  //margin-right: 8px;
  cursor: pointer;

  &.selected-tag {
    background: rgba(255, 255, 255, 0.3);
  }

  .tag-title {
    ${({theme: {fonts}}) => fonts.subheaderXSBolder};
    color: ${({theme: {colors}}) => colors.white};
  }
`;
