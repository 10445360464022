import React, {PropsWithChildren, useEffect} from "react";
import {LayoutWrapper} from "./Layout.style";
import {Header} from "components/common";
import {useGlobalSettings, useUI, useSearchResults, usePayment} from "context";
import {useGlobalClean} from "libs/hooks";
import {useIdleTimer} from 'react-idle-timer';
import {INACTIVITY_TIME_SEC, REFETCH_DATA_TIME_SEC} from '../../../types/constants';


interface ILayoutProps extends PropsWithChildren {
  isInactivityUser: boolean
}

let inactiveTimeout: ReturnType<typeof setInterval> | null;

export const Layout: React.FC<ILayoutProps> = ({children, isInactivityUser}) => {
  const { displaySearchResults, toggleInactivityUser } = useUI();
  const { settings } = useGlobalSettings()

  const { displayQuickResult, onChangeToggleDisplayQuickResult } = useSearchResults()
  const { onRefreshData } = useGlobalClean()
  const { isPaymentLoading, isPaymentWaiting } = usePayment();

  useEffect(() => {
    if (settings && !settings?.enableSplashScreen) {
      if (isInactivityUser) {
          if (!inactiveTimeout) {
            //console.log('Запускаем цикл обновления данных')
            inactiveTimeout = setInterval(() => {
              //console.log('Обновляем данные', inactiveTimeout)
              onRefreshData()
            }, REFETCH_DATA_TIME_SEC * 1000);
          }
      } else {
        if (inactiveTimeout) {
          clearInterval(inactiveTimeout)
          inactiveTimeout = null
        }
      }
    }
  }, [settings, isInactivityUser])

  const toggleDisplayQuickResults = (e: React.MouseEvent<HTMLElement>) => {
    // console.log('----- тип действия в Layout:', e?.type);

    if (settings && !settings?.enableSplashScreen && isInactivityUser && (e?.type === 'click')) {
      toggleInactivityUser()
    }

    if (!displaySearchResults) {
      return;
    }
      // @ts-ignore
    if (e?.target?.localName === 'path') {
      return
    }

    const checkElementId: any = (e: any) => {
      return e?.id === "dropdown-for-quick-answers" ? true : !e.parentElement ? false : checkElementId(e?.parentElement)
    }

    // @ts-ignore
    if (displayQuickResult && displaySearchResults && !(checkElementId(e?.target)) && !(e?.target?.className?.includes?.('hg-button') || e?.target?.className?.includes?.('hg-row'))) {
      onChangeToggleDisplayQuickResult()
    }
  }

  const handleIdle = async () => {
    // console.log(!isInactivityUser ? 'Переход в неактивный режим' : 'Терминал находится в неактивном режиме')
    if (!isInactivityUser) {
      toggleInactivityUser()
      // console.log('Рефреш при переходе в неактивный режим')
      await onRefreshData()
    }
  }

  useIdleTimer({
    timeout: INACTIVITY_TIME_SEC * 1000,
    throttle: 0,
    onIdle: handleIdle,
    disabled: isPaymentLoading || isPaymentWaiting,
    //onAction: onIdleAction
  })


  const onScreenAction = async () => {
    if (isInactivityUser) {
      toggleInactivityUser()
      await onRefreshData()
    }
  }

  return (
    <>
      <LayoutWrapper displaySearchResults={displaySearchResults}
                     isInactivityUser={isInactivityUser}
                     onClick={toggleDisplayQuickResults}
                     className="wrapper">
        {(!settings || settings?.enableSplashScreen) && (
          <div className={"video-plug"} onClick={onScreenAction} onTouchMove={onScreenAction}>
            <video autoPlay muted loop src={settings?.homeVideoUrl}/>
          </div>
        )}

        {/*<div className={"video-plug"} onClick={onScreenAction} onTouchMove={onScreenAction}>*/}
        {/*  <video autoPlay muted loop src={settings?.homeVideoUrl}/>*/}
        {/*</div>*/}

        <Header/>
        { children }
      </LayoutWrapper>
    </>
  )
}
