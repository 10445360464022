import React, {FC, PropsWithChildren, useCallback, useMemo} from "react";
import {ICategory, IProduct, ITag} from "types";
import {useCatalogData} from "./catalogData";
import urlJoin from "url-join";
import {API_URL} from "types/constants";
import {productFormatter} from "customProviders";

export enum EQuickResultTypes {
  tag= 'tag',
  category= 'category',
  product= 'product',
}

interface ISearchQuickResult {
  image?: string | null,
  id: string,
  name: string,
  tagIds?: string[],
  initialValue: IProduct | ICategory | ITag,
  type: EQuickResultTypes
}

export interface SearchState {
  searchValue: string;
  searchResults: ICategory[],
  searchTags: ITag[],
  searchProducts: IProduct[],
  selectedCategoryId: string | null,
  displayQuickResult: boolean,
  quickResults: ISearchQuickResult[],
  quickResult: ISearchQuickResult | null,
  selectQuickResult?: string | null
  isScanning: boolean
}

export interface SearchContextType extends SearchState {
  onClose: () => void;
  onSearchContextClear: () => void;
  onResetIsScanning: () => void;
  onChangeSearchValue: (value: string, categories?: ICategory[], isScanning?: boolean) => void
  onSetSearchResult: (value: string, result: IProduct[]) => void
  onChangeToggleDisplayQuickResult: () => void
  onSelectQuickResult: (value: ISearchQuickResult | null) => void
  onSelectCategoryId: (value: string | null) => void
}

const initialState: SearchState = {
  searchValue: '',
  selectedCategoryId: null,
  searchResults: [],
  searchProducts: [],
  searchTags: [],
  displayQuickResult: false,
  quickResults: [],
  quickResult: null,
  isScanning: false
};

export const SearchResultsContext = React.createContext<SearchContextType | null>(null);


enum ESearchActions {
  RESET = 'RESET_STATE',
  SET_SEARCH_VALUE = 'SET_SEARCH_VALUE',
  SET_SELECTED_CATEGORY_ID = 'SET_SELECTED_CATEGORY_ID',
  SELECT_QUICK_RESULT_VALUE = 'SELECT_QUICK_RESULT_VALUE',
  TOGGLE_DISPLAY_QUICK_RESULT = 'TOGGLE_DISPLAY_QUICK_RESULT',
  RESET_IS_SCANNING = 'RESET_IS_SCANNING',
}

type SearchAction = {
  type: ESearchActions.SET_SEARCH_VALUE,
  value: string,
  catalogData: {
    categories: ICategory[],
    tags: ITag[],
    popularProducts: IProduct[],
    searchProducts: IProduct[],
  },
  isScanning: boolean
} | {
  type: ESearchActions.SET_SELECTED_CATEGORY_ID,
  value: string | null
} | {
  type: ESearchActions.RESET
} | {
  type: ESearchActions.TOGGLE_DISPLAY_QUICK_RESULT
} | {
  type: ESearchActions.RESET_IS_SCANNING
} | {
  type: ESearchActions.SELECT_QUICK_RESULT_VALUE,
  value: ISearchQuickResult,
  catalogData: {
    categories: ICategory[],
    tags: ITag[],
    popularProducts: IProduct[]
  }
}


function searchReducer(state: SearchState, action: SearchAction) {
  switch (action.type) {
    case ESearchActions.RESET: {
      return {
        ...initialState,
        //isScanning: state.isScanning
      };
    }

    case ESearchActions.RESET_IS_SCANNING: {
      return {
        ...state,
        isScanning: false,
      };
    }


    case ESearchActions.TOGGLE_DISPLAY_QUICK_RESULT: {
      return {
        ...state,
        displayQuickResult: !!state.quickResults?.length && !state.displayQuickResult,
      };
    }

    case ESearchActions.SELECT_QUICK_RESULT_VALUE: {
      const { value, catalogData: {categories} } = action;
      const searchVal = state.searchValue;

      let searchResults: ICategory[] = []
      if (value?.type === EQuickResultTypes.tag) {
        searchResults = categories?.reduce((arr: ICategory[], cat: ICategory) => {
          return [...arr, {
            ...cat,
            products: cat?.products?.filter(p => value.id && p?.tagIds.includes(value.id) && cat.productIds.includes(p.id) &&
              p.codes.some(c => c === searchVal) || p.names?.some(n => !!n?.toLowerCase().includes(searchVal?.trim().toLowerCase())))
          }]
        }, []).filter(c => !!c.products?.length)
      }

      if (value?.type === EQuickResultTypes.category) {
        return {
          ...state,
          selectedCategoryId: value?.id,
          displayQuickResult: false
        };
      }

      return {
        ...state,
        quickResult: value,
        searchResults: searchResults,
        displayQuickResult: false
      };
    }

    case ESearchActions.SET_SELECTED_CATEGORY_ID: {
      return {
        ...state,
        selectedCategoryId: action?.value
      };
    }

    case ESearchActions.SET_SEARCH_VALUE: {
      const {value, catalogData: {categories, tags, popularProducts, searchProducts}, isScanning} = action;
      let searchResults: ICategory[]  = []
      let quickResults: ISearchQuickResult[] = []

      if (value.trim()) {
        searchResults = categories;

        //TODO: здесь нужно переписать так, чтобы популярные сравнивались с тем, что нашлось в результатах поиска.
        // проверить как следует

        // @ts-ignore
        const searchQuickProducts: ISearchQuickResult[] = popularProducts?.reduce((arr: IProduct[], p: IProduct) => {
          //!p.name.toLowerCase().includes(value?.trim().toLowerCase()) ? arr :
          const isSearching = p.names?.some(n => !!n?.toLowerCase().includes(value?.trim().toLowerCase()))
            //searchResults?.some((c: ICategory) => c?.productIds?.some(pId => p?.id === pId))

          return !isSearching ? arr : [...arr, {
            image: p.image?.src || null,
            id: p.id,
            name: p.name,
            names: p.names,
            type: EQuickResultTypes.product,
            tagIds: p?.tagIds,
            initialValue: p
          }]
        }, []) || [];

        const searchProductCategories: ISearchQuickResult[] = searchResults?.filter(c => c.productIds.some(pIds => searchQuickProducts.some(p => p.id === pIds)))
          .map(c => ({
            image: c?.image?.src || null,
            id: c.id,
            name: c.name,
            names: c.names,
            type: EQuickResultTypes.category,
            initialValue: c
        })) || []

        const searchProductTags: ISearchQuickResult[] = tags?.filter(t => searchQuickProducts.some(p => p?.tagIds?.includes(t.id))).map(t => ({
          image: null,
          id: t.id,
          name: t.name,
          names: t.names,
          type: EQuickResultTypes.tag,
          initialValue: t
        })) || []

        quickResults =  [...searchProductTags.slice(0, 3), ...searchProductCategories.slice(0, 3), ...searchQuickProducts.slice(0, 15)]
      }

      return {
        ...state,
        isScanning: isScanning,
        searchValue: value.trim(),
        displayQuickResult: !!quickResults?.length,
        quickResults,
        quickResult: null,
        searchResults,
        searchProducts,
        searchTags: tags?.filter(t => searchProducts.some(p => p?.tagIds?.includes(t.id)))
      };
    }
  }
}

export const SearchResultsProvider: FC<PropsWithChildren> = (props) => {

  const [state, dispatch] = React.useReducer(searchReducer, initialState);

  const { categories, tags, popularProducts } = useCatalogData()

  const handleChangeSearchValue = useCallback(
    (value: string, cats?: ICategory[], isScanning?: boolean) => {
      if (!value?.trim()) {
        return;
      }

      // @ts-ignore
      const params = new URLSearchParams({
        search_text: value?.trim(),
        only_actual: true,
        page_size: 100,
      })

      fetch(`${urlJoin(API_URL, `/store/products/`)}?${params}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async (response) => {
          const result = (await response?.json()).map((p: any, i: number) => productFormatter(p, i))

          let productCategories = categories?.reduce((arr: ICategory[], group: ICategory) => {
            return [...arr, {
              ...group,
              products: result.reduce((arr: IProduct[], p: IProduct) => {
                //console.log(group.productIds, product)
                return p.group !== group.id ? arr : [...arr, p]
              }, [])
            }]
          }, [])
            .filter(c => !!c?.products?.length)

          return dispatch({type: ESearchActions.SET_SEARCH_VALUE, isScanning: !!isScanning, value: value, catalogData: {
              categories: productCategories, tags, popularProducts, searchProducts: result
            }})
        })
    },
    [categories, tags, popularProducts, dispatch])


  const handleSetSearchResult = useCallback(
    (value: string, result: IProduct[]) => {
      let productCategories = categories?.reduce((arr: ICategory[], group: ICategory) => {
        return [...arr, {
          ...group,
          products: result.reduce((arr: IProduct[], p: IProduct) => {
            //console.log(group.productIds, product)
            return p.group !== group.id ? arr : [...arr, p]
          }, [])
        }]
      }, [])
        .filter(c => !!c?.products?.length)

      return dispatch({type: ESearchActions.SET_SEARCH_VALUE, value: value,  isScanning: false, catalogData: {
          categories: productCategories, tags, popularProducts, searchProducts: result
        }})
    },
    [dispatch, categories, tags, popularProducts],
  );


  const handleClose = useCallback(
    () => dispatch({type: ESearchActions.RESET}),
    [dispatch],
  );

  const handleCategoryIdSelect = useCallback(
    (value: string | null) => dispatch({type: ESearchActions.SET_SELECTED_CATEGORY_ID, value: value}),
    [dispatch],
  );

  const toggleDisplayQuickResult = useCallback(
    () => {
      return dispatch({type: ESearchActions.TOGGLE_DISPLAY_QUICK_RESULT})
    }, [dispatch]
  );

  const resetIsScanning = useCallback(
    () => {
      return dispatch({type: ESearchActions.RESET_IS_SCANNING})
    }, [dispatch]
  );

  const selectQuickResult = useCallback(
    (quickValue: ISearchQuickResult | null) => {
      if (!quickValue) {
        handleChangeSearchValue(state.searchValue)
        return
      }
      if (quickValue.type === EQuickResultTypes.product) {
        handleChangeSearchValue(quickValue?.name)
      }
      return dispatch({type: ESearchActions.SELECT_QUICK_RESULT_VALUE, value: quickValue, catalogData: {
          categories, tags, popularProducts
        }})

    }, [categories, tags, popularProducts, state, dispatch]
  );

  const value = useMemo(() => ({
    ...state
  }), [state, dispatch, categories, tags, popularProducts])

  // @ts-ignore
  return (
    <SearchResultsContext.Provider value={{
      ...value,

      onClose: handleClose,
      onSearchContextClear: handleClose,
      onChangeSearchValue: handleChangeSearchValue,
      onSetSearchResult: handleSetSearchResult,
      onSelectQuickResult: selectQuickResult,
      onSelectCategoryId: handleCategoryIdSelect,
      onChangeToggleDisplayQuickResult: toggleDisplayQuickResult,
      onResetIsScanning: resetIsScanning,
    }} {...props} />
  );
};

export const useSearchResults = (): SearchContextType => {
    const context = React.useContext(SearchResultsContext);
    if (context === undefined) {
        throw new Error(`useUI must be used within a UIProvider`);
    }
    return context as SearchContextType;
};

export const ManagedSearchResultsContext: FC<PropsWithChildren> = ({ children }) => (
    <SearchResultsProvider>
        {children}
    </SearchResultsProvider>
);
