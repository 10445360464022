import styled from "styled-components";
import {ButtonWrapper} from "components/ui";

export const PromotionsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 24px;

  .scroll-container {
    display: flex;
    height: 164px;
    padding-inline-end: 26px;
    gap: 8px;
    align-items: center;
    width: 100%;
  }

  .swiper-slide {
    max-width: 400px;
    //margin-inline-end: 8px;
  }
`;

export const PromotionWrapper = styled.div<{
  imageUrl?: string
}>`
  background: ${({ theme: { colors } }) => colors.red} url(${({imageUrl}) => imageUrl}) left top no-repeat;
  background-size: cover;
  border-radius: 24px;
  max-width: 400px;
  min-width: 400px;
  height: 164px;
  position: relative;
  overflow: hidden;

  margin-inline-end: 8px;
  cursor: pointer;

  display: flex;
  
  .promotion-container {
    display: flex;
    flex: 1;
    padding-block: 20px;
    padding-inline: 16px;
    flex-direction: column;
    height: 100%;
    z-index: 3;
  }

  ${ButtonWrapper}  {
    .white-button {
      padding-block: 8px;
      padding-inline: 12px;
      border-radius: 12px;

      ${({ theme: { fonts } }) => fonts.captionXS};
    }
  }

  .promotion-title {
    ${({ theme: { fonts } }) => fonts.subheaderSBolder};
    color:  ${({ theme: { colors } }) => colors.white};
    white-space: pre-wrap;
    flex: 1;
    max-width: 256px;
  }

  .promotion-img-container {
    position: absolute;
    inset-inline-end: 0;
    bottom: 0;
    min-width: 155px;
    display: flex;
    align-items: flex-end;
    z-index: 1;
  }

  .promotion-img {
    width: 100%;
    height: 155px;
    max-width: 256px;
    object-fit: contain;
  }
  
  &.cart-version {
    max-width: unset;
    min-width: auto;
    height: 100%;
    min-height: 170px;
    width: 100%;

    .promotion-title {
      max-width: 80%;
    }
  }
`;