import styled from "styled-components";

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 24px;
  
  .scroll-container {
    display: flex;
    height: 109px;
    padding-inline-end: 26px;
    gap: 8px;
    align-items: center;
    width: 100%;
  }
  
  .swiper-slide {
    max-width: 194px;
  }
`;

export const CategoryWrapper = styled.div<{
  imageUrl: string
}>`
  background: rgba(0, 0, 0, 0.3);
  border-radius: 24px;
  max-width: 194px;
  min-width: 194px;
  height: 109px;
  position: relative;
  overflow: hidden;
  
  margin-inline-end: 8px;
  cursor: pointer;
  display: block;
  
  &.selected-category {
    background: rgba(255, 255, 255, 0.3);
  }
  
  .category-title {
    ${({ theme: { fonts } }) => fonts.subheaderSBolder};
    color:  ${({ theme: { colors } }) => colors.white};
    display: block;
    word-break: break-word;
    padding: 20px 16px;
  }
  
  .shape-category-image {
    background: transparent url(${({imageUrl}) => imageUrl}) center center no-repeat;
    background-size: cover;
    height: 87px;
    width: 87px;
    float: ${({theme: {rtl}}) => rtl ? 'left' : 'right'};
    margin-top: 22px;
    shape-outside: ${({theme: {rtl}}) => rtl ? 'circle(98% at -2% 104%);' : 'circle(100% at 109% 111%)'};
    clip-path: ${({theme: {rtl}}) => rtl ? 'circle(98% at -2% 104%);' : 'circle(100% at 109% 108%)'};
    shape-margin: 0;
    
    &::before {
      display: ${({theme: {rtl}}) => rtl ? 'block' : 'none'};
      content: "";
      z-index: -1;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-image: url(${({imageUrl}) => imageUrl});
      transform: scaleX(-1);
    }
  }
  
  .category-image {
    border-radius: 50%;
    position: absolute;
    inset-inline-start: 107px;
    top: 26px;
    
    img {
      border-radius: 50%;
      width: 157px;
      height: 157px;
    }
  }
`;
