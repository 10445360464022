import {createGlobalStyle} from 'styled-components';

export const defaultTheme = {
  colors: {
    white: "#FFFFFF",
    textBlack: "#1A1A1A",
    gray: "#797D80",
    lightGray: "#EBECED",
    red: "#D92351",
    coralRed: "#D9214F",
    violet: "#9623F0",
    green: "#39BF71",
    yellow: "#F2C43C"
  },
  fonts: {
    headerS: `
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    `,

    headerXS: `
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; 
    `,
    headerSBold: `
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    `,

    subheaderS: `
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    `,

    subheaderSBolder: `
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    `,

    subheaderXSBolder: `
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
    `,

    subheaderSBold: `
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    `,

    subheaderMBold: `
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
    `,

    captionS: `
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    `,

    captionXS: `
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    `,

    keyBoardButton: `
      font-size: 24px;
      font-weight: 600;
      line-height: 40px;
    `,
  },
  rtl: false
};

export const GlobalStyle = createGlobalStyle<{
  rtl: boolean
}>`
  * {
    font-family: Inter;
    font-style: normal;
    direction: ${({ rtl }) => rtl ? 'rtl' : 'ltr'};
  }
  
  html body {
    width: 100%!important;
  }

  *::-webkit-scrollbar {
    width: 1px;
    background: linear-gradient(#b24b30, #7b007a);
  }

  *::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 100px;
  }

  *::-webkit-scrollbar-track-piece:end {
    background: transparent;
  }

  .full-width {
    width: 100%;
  }

  .flex {
    display: flex;
  }

  .flex-1 {
    flex: 1;
  }

  .vertical-center {
    align-items: center;
  }

  img.product-img {
    object-fit: cover;
  }

  .image-not-exist, img.product-img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
  }

  /* ANTD MODAL */
  .terminal-modal .ant-modal-content {
    margin: 0 37px;
    padding: 0;
    color: ${({ theme }: any) => theme?.colors?.textBlack};
    border-radius: 40px;
  }

  .full-screen-modal .ant-modal-content {
    margin: 0 37px;
    padding: 0;
    border-radius: 0;
  }


  .ant-modal-wrap {
    overflow: hidden !important;
  }

  .full-width .ant-modal-content {
    margin: 0;
  }

  .go-input-suffix {
    cursor: pointer;
  }
  
  /* DROPDOWN */
  
  .overlay-dropdown.ant-dropdown .ant-dropdown-menu {
    background: transparent;
    box-shadow: none;
    padding: 0;
    .ant-dropdown-menu-item {
      color: white;
      padding: 2px 0;
      
      &:hover {
        background: none;
      }
    }
    .ant-dropdown-menu-item-disabled {
      display: none;
    }
    
    
  }
`;