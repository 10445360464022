import React, {PropsWithChildren, useEffect, useRef} from "react";
import {IProduct} from "types";
import {ProductsWrapper} from "./ProductList.style";
import {ISettings, useSettings, useUI} from "context";
import { ProductPreview } from "components/ProductPreview";


interface IProductListProps extends PropsWithChildren {
  products: IProduct[]
  selectedCategoryId?: string | null
  selectedTagIds?: string[]
  settings: ISettings | null
}

export const ProductList: React.FC<IProductListProps> = React.memo(({
                                                                      products,
                                                                      selectedCategoryId = '',
                                                                      settings,
                                                                      selectedTagIds = [],
                                                                      children }) => {
  const ref = useRef<HTMLInputElement>(null);
  const { displaySearchResults } = useUI();

  useEffect(() => {
    if (ref?.current) {
      ref?.current?.scrollTo?.({
        top: 0
      })
    }
  }, [selectedTagIds, selectedCategoryId, ref])

  return (
    <>
      {children}
      <ProductsWrapper ref={ref} displaySearchResults={displaySearchResults}>
        {products.map((product: IProduct, idx: number) => (
          <ProductPreview product={product} key={product.id}
                          settings={settings}
                          displayProduct={!selectedTagIds.length || selectedTagIds?.every((tId: string) => product?.tagIds?.includes(tId))}/>
        ))}
      </ProductsWrapper>
    </>
  )
})

