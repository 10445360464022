import {useGetLocale, useSetLocale, useTranslate} from "@refinedev/core";
import { Row, Col, Dropdown } from "antd";
import React, {useEffect, useState} from "react";
import {Searchbar} from "components/common";
import Clock from 'react-live-clock';
import {
  HeaderButtonLangWrapper,
  HeaderButtonWrapper,
  HeaderLogoWrapper,
  HeaderSearchbarWrapper, HeaderTerminalNumberWrapper,
  HeaderWrapper
} from "./Header.style";
import {CustomButton, Modal} from "components/ui";
import {Question, Call} from "components/common/Icons";
import {useGlobalSettings, usePayment, useUI} from "context";
import {BonusModal, ContactModal, TerminalNumberModal, TermsOfUseModal} from "./HeaderModals";
import {EBonusSystemType} from "types/constants";


export const Header: React.FC = () => {
  const t = useTranslate();

  const { displaySearchResults, isInactivityUser, displayBonusAuthModal, toggleDisplayBonusAuthModal } = useUI();
  const { settings } = useGlobalSettings();

  //const [bonusModalVisibility, setBonusModalVisibility] = useState(false)
  const [contactModalVisibility, setContactModalVisibility] = useState(false)
  const [termOfUseModalVisibility, setTermOfUseModalVisibility] = useState(false)
  const [terminalNumberModalVisibility, setTerminalNumberModalVisibility] = useState(false)

  const { bonusCard,  isErrorBonusCard, isCheckingBonusCardNumber, onClearBonusCard } = usePayment()

  useEffect(() => {
    if (isInactivityUser) {
      toggleDisplayBonusAuthModal(false)
      setContactModalVisibility(false)
      setTermOfUseModalVisibility(false)
      setTerminalNumberModalVisibility(false)
    }
  }, [isInactivityUser])

  const closeBonusModal = () => {
    if (isErrorBonusCard) {
      onClearBonusCard();
    }
    toggleDisplayBonusAuthModal(false)
  }

  return (
    <HeaderWrapper displaySearchResults={!!displaySearchResults}>
      <div className={"logo-and-search"}>
        <div className={"logo-and-time"}>
          <HeaderLogoWrapper><img src={"images/logo.svg"} alt={"logo"}/></HeaderLogoWrapper>
          <Clock format={"DD-MM-yyyy HH:mm:ss"}
                 className={"current-date-and-time"}
                 ticking={true}/>
          {!!settings?.micromarketNumber && (
            <HeaderTerminalNumberWrapper onClick={() => setTerminalNumberModalVisibility(true)}>
              <TerminalNumberIcon/>
              <span>{settings?.micromarketNumber}</span>
            </HeaderTerminalNumberWrapper>
          )}
        </div>
        <HeaderSearchbarWrapper><Searchbar/></HeaderSearchbarWrapper>
      </div>
      <HeaderButtonWrapper>
        <Row gutter={[16, 0]}>
          {((settings?.posTerminal?.paymentEnabled || settings?.payWithBonusPointsOnly) && (settings?.bonusSystemType === EBonusSystemType.GOPOEDIM)) ? (
            <Col className={"flex vertical-center"}>
              {bonusCard?.cardId ? (
                <div className={"default-text card-link user-name"}>
                  <span>{bonusCard?.firstName || `😉`},&nbsp;{bonusCard?.allBonus}&nbsp;{t('layout.header.shortBonus')}</span>
                  <CustomButton className={"white-button"} onClick={onClearBonusCard}>
                    {t('layout.header.logout')}
                  </CustomButton>
                </div>
              ) : (
                <div className={"default-text card-link"} onClick={() => toggleDisplayBonusAuthModal(true)}>{t('layout.header.bonus')}</div>
              )}
            </Col>
          ) : (
            <div style={{width: '170px'}}></div>
          )}
          <Col>
            <div className={"rounded-button"} onClick={() => setContactModalVisibility(true)}><Call/></div>
          </Col>
          <Col>
            <div className={"rounded-button"} onClick={() => setTermOfUseModalVisibility(true)}><Question/></div>
          </Col>
          <Col>
            <SelectLang/>
          </Col>
        </Row>
      </HeaderButtonWrapper>

      <Modal setVisible={closeBonusModal} visible={displayBonusAuthModal} onClose={closeBonusModal} containerClassName={"header-modal-container"}>
        <BonusModal onClose={closeBonusModal}/>
      </Modal>

      <Modal setVisible={setTerminalNumberModalVisibility} visible={terminalNumberModalVisibility} onClose={() => setTerminalNumberModalVisibility(false)}>
        <TerminalNumberModal onClose={() => setTerminalNumberModalVisibility(false)}/>
      </Modal>

      <Modal setVisible={setTermOfUseModalVisibility} visible={termOfUseModalVisibility}
             onClose={() => setTermOfUseModalVisibility(false)} containerClassName={"header-modal-container"}>
        <TermsOfUseModal onClose={() => setTermOfUseModalVisibility(false)}/>
      </Modal>

      <Modal maxWidth={"658px"} setVisible={setContactModalVisibility} visible={contactModalVisibility}
             onClose={() => setContactModalVisibility(false)} containerClassName={"header-modal-container"}>
        <ContactModal onClose={() => setContactModalVisibility(false)}/>
      </Modal>
    </HeaderWrapper>
  );
};


const SelectLang: React.FC = () => {
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const currentLocale = locale();
  const { settings } = useGlobalSettings();

  return (
    <Dropdown menu={{ items: settings?.languages?.map((l: string) => ({
        label: <HeaderButtonLangWrapper><div className={"default-text language-text rounded-button"}><span>{l.toUpperCase()}</span></div></HeaderButtonLangWrapper>,
        key: l,
        disabled: currentLocale === l
      })),
      onClick: (e) => {
        // @ts-ignore
        //document.body.dir = e.key === 'ar' ? 'rtl' : 'ltr'
        //console.log(e.key)
        changeLanguage(e.key)
      }
    }} overlayClassName={"overlay-dropdown"} className={"default-text language-text rounded-button"} trigger={['click']}>
      <a onClick={e => e.preventDefault()}>
        <div>
          <span>{currentLocale?.toUpperCase()}</span>
        </div>
      </a>
    </Dropdown>
  )
}


const TerminalNumberIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1739_1731)">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.6721 27.8375C13.6721 26.89 12.94 26.1911 12.0665 26.1911H5.49431C4.63174 26.1911 3.88875 26.8986 3.88875 27.8375V34.4661C3.88875 35.3924 4.6385 36.1105 5.49431 36.1105H12.0665C12.9333 36.1105 13.6721 35.401 13.6721 34.4661V27.8375ZM13.6721 5.5353C13.6721 4.59762 12.9324 3.88891 12.0665 3.88891H5.49431C4.63934 3.88891 3.88875 4.60622 3.88875 5.5353V12.1639C3.88875 13.1011 4.63005 13.8084 5.49431 13.8084H12.0665C12.9417 13.8084 13.6721 13.1097 13.6721 12.1639V5.5353ZM36.1112 5.5353C36.1112 4.60622 35.3606 3.88891 34.5056 3.88891H27.9334C27.0675 3.88891 26.3278 4.59762 26.3278 5.5353V12.1639C26.3278 13.1097 27.0582 13.8084 27.9334 13.8084H34.5056C35.3699 13.8084 36.1112 13.1011 36.1112 12.1639V5.5353ZM17.0054 27.8375C17.0054 25.0764 14.8082 22.8578 12.0665 22.8578H5.49431C2.77209 22.8578 0.55542 25.0764 0.55542 27.8375V34.4661C0.55542 37.2078 2.77209 39.4439 5.49431 39.4439H12.0665C14.8082 39.4439 17.0054 37.2078 17.0054 34.4661V27.8375ZM17.0054 5.5353C17.0054 2.79168 14.8082 0.555573 12.0665 0.555573H5.49431C2.77209 0.555573 0.55542 2.79168 0.55542 5.5353V12.1639C0.55542 14.925 2.77209 17.1417 5.49431 17.1417H12.0665C14.8082 17.1417 17.0054 14.925 17.0054 12.1639V5.5353ZM39.4445 5.5353C39.4445 2.79168 37.2278 0.555573 34.5056 0.555573H27.9334C25.1917 0.555573 22.9945 2.79168 22.9945 5.5353V12.1639C22.9945 14.925 25.1917 17.1417 27.9334 17.1417H34.5056C37.2278 17.1417 39.4445 14.925 39.4445 12.1639V5.5353Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M37.5 23.3333C38.4205 23.3333 39.1667 24.0795 39.1667 25V35C39.1667 37.7614 36.9281 40 34.1667 40H25.8334C24.9129 40 24.1667 39.2538 24.1667 38.3333C24.1667 37.4129 24.9129 36.6667 25.8334 36.6667H34.1667C35.0872 36.6667 35.8334 35.9205 35.8334 35V25C35.8334 24.0795 36.5795 23.3333 37.5 23.3333Z" fill="white"/>
        <path d="M23.3333 25C23.3333 24.0795 24.0795 23.3333 25 23.3333H28.3333C29.2538 23.3333 30 24.0795 30 25V28.3333C30 29.2538 29.2538 30 28.3333 30H25C24.0795 30 23.3333 29.2538 23.3333 28.3333V25Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_1739_1731">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
