import styled from "styled-components";


export const KeyBoardOpenButtonWrapper = styled.div`
  display: flex;
  width: 180px;
  height: 74px;
  padding: 17px 0;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px 12px 0 0;
  background: ${({ theme: { colors } }) => colors.white};
  position: fixed;
  bottom: 0;

  :before {
    content: url(images/open-keyboad.svg);
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
`

export const KeyBoardWrapper = styled.section`
  &.keyboard-container {
    transform: translateY(380px);
    transition: all ease 0.5s;

    position: fixed;
    bottom: 0;
    width: 980px;

    .hg-theme-default {
      background: ${({ theme: { colors } }) => colors.lightGray};
      border-radius: 12px 12px 0 0;
      padding: 12px;
      height: 380px;
    }

    .hide-buttons {
      display: none;
    }

    .language-button {
      :before {
        content: url(images/lang-keyboard.svg);
        margin-inline-end: 8px;
        display: inline-block;
        vertical-align: middle;
        height: 32px;
        width: 32px;
        line-height: normal;
      }
      max-width: 158px;
    }

    .close-button {
      :before {
        content: url(images/close-keyboard.svg);
        display: inline-block;
        vertical-align: middle;
        height: 25px;
        width: 28px;
        line-height: normal;
      }
      max-width: 75px;
      span {
        display: none;
      }
    }
    
    .hg-rows {
      direction: ltr;
      .hg-row:not(:last-child) {
        margin-bottom: 6px;
      }

      height: 100%;
      display: flex;
      flex-direction: column;

      .hg-row {
        direction: ltr;
        display: flex;
        justify-content: center;
        flex: 1;

        .hg-button {
          margin-right: 0;
          margin-inline-end: 8px;
          height: 100%;

          ${({ theme: { fonts } }) => fonts.keyBoardButton};
          letter-spacing: -0.02em;

          color: ${({ theme: { colors } }) => colors.textBlack};

          &.backspace-button {
            background: ${({ theme: { colors } }) => colors.red};
            color: ${({ theme: { colors } }) => colors.white}
          }
        }
      }
    }

    &.opened-keyboard {
      opacity: 1;
      transform: translateY(0);
      z-index: 1020;
    }
  }
`;
