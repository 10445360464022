import React from "react";
import {Col, Row} from "antd";
import {DeleteKey, SuccessAction} from "components/Payment/PaymentModalStates/icons";
import styled from "styled-components";

interface ICustomKeyBoard {
  value?: string
  isValid: boolean,
  onChange: (value: string) => void
  onSubmit?: () => void
}


export const NumericKeyBoard: React.FC<ICustomKeyBoard> = ({ value = '', isValid, onSubmit, onChange }) => {

  const keyboardButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  return (
    <NumericKeyboardWrapper>
      <Row gutter={[12, 0]}>
        <Col span={18}>
          <Row gutter={[12, 12]}>
            {keyboardButtons.map((key) => (
              <Col key={key} span={8} onClick={() => onChange( `${value}${key}`)}><div className={"keyboard-button"}>{key}</div></Col>
            ))}
            <Col span={24} onClick={() => onChange(`${value}0`)}><div className={"keyboard-button"}>0</div></Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row gutter={[0, 12]}>
            <Col span={24}><div className={"keyboard-button"} onClick={() => onChange(value.slice(0, value.length - 1))}><DeleteKey/></div></Col>
            {!!onSubmit && (
              <Col span={24}><div className={`keyboard-button success-action${isValid ? '' : ' disabled'}`}
                                  onClick={() => onSubmit?.()}><SuccessAction/></div></Col>
            )}

          </Row>
        </Col>
      </Row>
    </NumericKeyboardWrapper>
  );
};

export const NumericKeyboardWrapper = styled.div`
  max-width: 380px;
  position: relative;
  padding: 24px;
  background: ${({ theme: { colors } }) => colors.lightGray};
  border-radius: 24px;
  
  display: flex;

  color: ${({ theme: { colors } }) => colors.textBlack};
  
  .numeric-button-container {
    
  }
  
  .keyboard-button {
    user-select: none;
    ${({ theme: { fonts } }) => fonts.keyBoardButton};
    letter-spacing: -0.02em;
    
    background:  ${({ theme: { colors } }) => colors.white};
    cursor: pointer;

    border: 1px solid #EBECED;
    border-radius: 12px;
    padding: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &.success-action {
      background: ${({ theme: { colors } }) => colors.green};
      
      &.disabled {
        background: ${({ theme: { colors } }) => colors.gray};
      }
    }
  }
`
