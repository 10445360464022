import {ICartProduct, IProduct} from "types";
import React from "react";
import {BadgeContainer, ProductImageWrapper} from "./ProductImage.style";
import {useTranslate} from "@refinedev/core";
import {getImageUrl} from "libs/utils";


interface IProductImageProps {
  product: IProduct | ICartProduct,
  isPreview?: boolean,
  showBadge?: boolean,
  discount?: boolean,
  isPromo?: boolean,
  className?: string,
  quantity?: number,
  onOpenInfo?: (e: any) => void
}

export const ProductImage: React.FC<IProductImageProps> = React.memo(({ product, isPreview, discount, isPromo,
                                                                        className, quantity = 0, onOpenInfo, showBadge = true }) => {

  const t = useTranslate()

  return (
    <ProductImageWrapper className={className}>
      {showBadge && (
        <BadgeContainer className={"product-badge-container"} discount={discount}>
          {(discount || isPromo) ? (
            <>
              {discount && (<div className={"product-badge badge-discount rotate"}>{t("product.badge.discount")}</div>)}
              {isPromo && (<div className={"product-badge badge-promo rotate"}>{t("product.badge.promoCode")}</div>)}
            </>
          ) : (
            <>{product?.isNew && (<div className={"product-badge badge-new rotate"}>{t("product.badge.new")}</div>)}</>
          )}
        </BadgeContainer>
      )}

      {!!quantity && (
        <div className={"quantity-shadow"}>
          {quantity}
        </div>
      )}

      {product?.image?.src ? (
        <img className={"product-img"} src={getImageUrl(product?.image?.src)} alt={product?.name}/>
      ) : (
        <div className={"image-not-exist"}/>
      )}

      {isPreview && (
        <div className={"info-button"} onClick={onOpenInfo}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
              <path d="M12 22.2715C6.3457 22.2715 1.6875 17.6133 1.6875 11.9492C1.6875 6.29492 6.33594 1.63672 12 1.63672C17.6543 1.63672 22.3125 6.29492 22.3125 11.9492C22.3125 17.6133 17.6641 22.2715 12 22.2715ZM11.9902 8.78516C12.8203 8.78516 13.5039 8.0918 13.5039 7.26172C13.5039 6.39258 12.8203 5.71875 11.9902 5.71875C11.1504 5.71875 10.457 6.39258 10.457 7.26172C10.457 8.0918 11.1504 8.78516 11.9902 8.78516ZM10.1543 17.4668H14.3047C14.8418 17.4668 15.2617 17.0859 15.2617 16.5293C15.2617 16.0215 14.8418 15.6113 14.3047 15.6113H13.3867V11.3828C13.3867 10.6504 13.0254 10.1816 12.3418 10.1816H10.3496C9.8125 10.1816 9.39258 10.5918 9.39258 11.0996C9.39258 11.6562 9.8125 12.0371 10.3496 12.0371H11.2871V15.6113H10.1543C9.61719 15.6113 9.19727 16.0215 9.19727 16.5293C9.19727 17.0859 9.61719 17.4668 10.1543 17.4668Z" fill="white"/>
            </g>
          </svg>
        </div>
      )}

    </ProductImageWrapper>
  )
})
