import styled from "styled-components";

export const ProductsWrapper = styled.div<{
  displaySearchResults?: boolean
}>`
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
  overflow: auto;
  overflow-x: hidden;
  
  margin-inline-end: 18px;
  margin-inline-start: -4px;
  padding-bottom: ${({ displaySearchResults }) => displaySearchResults ? '86px' : '80px' };

  &::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(255 255 255 / 60%);
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: ${({ displaySearchResults }) => displaySearchResults ? '86px' : '80px' };
  }

  .swiper-slide {
    max-width: 194px;
  }
`;
