import {HttpClient} from './http-client';
import { DConfigRequest, DItemActionSuccessfulRequest, TItemID } from 'maintenance/libs/types';
import {API_URL} from 'maintenance/libs/helpers/common';

class MainApi extends HttpClient {
  public constructor(baseURL: string = API_URL || '') {
    super({baseURL});
  }

  public getConfig = async () => this.instance.get<DConfigRequest>('/maintenance/config/');

  public doOperation = async (id: TItemID, processType: string) =>
    this.instance.post<DItemActionSuccessfulRequest>(`/maintenance/operations/${id}/do/?process_type=${processType}`);

  public clickDialogButton = async (id: TItemID, buttonId: number) =>
    this.instance.post<DItemActionSuccessfulRequest>(`/maintenance/operations/${id}/buttons/${buttonId}/click/`);
}

export default MainApi;