import {ProductsWrapper} from "components/ProductList/ProductList.style";
import styled from "styled-components";
import {CustomModalContentWrapper} from "components/ui/styled/common.styles";


export const EmptyCartWrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  //height: 100%;
  margin-inline-start: 40px;
  position: relative;

  height: calc(100vh - 160px);

  .empty-cart-text {
    white-space: break-spaces;
    text-align: center;

    font-size: 24px;
    font-weight: 500;
    line-height: 32px;

    color: ${({theme: {colors}}) => colors.white};
    opacity: 0.6;
    backdrop-filter: blur(2px);

    margin-bottom: 48px;
  }
    
  &.empty-search-result {
      justify-content: flex-start;
      margin-inline-start: 0;
      margin-top: 225px;
  }
`;


export const BonusCardTipWrapper = styled.div`
  background: ${({theme: {rtl}}) => rtl ? 'url("/images/bonus-tooltip-background-rtl.png")' : 'url("/images/bonus-tooltip-background.png")'};
  background-size: cover;
  background-position: center;
  border-radius: 24px;
  padding: 51px 42px 51px 56px;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: ${({theme: {colors}}) => colors.white};

  .col-flex-end {
    display: flex;
    height: 100%;
    min-width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  .bonus-tooltip-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .bonus-tooltip-header {
    ${({theme: {fonts}}) => fonts.headerXS};
  }

  .bonus-tooltip-text {
    ${({theme: {fonts}}) => fonts.subheaderS};
    width: 233px;
  }
`

export const CartWrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin-inline-start: 40px;

  padding-block: 26px;
  padding-inline: 40px;
  position: relative;

  height: 100%;
  //max-height: 907px;

  max-height: calc(100vh - 160px);
  overflow: hidden;
  // max-height: calc(100vh - 160px);
`;

export const CartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: flex;
  }

  .total-item-count {
    margin-inline-end: 19px;
    ${({theme: {fonts}}) => fonts.subheaderSBolder};

    color: ${({theme: {colors}}) => colors.white};
  }

  .reset-button {
    cursor: pointer;
  }
`

export const CartButtons = styled.div`
  position: sticky;
  inset-inline-start: 0;
  bottom: 20px;
  width: 100%;
  //padding: 0 20px;

  .initial-with-discount {
    justify-content: flex-end;

    .product-price {
      flex: 1;
      margin-inline-end: 12px;
      color: ${({theme: {colors}}) => colors.textBlack};

      &.discount-price {
        flex: 0;
        color: ${({theme: {colors}}) => colors.green};
        margin-inline-end: 14px;
      }

      &.old-price {
        flex: 0;
        text-decoration-line: line-through;
        color: ${({theme: {colors}}) => colors.gray};
      }
    }
  }


  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.3));
  z-index: 50;

  .total-amount {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: ${({theme: {colors}}) => colors.white};
    border-radius: 28px;

    padding: 0 24px;
    height: 84px;

    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: ${({theme: {colors}}) => colors.textBlack};
  }
`

export const ConfirmResetCartModalWrapper = styled(CustomModalContentWrapper)`
  min-width: 564px;
  height: 360px;
  padding-block: 50px 40px;
  padding-inline: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.bid-width {
    min-width: 656px
  }

  &.small-width {
    max-width: 656px
  }

  &.remove-items {
    max-width: 564px;
  }

  .confirm-title {
    ${({theme: {fonts}}) => fonts.headerS};
    text-align: center;
    margin-bottom: 24px;
    color: ${({theme: {colors}}) => colors.textBlack};
  }

  &.small-height {
    height: auto;

    .confirm-title {
      margin-bottom: 50px;
    }
  }

  .confirm-description {
    ${({theme: {fonts}}) => fonts.subheaderS};
    flex: 1;
    color: ${({theme: {colors}}) => colors.gray};
    text-align: center;
  }

  .confirm-buttons {
    display: flex;

    .red-button {
      margin-inline-start: 8px;
    }
  }

  @media (max-width: 760px) {
    max-width: 564px;
    min-width: 1px;
  }

  @media (max-width: 575px) {
    padding-block: 50px 40px;
    padding-inline: 45px;
  }
`


