import React, {Dispatch, FC, SetStateAction} from 'react';
import {Modal as AntdModal, ModalProps} from 'antd';
import {Close} from 'components/common/Icons/Close';
import styled from 'styled-components';


export const ModalCloseButton = styled.div<{
  isInContainer?: boolean
}>`
  top: ${({ isInContainer }) => isInContainer ? '49px' : '0' };
  inset-inline-end: ${({ isInContainer }) => isInContainer ? '52px' : '-34px' };
  width: 22px;
  height: 22px;
  position: absolute;
  cursor: pointer;
  z-index: 999;
`;

interface IModalProps extends ModalProps {
  visible?: boolean
  setVisible?: Dispatch<SetStateAction<boolean>>
  onClose?: () => void
  children?: React.ReactNode
  containerClassName?: string,
  wrapClassName?: string,
  maxWidth?: string,
  closeInContainer?: boolean,
  displayCloseButton?: boolean
}


export const Modal: FC<IModalProps> = ({ children, visible, setVisible, maxWidth,
                                         displayCloseButton = true, onClose,
                                         containerClassName, wrapClassName = 'terminal-modal',
                                         closeInContainer, ...props }) => {
  return (
    <AntdModal
      centered
      destroyOnClose={true}
      style={{
        overflow: 'hidden',
        maxWidth: maxWidth || '1074px'
      }}
      open={visible}
      footer={false}
      closable={false}
      width={'auto'}
      maskClosable={true}
      onCancel={() => setVisible?.(false)}
      wrapClassName={wrapClassName}
      className={containerClassName || ''}
      {...props}
    >
      {!!displayCloseButton && (
        <ModalCloseButton isInContainer={!!closeInContainer} onClick={() => {
          setVisible?.(false)
          onClose?.()
        }}>
          <Close />
        </ModalCloseButton>
      )}
      {children}
    </AntdModal>
  )
}
