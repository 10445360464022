import React, { FC, useCallback } from 'react';
import sanitizeHtml from 'sanitize-html';
import styles from 'maintenance/styles/Globals.module.css';
import {Button, Timeline, Typography, Spin} from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, LoadingOutlined} from '@ant-design/icons';
import {TDiagnosticsItem, TDoActionItem} from 'maintenance/libs/types';

const {Text} = Typography;

type TComponentsDItems = {
  item: TDiagnosticsItem,
  isError: boolean,
  isCurrentItem: boolean,
  pending?: boolean,
  doItemAction: TDoActionItem,
  error?: any
}
const antIcon = <LoadingOutlined style={{fontSize: 22}} spin/>;

const DiagnosticsItem: FC<TComponentsDItems> = ({
                                                  item,
                                                  isError,
                                                  doItemAction,
                                                  isCurrentItem,
                                                  pending= false
                                                }) => {

  return (
    <>
      {item?.title}
      {isError ? (
        <>
          {item?.error_instruction && (<div className="diagnostics-error-instruction"
                                            dangerouslySetInnerHTML={{__html: sanitizeHtml(item.error_instruction)}}></div>)}
        </>
      ) : (
        <>
          {(item?.instruction && !item?.dialog) && (<div className="diagnostics-instruction"
                                                         dangerouslySetInnerHTML={{__html: sanitizeHtml(item.instruction)}}></div>)}
        </>
      )}

      {(item?.dialog && !isError && isCurrentItem) && (
        <>
          <div className="diagnostics-dialog-instruction" dangerouslySetInnerHTML={{__html: sanitizeHtml(item.dialog?.text) || ''}}></div>
          <div className="diagnostics-button-action-wrapper">
            {item?.dialog.buttons.map((b, i) => (
              <Button size="large" key={b.id} type={i ? 'default' : 'primary'}
                      className="diagnostics-button-action"
                      onClick={() => doItemAction(i === 0, item?.id, b.id)}>
                {b.text}
              </Button>
            ))}
          </div>

        </>
      )}
    </>
  );
};

export default DiagnosticsItem;