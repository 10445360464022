import React from 'react';
import {DiagnosticsProcess} from 'maintenance/components/Diagnostics/DiagnosticsProcess';
import {useGetConfig} from 'maintenance/hooks/useGetConfig';
import {INITIAL_ITEM_INDEX} from 'maintenance/libs/helpers/common';
import { Layout, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import 'maintenance/styles/globals.css';
import styled from "styled-components";


const antIcon = <LoadingOutlined style={{fontSize: 36}} spin/>;
const DiagnosticsPage = () => {

  const config = useGetConfig();

  if (!config?.main_diagnostics?.operations) {
    return null;
  }

  return (
    <MaintenanceWrapper>
      {!config?.main_diagnostics?.operations ? (
        <Layout style={{minHeight: '100vh', justifyContent: 'center'}}>
          <Spin spinning={true} size="large" delay={500} indicator={antIcon}>
          </Spin>
        </Layout>
      ) : (
        <DiagnosticsProcess initialDItems={config?.main_diagnostics?.operations?.slice(0, INITIAL_ITEM_INDEX) || []}
                            diagnosticsItems={config?.main_diagnostics?.operations || []}
                            inactivityTimeout={config?.inactivity_timeout || 2}
                            operations={config?.additional_operations || []}/>
      )}
    </MaintenanceWrapper>

  );
};

//
// export const getServerSideProps: GetServerSideProps = async (context) => {
//   const api = new MainApi();
//   let result: DConfigRequest = {};
//   try {
//     result = await api.getConfig();
//   } catch(e) {
//     console.log('getConfig e', e);
//   }
//
//   return {
//     props: {
//       config: result.data || {}
//     }
//   };
// };


export default DiagnosticsPage;


const MaintenanceWrapper = styled.div`

  position: relative;
  display: flex;
  
  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  /* SIDER */
  .ant-layout-sider-zero-width-trigger .anticon {
    margin-top: 9px;
  }

  .ant-layout-sider-zero-width-trigger .anticon svg {
    width: 40px;
    height: 40px;
  }

  .arrow-right {
    display: none;
  }

  .arrow-left {
    display: block;
  }

  .ant-layout-sider-collapsed .arrow-right {
    display: block;
  }

  .ant-layout-sider-collapsed .arrow-left {
    display: none;
  }


  .ant-timeline-item-head {
    background: transparent !important;
  }

  .ant-menu .ant-menu-item .anticon {
    min-width: 24px;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item.custom-sider-item {
    padding: 18px;
    height: auto;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item.power-off-button,
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item.power-off-button:not(.ant-menu-item-selected),
  .ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item.power-off-button:not(.ant-menu-item-selected):active {
    background-color: transparent;
  }

  .power-off-button {
    display: flex !important;
    flex: 1;
    align-items: flex-end !important;
  }
  
  .container {
    padding: 0;
  }

  .main {
    min-height: 100vh;
    padding: 4rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .description {
    text-align: center;
  }

  .description {
    margin: 4rem 0;
    line-height: 1.5;
    font-size: 1.5rem;
  }

  .code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
  }

  .logo {
    height: 1em;
    margin-left: 0.5rem;
  }

  /* HEADER */
  .header-background {
    background: transparent;
    height: 140px;
  }

  .diagnostics-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .diagnostics-button-wrapper button {
    width: 370px;
    font-size: 24px;
    height: 68px;
    line-height: 117%;
  }

  .button-continue-diagnostics {
    margin-right: 48px;
  }


  /* START */
  .start-wrapper {
    margin: 4rem 0;
    display: flex;
    flex-direction: column;
    width: 600px;
    font-size: 22px;
  }

  .start-button {
    font-size: 48px;
    height: 120px;
    margin-bottom: 36px;
  }

  /* DIAGNOSTICS */
  .diagnostics-main {
    min-height: 100vh;
    padding: 4rem 0;
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .diagnostics-successful-execution {
    font-size: 30px;
    padding: 87px 0;
    text-align: center;
  }

  .diagnostics-timeline {
    width: 672px;
  }

  .diagnostics-button-action-wrapper {
    margin-top: 18px;
    display: flex;
  }

  .diagnostics-button-action {
    width: 170px;
    font-size: 24px;
    height: 65px;
    line-height: 110%;
  }

  .diagnostics-button-action:first-child {
    margin-right: 32px;
  }

  .diagnostics-item,
  .diagnostics-pending-item {
    font-size: 24px;
    padding-bottom: 28px;
  }

  .diagnostics-pending-item {
    font-style: italic;
  }

  .diagnostics-dialog-instruction,
  .diagnostics-error-instruction {
    padding-top: 16px;
  }

  .diagnostics-instruction {
    font-size: 22px;
    opacity: 0.8;
    padding-top: 16px;
  }

  .diagnostics-loader {
    font-size: 24px;
    font-style: italic;
  }

  .diagnostics-loader-dot {
    font-size: 24px;
    margin-top: 10px
  }

  .diagnostics-dot-icon {
    margin-top: 10px
  }

  /* SIDER */

  .custom-sider-width {
    position: fixed;
    height: 100vh;
    z-index: 100;
  }

  .ant-layout-sider-collapsed.custom-sider-width {

  }

`