import React, {useEffect, useMemo, useRef, useState} from "react";
import {CustomKeyBoard} from "components/common";
import {EQuickResultTypes, useSearchResults} from "context/search";
import {KeyboardReactInterface} from "react-simple-keyboard";
import {SearchWrapper} from "./Search.style";
import {ICategory, IProduct} from "types";
import {ProductList} from "components/ProductList";
import {CategoryList} from "components/CategoryList";
import {getImageUrl} from "libs/utils";
import {TagList} from "components/TagList";
import {useGlobalSettings, useUI} from '../../context';
import {useTranslate} from '@refinedev/core';


export const SearchResults: React.FC = () => {
  const t = useTranslate()
  const { quickResult, searchValue, displayQuickResult, quickResults, searchResults, onSelectQuickResult, isScanning,
    onResetIsScanning, onChangeSearchValue, selectedCategoryId, onSelectCategoryId, searchTags, onClose } = useSearchResults();
  const { settings } = useGlobalSettings();

  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([])
  const {openKeyBoard} = useUI();

  useEffect(() => {
    if (quickResult?.type === EQuickResultTypes.tag) {
      setSelectedTagIds([quickResult?.id])
    }
  }, [quickResult])

  const keyboardRef = useRef<KeyboardReactInterface | null>(null)

  const products = useMemo(() => searchResults.find(c => c.id === selectedCategoryId)?.products || searchResults.reduce((a: IProduct[], r: ICategory) => (!r.products ? a :[...a, ...r.products]), []),
    [searchResults, selectedCategoryId])

  useEffect(() => {
    if (!products?.length && isScanning) {
      openKeyBoard()
    }
  }, [products, isScanning])

  return (
    <SearchWrapper>
      <div id={"dropdown-for-quick-answers"}
           className={`dropdown-for-quick-answers ${(!products?.length && isScanning) ? 'opened-dropdown' : 'closed-dropdown'}`}>
        <div className={"dropdown-for-quick-answers-container"}>
          <div className={`dropdown-for-quick-answers-text`}>
            {t("layout.header.empty_search")}
          </div>
        </div>

        {/*{quickResults.map((result, idx) => (*/}
        {/*  <div className={"quick-result"} onClick={() => onSelectQuickResult(result)} key={idx}>*/}
        {/*    {!!result?.image && (*/}
        {/*      <div>*/}
        {/*        <img src={getImageUrl(result?.image)} alt={result?.name}/>*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*    <div>*/}
        {/*      <LocaleName entity={result} langs={settings?.languages || []}/>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*))}*/}
      </div>


      <div className={"product-list-wrapper"}>
        <ProductList selectedCategoryId={selectedCategoryId}
                     settings={settings}
                     selectedTagIds={selectedTagIds}
                     products={products}>
          <div className={"category-list-wrapper"}>
            {(searchResults?.length > 1) && (<CategoryList categories={searchResults || []} selectedCategoryId={selectedCategoryId} onSelectCategory={onSelectCategoryId}/>)}

            {searchTags?.length > 1 && (
              <TagList tags={searchTags} selectedTagIds={selectedTagIds} onSelectTags={setSelectedTagIds}/>
            )}
          </div>
        </ProductList>
      </div>

      <CustomKeyBoard keyboardRef={keyboardRef}
                      value={searchValue}
                      onChange={(value) => {
                        //console.log('isScanning', isScanning)
                        if (isScanning) {
                          if (value?.includes(searchValue)) {
                            //console.log('searchValue', searchValue, value.replace(searchValue, ''));
                            onChangeSearchValue(value.replace(searchValue, ''));
                            onResetIsScanning();
                            //keyboardRef.current?.setInput(value.replace(searchValue, ''), "default");
                          } else {
                            keyboardRef.current?.clearInput("default");
                            onClose();
                          }
                          return;
                        }
                        if (value) {
                          onChangeSearchValue(value)
                        } else {
                          onClose()
                        }
                      }}/>

      {/*<InputForScanning elementId={"search-scanning"} active={(!displayPromoModal && displaySearchResults)} onSubmit={(value) => {*/}
      {/*  onChangeSearchValue(value)*/}
      {/*}}/>*/}
    </SearchWrapper>
  )
}
