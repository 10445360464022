import {ICategory} from "types";
import React, {useEffect, useRef, useState} from "react";
import {CategoriesWrapper, CategoryWrapper} from "./CategoryList.style";
import {getImageUrl} from "libs/utils";
import ScrollContainer from 'react-indiana-drag-scroll'
import {LocaleName} from '../common/LocaleName';
import {useGlobalSettings, useUI} from '../../context';
import {useScrollOnStart} from '../../libs/hooks';


interface ICategoryListProps {
  categories: ICategory[],
  selectedCategoryId?: string | null
  onSelectCategory?: (id: string | null) => void
}

export const CategoryList: React.FC<ICategoryListProps> = React.memo(({ categories, selectedCategoryId, onSelectCategory }) => {
  const { settings } = useGlobalSettings();
  const setRef = useScrollOnStart();

  return (
    <CategoriesWrapper>
      {/*// @ts-ignore*/}
      <ScrollContainer innerRef={r => setRef(r)} className="scroll-container" vertical={false} horizontal={true}>
        {categories?.filter(c => !!c?.productIds?.length).map((category: ICategory) => (
          <div className={"swiper-slide"} key={category.id}>
            <CategoryWrapper imageUrl={getImageUrl(category.image?.src) || 'images/default-category-image.png'} className={selectedCategoryId === category.id ? 'selected-category' : ''}
                             onClick={() => onSelectCategory?.(selectedCategoryId === category.id ? null : category.id)}>
              {!!category.image?.src && (
                <div className={"shape-category-image"}/>
              )}
              <div className={"category-title"}>
                <LocaleName entity={category} langs={settings?.languages || []}/>
              </div>
            </CategoryWrapper>
          </div>
        ))}
      </ScrollContainer>
    </CategoriesWrapper>
  )
})
