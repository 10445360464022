import styled from "styled-components";
import {CustomModalContentWrapper} from "components/ui/styled/common.styles";

export const ProductModalWrapper = styled(CustomModalContentWrapper)`
  padding-block: 60px 80px;
  padding-inline: 60px;
  display: flex;
  max-width: 1000px;

  .product-full-image {
    .image-not-exist {
      background: ${({ theme: { colors } }) => colors.yellow};
    }

    img {
      object-fit: cover;
    }
  }

  .product-info-container {
    margin-inline-start: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .product-title {
      ${({ theme: { fonts } }) => fonts.headerS};
      color: ${({ theme: { colors } }) => colors.textBlack};
      word-break: break-word;
    }

    .product-specifications-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    .product-specifications {
      display: flex;
      flex-direction: column;
      width: 320px;
          
      ${({ theme: { fonts } }) => fonts.headerSBold};

      text-align: center;

      color: ${({ theme: { colors } }) => colors.textBlack};

      .ant-divider {
        color: ${({ theme: { colors } }) => colors.lightGray};
      }

      .flex {
        width: 100%;
        justify-content: center;
      }

      .product-specification {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-inline-end: 48px;
        align-items: center;

        &:last-child {
          margin-inline-end: 0;
        }

        .info-unit {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;

          display: flex;
          align-items: center;
          text-align: center;

          color: ${({ theme: { colors } }) => colors.gray};
        }
      }
    }
  }
  
  .product-counter {
    padding: 11px;
    background: ${({ theme: { colors } }) => colors.green};
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ theme: { fonts } }) => fonts.subheaderXSBolder};

    color: ${({ theme: { colors } }) => colors.white};
    
    span {
      cursor: pointer;
    }
  }
`
