import React, {useEffect, useState} from "react";
import {CustomButton, Modal} from "components/ui";
import {EPaymentMethods, EPaymentSteps} from "types/constants";
import {usePayment} from "context";
import styled from "styled-components";
import {FullScreenModalWrapper} from "components/ui/styled/common.styles";
import {useTranslate} from "@refinedev/core";
import {NumericKeyBoard, NumericKeyboardWrapper} from "components/common/NumericKeyBoard/NumericKeyBoard";


export const YandexFoodCodeModalWrapper = styled(FullScreenModalWrapper)`

  background: ${({theme}) => theme.colors.green};
  justify-content: flex-end;

  .code-container {
    transform: translateY(0);
  }
  
  .code-buttons-wrapper {
    margin-bottom: 36px;
  }
  
  ${NumericKeyboardWrapper} {
    margin-bottom: 24px;
  }
`;


export const YandexFoodCodeModal: React.FC = () => {
  const t = useTranslate()

  const { paymentError, isSuccessPayment, isPaymentLoading, selectedPaymentMethod, paymentStep, onChangePaymentStep,
    onClearPaymentContext,onMakeYandexPayment } = usePayment()
  const [code, setCode] = useState<string>('')

  useEffect(() => {
    return () => {
      setCode('')
    }
  }, [])

  const handleSubmit = async () => {
    await onMakeYandexPayment(code)
    setCode('')
  }

  return (
    <Modal visible={selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_YANDEX_EDA && (paymentStep === EPaymentSteps.FINISH_PAYMENT) &&
      (!isSuccessPayment && !paymentError && !isPaymentLoading)}
           afterClose={() => {
             setCode('')
           }}
           maxWidth={'100vw'} containerClassName={"full-width"} setVisible={onClearPaymentContext}
           closeInContainer={true} wrapClassName={"full-screen-modal"}>
      <YandexFoodCodeModalWrapper>
        <div className={"code-container"}>
          <div className={"code-header"}>
            {t("payment.finish.yandexFood.header")}
          </div>

          <div className={"code-input-wrapper"}>
            {code ? (
              <div className={""}>
                {code}
              </div>
            ) : (
              <div className={"editable-placeholder"}>
                123456
              </div>
            )}
          </div>

          <div className={"code-buttons-wrapper"}>
            <CustomButton className={"gray-button"} onClick={() => onChangePaymentStep(EPaymentSteps.PAYMENT_METHODS)}>
              {t("payment.finish.yandexFood.buttons.cancel")}</CustomButton>
            <CustomButton className={"red-button"} disabled={!code?.length} onClick={() => handleSubmit()}>
              {t("payment.finish.yandexFood.buttons.send")}</CustomButton>
          </div>
        </div>

        <NumericKeyBoard isValid={!!code.length} value={code}  onChange={(v) => setCode(v)}/>
      </YandexFoodCodeModalWrapper>
    </Modal>
  )
}