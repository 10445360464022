import React, {useEffect, useState} from "react";
import {CatalogContainer, CatalogHeader, SectionWrapper} from "./Catalog.style";
import {useTranslate} from "@refinedev/core";
import {IProduct, IPromotion} from "types";
import {Close, Expand} from "components/common/Icons";

import {ProductList} from "components/ProductList";
import {ECatalogMode, useCatalogData, useGlobalSettings, useUI} from "context";
import {CategoryList} from "components/CategoryList";
import {PromotionList} from "components/PromotionList";
import {PopularProductList} from "components/PopularProductList";
import {TagList} from "components/TagList";
import {Skeleton} from "antd";
import {LocaleName} from '../../components/common/LocaleName';


export const Catalog: React.FC = React.memo(() => {
  const t = useTranslate()
  const { displayCatalog, isInactivityUser, catalogMode: mode, setCatalogMode: setMode, closeCatalog, openCatalog} = useUI();
  //const [mode, setMode] = useState<string | null>(null)

  const { categories, promotions, products, tags, popularProducts, isLoading } = useCatalogData();
  const { settings } = useGlobalSettings();

  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null)
  const [selectedPromotion, setSelectedPromotion] = useState<IPromotion | null>(null)
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([])

  const resetMode = () => {
    setMode(null)
    setSelectedCategoryId(null)
    setSelectedTagIds([])
  }

  useEffect(() => {
    if (isInactivityUser) {
      resetMode()
    }
  }, [isInactivityUser])

  useEffect(() => {
    if (displayCatalog) {
      setMode(ECatalogMode.catalog)
    }
  }, [displayCatalog])

  const onCloseCatalog = () => {
    resetMode()
    closeCatalog()
  }

  const onSelectCategory = (id: string | null) => {
    setSelectedCategoryId(id)
    setMode(ECatalogMode.catalog)
  }

  const onClosePromotion = () => {
    resetMode()
    setSelectedPromotion(null)
  }

  const onOpenPromotion = (promo: IPromotion) => {
    setSelectedPromotion(promo)
    setMode(ECatalogMode.promo)
  }

  return (
    <CatalogContainer>
      <SectionWrapper className={`expand-version ${(mode === ECatalogMode.catalog) ? 'opened' : 'closed'}`}>
        <CatalogHeader>
          <span className={"default-header"}>{t('catalog.catalog.header')}</span>
          <span className={"preview-header-icon"} onClick={onCloseCatalog}><Close/></span>
        </CatalogHeader>
        <CategoryList categories={categories || []} selectedCategoryId={selectedCategoryId} onSelectCategory={(id) => setSelectedCategoryId(id)} />
        {!!tags?.length && (<TagList tags={tags} selectedTagIds={selectedTagIds} onSelectTags={setSelectedTagIds}/>)}
        <ProductList settings={settings} selectedTagIds={selectedTagIds} selectedCategoryId={selectedCategoryId} products={!selectedCategoryId ? products || [] : categories.find(c => c.id === selectedCategoryId)?.products || []}>
        </ProductList>
      </SectionWrapper>

      <SectionWrapper className={`expand-version ${(mode === ECatalogMode.popular) ? 'opened' : 'closed'}`}>
        <CatalogHeader>
          <span className={"default-header"}>{t('catalog.popular.header')}</span>
          <span className={"preview-header-icon"} onClick={onCloseCatalog}><Close/></span>
        </CatalogHeader>
        {!!tags?.length && (<TagList tags={tags} selectedTagIds={selectedTagIds} onSelectTags={setSelectedTagIds}/>)}
        <ProductList settings={settings} selectedTagIds={selectedTagIds} products={popularProducts || []}/>
      </SectionWrapper>

      <SectionWrapper className={`expand-version ${(mode === ECatalogMode.promo && selectedPromotion) ? 'opened' : 'closed'}`}>
        <CatalogHeader className={"promotion-name"}>
          <span className={"default-header"}>
            <LocaleName entity={selectedPromotion} langs={settings?.languages || []}/>
          </span>
          <span className={"preview-header-icon"} onClick={onClosePromotion}><Close/></span>
        </CatalogHeader>
        <ProductList settings={settings} products={selectedPromotion?.productIds
          .reduce((arr: IProduct[], pId) => {
            const product = products?.find(p => p.id === pId)
            return product ? [...arr, product] : arr
          }, []) || []
        }/>
      </SectionWrapper>

      <Skeleton loading={isLoading} active>
        {!!promotions?.length && (
          <SectionWrapper className={`preview promotions margin-bottom ${!mode ? 'opened' : 'closed'}`}>
            <CatalogHeader>
              <span className={"default-header"}>{t('catalog.promo.header')}</span>
            </CatalogHeader>
            <PromotionList promotions={promotions || []} onSelectPromotion={onOpenPromotion}/>
          </SectionWrapper>
        )}
      </Skeleton>


      <Skeleton loading={isLoading} active rootClassName={"catalog-skeleton"}>
        <SectionWrapper className={`preview catalog margin-bottom ${!mode ? 'opened' : 'closed'}`}>
          <CatalogHeader>
            <span className={"default-header"}>{t('catalog.catalog.header')}</span>
            <span className={"preview-header-icon"} onClick={openCatalog}><Expand/></span>
          </CatalogHeader>
          <CategoryList categories={categories || []} onSelectCategory={onSelectCategory}/>
        </SectionWrapper>
      </Skeleton>

      <Skeleton loading={isLoading} active rootClassName={"popular-skeleton"}>
        {!!popularProducts?.length && (
          <SectionWrapper className={`preview popular ${!mode ? 'opened' : 'closed'}`}>
            <CatalogHeader>
              <span className={"default-header"}>{t('catalog.popular.header')}</span>
              <span className={"preview-header-icon"} onClick={() => setMode(ECatalogMode.popular)}><Expand/></span>
            </CatalogHeader>
            {/*// @ts-ignore*/}
            <PopularProductList products={popularProducts || []}/>
          </SectionWrapper>
        )}
      </Skeleton>
    </CatalogContainer>
  );
});



