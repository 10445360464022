import React, {useState} from "react";
import {PromoCodeTipWrapper} from "./PromoCodeTip.style";
import {useTranslate} from "@refinedev/core";
import {useCartContext, useUI} from "context";
import {ConfirmResetCartModalWrapper} from "containers/Cart/Cart.style";
import {Modal, CustomButton} from "components/ui";


export const PromoCodeTip: React.FC = () => {

  const t = useTranslate()
  const [clearConfirm, setClearConfirm] = useState(false)
  const { togglePromoModal } = useUI();
  const { selectedPromoCode, promoCodeError, onChangePromoCode, onSetPromoCodeError, onApplyPromoCode } = useCartContext()

  const onClearPromoCode = async () => {
    onChangePromoCode('')
    onSetPromoCodeError(null)
    await onApplyPromoCode('')
    setClearConfirm(false)
  }

  return (
    <PromoCodeTipWrapper>
      {selectedPromoCode ? (
        <div className={"promo-tip-container"}>
          {!promoCodeError ? (
            <div className={"promo-tip-title"}>{t('cart.promoAds.selectedPromocode.description')}</div>
          ) : (
            <div className={"promo-tip-title"}>{t('cart.promoAds.selectedPromocode.error')}</div>
          )}

          <CustomButton onClick={() => setClearConfirm(true)} className={"white-button small-button"}>{t('cart.promoAds.selectedPromocode.button')}</CustomButton>
        </div>
      ) : (
        <div className={"promo-tip-container"}>
          <div className={"promo-tip-title"}>{t('cart.promoAds.description')}</div>
          <CustomButton onClick={() => togglePromoModal()} className={"white-button small-button"}>{t('cart.promoAds.button')}</CustomButton>
        </div>
      )}

      <div className={"promo-tip-img-container"}>
        <img className={"promo-tip-img"} src={"images/promo-percent.png"}/>
      </div>

      <Modal visible={clearConfirm} setVisible={() => setClearConfirm(false)}>
        <ConfirmResetCartModalWrapper className={"small-width"}>
          <div className={"confirm-title"}>
            {t("cart.clearPromoCode.title")}
          </div>

          <div className={"confirm-description"}>
            {t("cart.clearPromoCode.description", {promoCode: selectedPromoCode})}
          </div>

          <div className={"confirm-buttons"}>
            <CustomButton className={"gray-button"} onClick={() => setClearConfirm(false)}>{t("cart.clearPromoCode.buttons.cancel")}</CustomButton>
            <CustomButton className={"red-button"} onClick={onClearPromoCode}>{t("cart.clearPromoCode.buttons.confirm")}</CustomButton>
          </div>
        </ConfirmResetCartModalWrapper>
      </Modal>
    </PromoCodeTipWrapper>
  )
}