import styled from "styled-components";

export const LayoutWrapper = styled.section<{
  displaySearchResults?: boolean,
  isInactivityUser?: boolean
}>`
  
  background-image: url("/images/b269d9a8f85c3b751dae839011dd7aa5.png");
  max-height: 100%;
  min-height: 100vh;
  position: relative;
  background-size: cover;

  display: flex;
  flex-direction: column;
  
  padding-bottom: ${({ displaySearchResults }) => displaySearchResults ? '0' : '0' };
  
  .video-plug {
    position: fixed;
    overflow: hidden;
    z-index: 1050;
    top: 0;
    inset-inline-start: 0;
    
    height: 100vh;
    width: 100vw;
    
    background: ${({ theme: { colors } }) => colors.green};
    display: ${({isInactivityUser}) => isInactivityUser ? 'block' : 'none'};
    
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .red-text {
    color ${({ theme: { colors } }) => colors.red}
  }

  .image-not-exist {
    background: ${({ theme: { colors } }) => colors.yellow};
  }

  .default-header {
    ${({ theme: { fonts } }) => fonts.headerSBold};
    color: ${({ theme: { colors } }) => colors.white};
  }

  .default-text {
    ${({ theme: { fonts } }) => fonts.subheaderSBolder};
    letter-spacing: 0;
    color: ${({ theme: { colors } }) => colors.white};
  }
  
  .dark-background {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 40px;
  }

`;
