import React from "react";
import {SearchbarWrapper} from "./Searchbar.style";
import {useTranslate} from "@refinedev/core";
import {useUI, useSearchResults} from "context";
import {Close, Search} from "components/common/Icons";

interface SearchbarProps {
  id?: string;
}

export const Searchbar: React.FC<SearchbarProps> = ({
                                                      id = "search",
                                                    }) => {

  const t = useTranslate()

  const {displaySearchResults, openKeyBoard, closeKeyBoard, toggleDisplaySearchResults} = useUI();
  const {searchValue, onClose, displayQuickResult, onChangeToggleDisplayQuickResult} = useSearchResults()

  const onDisplaySearchResult = () => {
    if (!displaySearchResults) {
      toggleDisplaySearchResults(true)
      openKeyBoard()
    }
    if (!displayQuickResult) {
      onChangeToggleDisplayQuickResult()
    }
  }

  const onCloseSearchResult = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    onClose()
    closeKeyBoard()
    toggleDisplaySearchResults(false)
  }

  return (
    <SearchbarWrapper showOver={!!displaySearchResults} onClick={() => onDisplaySearchResult()}>
      <div className={"go-input-affix-wrapper"}>
        <div className={"go-input-prefix"}>
          <Search/>
        </div>
        <div className={"go-input"}>
          {searchValue || t("layout.header.search")}
        </div>

        {!!displaySearchResults && (
          <div className={"go-input-suffix"} onClick={onCloseSearchResult}>
            <Close/>
          </div>
        )}
      </div>
    </SearchbarWrapper>
  );
};
