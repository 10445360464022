import React, {useEffect, useRef, useState} from "react";
import {useCustomMutation, useTranslate} from "@refinedev/core";
import {HeaderModalWrapper} from "./Header.style";
import {CustomButton} from "components/ui";
import {useGlobalSettings, usePayment, useUI} from "context";
import urlJoin from "url-join";
import {API_URL} from "types/constants";
import {RedSend} from "components/common/Icons";
import {CustomKeyBoard, Loader} from "components/common";
import { QRCode } from "antd";
import {BonusCardAuth} from "components/BonusCardAuth";
import {useGetBrandName} from "libs/hooks";
import {KeyboardReactInterface} from 'react-simple-keyboard';


export const BonusModal: React.FC<{onClose: () => void}> = ({ onClose }) => {
  const t = useTranslate();

  const brandName = useGetBrandName();
  const { settings } = useGlobalSettings();
  const { isErrorBonusCard, isCheckingBonusCardNumber } = usePayment()

  return (
    <HeaderModalWrapper className={"bonus-info"}>
      <div className={"modal-header"}>{t("modal.bonus.authHeader")}</div>

      {!isErrorBonusCard || isCheckingBonusCardNumber ? (
        <div className={"modal-text first-banner-text"}>{t(`payment.methods.description.${settings?.bonusSystemType}Login`, {
          brandName,
          interpolation: {
            escapeValue: false,
          }
        })}</div>
      ) : (
        <div className={"modal-text first-banner-text error-message"}>{t("payment.methods.errors.bonusCardNotFound")}</div>
      )}

      <BonusCardAuth isLogin={true} displayKeyboard={true} onChangeDisplayKeyboard={() => {}}/>
      {/*<div className={"bonus-banner"}>*/}
      {/*  <div className={"bonus-text-wrapper"}>*/}
      {/*    <div className={"banner-header"}>{t("modal.bonus.banner_title")}</div>*/}
      {/*    <div className={"banner-text"}>{t("modal.bonus.banner_info")}</div>*/}
      {/*  </div>*/}
      {/*  <div className={"qr-code-image"}>*/}
      {/*    <QRCode value={BONUS_QR_CODE_LINK} size={184}*/}
      {/*            errorLevel="H"*/}
      {/*            icon={"images/logo-color.svg"}*/}
      {/*            iconSize={54}*/}
      {/*            style={{*/}
      {/*              height: '100%',*/}
      {/*              width: '100%',*/}
      {/*              border: 0,*/}
      {/*              padding: '20px'*/}
      {/*            }}/>*/}
      {/*    /!*<img src={"images/qr-code.png"} alt={"code"}/>*!/*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className={"modal-text"}>*/}
      {/*  {t("modal.bonus.sony_one")}<span className={"red-text"}>{t("modal.bonus.sony_link")}</span>{t("modal.bonus.sony_two")}</div>*/}
      {/*<div className={"modal-text"} style={{marginTop: '24px'}}>{t("modal.bonus.link_text")}</div>*/}

      <CustomButton className={"red-button"} wrapClassName={"wrap-absolute"} onClick={onClose}>
        {t("modal.buttons.cancel")}
      </CustomButton>
    </HeaderModalWrapper>
  );
}

export const TermsOfUseModal: React.FC<{onClose: () => void}> = ({ onClose }) => {
  const t = useTranslate();
  return (
    <HeaderModalWrapper className={"term-of-use-info"}>
      <div className={"term-of-use-header modal-header"}>{t("modal.termOfUse.header")}</div>
      <div className={"term-of-use-content modal-text"}>
        {[0, 1, 2, 3].map((i) => (
          <p>
            <span className={"modal-subheader"}>{t(`modal.termOfUse.content.${i}.title`)}</span>
            <span>{t(`modal.termOfUse.content.${i}.description`)}</span>
          </p>
        ))}
      </div>

      <CustomButton className={"red-button"} wrapClassName={"wrap-absolute"} onClick={onClose}>
        {t("modal.buttons.confirm")}
      </CustomButton>
    </HeaderModalWrapper>
  );
}

export const TerminalNumberModal: React.FC<{onClose: () => void}> = ({ onClose }) => {
  const t = useTranslate();
  const {settings} = useGlobalSettings();
  const brandName = useGetBrandName();

  return (
    <HeaderModalWrapper className={"terminal-info"}>
      <div className={"terminal-info-header modal-header"}>{t("modal.terminalNumber.header")}</div>
      <div className={"terminal-info-description modal-text"}>
        {t("modal.terminalNumber.description", {
          brandName,
          interpolation: {
            escapeValue: false,
          }
        })}
      </div>

      <div className={"terminal-info-content"}>
        <QRCode size={396}
                errorLevel="H"
                value={settings?.micromarketId || ''}
                type={"svg"}
                icon={"images/logo-color.svg"}
                iconSize={116}
                style={{
                  direction: 'ltr',
                  height: '100%',
                  width: '100%',
                  borderRadius: 0,
                  border: 0,
                  padding: '6px'
                }}
        />
      </div>

      <CustomButton className={"red-button"} wrapClassName={"wrap-absolute"} onClick={onClose}>
        {t("modal.buttons.close")}
      </CustomButton>
    </HeaderModalWrapper>
  );
}

export const ContactModal: React.FC<{onClose: () => void}> = ({ onClose }) => {
  const t = useTranslate();
  const keyboardRef = useRef<KeyboardReactInterface | null>(null)
  const [message, setMessage] = useState<string>('')
  const { settings } = useGlobalSettings();

  const {mutateAsync: onSendEmail, isLoading, isSuccess, reset} = useCustomMutation()
  const {openKeyBoard, closeKeyBoard} = useUI();

  useEffect(() => {
    return () => {
      _closeModal()
    }
  }, [])

  const handleSupportMailSend = async (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    await onSendEmail({
      url: urlJoin(API_URL, '/send_mail/'),
      method: 'post',
      values: {
        message
      }
    })
  }

  const _closeModal = () => {
    setMessage('')
    closeKeyBoard()
    reset()
    onClose()
  }

  return (
    <>
      <HeaderModalWrapper className={"contact-info"}>
        <div className={"modal-header"}>{t("modal.contact.header")}</div>
        <div className={"modal-text"}>
          {settings?.contacts?.email && (
            <div className={"contact-block"}>
              <div className={"contact-qr-code"}>
                <QRCode size={84}
                        errorLevel="H"
                        value={`mailto:${settings?.contacts?.email}`}
                        type={"svg"}
                        icon={"images/red-email.svg"}
                        iconSize={18}
                        style={{
                          direction: 'ltr',
                          height: '100%',
                          width: '100%',
                          borderRadius: 0,
                          border: 0,
                          padding: '6px'
                        }}
                />
                {/*<img src={"images/qr-email.png"} alt={"code"}/>*/}
              </div>
              <div className={"contact-link"}>{settings?.contacts?.email}</div>
            </div>
          )}

          {settings?.contacts?.whatsapp && (
            <div className={"contact-block"}>
              <div className={"contact-qr-code"}>
                <QRCode value={`${settings?.contacts?.whatsappFull}`} size={84}
                        errorLevel="H"
                        type={"svg"}
                        icon={"images/whatsapp-green.svg"}
                        iconSize={22}
                        style={{
                          direction: 'ltr',
                          height: '100%',
                          width: '100%',
                          borderRadius: 0,
                          border: 0,
                          padding: '6px'
                        }}/>
                {/*<img src={"images/whatsapp_qr.jpg"} alt={"code"}/>*/}
              </div>
              <div className={"contact-link"}>{settings?.contacts?.whatsapp}</div>
            </div>
          )}

          {settings?.contacts?.telegram && (
            <div className={"contact-block"}>
              <div className={"contact-qr-code"}>
                <QRCode value={`${settings?.contacts?.telegramFull}`} size={84}
                        errorLevel="H"
                        type={"svg"}
                        icon={"images/blue-tg.svg"}
                        iconSize={22}
                        style={{
                          direction: 'ltr',
                          height: '100%',
                          width: '100%',
                          borderRadius: 0,
                          border: 0,
                          padding: '6px'
                        }}/>
                {/*<img src={"images/qr-tg.png"} alt={"code"}/>*/}
              </div>
              <div className={"contact-link"}>{settings?.contacts?.telegram}</div>
            </div>
          )}

          {settings?.contacts?.phone && (
            <div className={"contact-block call-contact"}>
              <div className={"contact-text"}>{t("modal.contact.freeCall")}</div>
              <div className={"contact-link"}>{settings?.contacts?.phone}</div>
            </div>
          )}

          {isSuccess ? (
            <div className={"success-email-sending"}>
              {t('layout.header.contact.successSending')}
            </div>
          ) : (
            <div className={"contact-block large"} onClick={openKeyBoard}>
              <div className={"go-input"}>
                {message || t('layout.header.contact.mailPlaceholder')}
              </div>
              {isLoading ? (
                <span className={"contact-send-icon"}>
                  <Loader/>
                </span>
              ) : (
                <span className={"contact-send-icon"} onClick={handleSupportMailSend}>
                  <RedSend/>
                </span>
              )}
            </div>
          )}

        </div>

        <CustomButton className={"red-button"} wrapClassName={"wrap-absolute"} onClick={onClose}>
          {t("modal.buttons.close")}
        </CustomButton>

        <CustomKeyBoard keyboardRef={keyboardRef} onChange={setMessage}/>
      </HeaderModalWrapper>
    </>
  );
}
