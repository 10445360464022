import {
  ISettings,
  useCartContext,
  useCatalogData,
  useGlobalSettings,
  usePayment,
  useSearchResults,
  useUI
} from "context";
import {useEffect, useMemo, useState} from "react";
import {EBonusSystemType, EPaymentSteps} from '../types/constants';
import {ICart} from '../types';
import {normalizeCurrencyValue} from './utils';
import {useGetLocale} from '@refinedev/core';


export const useGlobalClean = () => {
  const { resetUIState } = useUI();
  const { onRefetchGlobalSettings } = useGlobalSettings();
  const { onRefetchCatalogData } = useCatalogData();
  const { onCloseDisplayPayment } = usePayment();
  const { onSearchContextClear } = useSearchResults();
  const { onResetCart } = useCartContext()

  const handleDataClean = async (resetState = true) => {
    if (resetState) {
      resetUIState()
    }
    await onResetCart()
    onCloseDisplayPayment()
    await onRefetchGlobalSettings()
    onSearchContextClear()
    await onRefetchCatalogData?.()
  }

  return useMemo(() => ({
    onRefreshData: handleDataClean,
    onRefetchCatalogData: onRefetchCatalogData
  }), [])
}

export const useScrollOnStart = (type = '') => {
  let [ref, setRef] = useState<any>(null);
  //const { isInactivityUser } = useUI();

  // useEffect(() => {
  //   return () => {
  //     ref?.scroll({
  //       left: 0,
  //       behavior: 'smooth'
  //     })
  //   }
  // }, [ref])

  // useEffect(() => {
  //   if (ref && isInactivityUser) {
  //     ref?.scroll({
  //       left: 0,
  //       behavior: 'smooth'
  //     })
  //   }
  // }, [ref, isInactivityUser])

  return setRef
}

export const getMaxSliderBonusesValue: any = (cart: ICart, settings: ISettings, allBonus: number ) => {
  const minPaymentAmount = settings?.payWithBonusPointsOnly ? 0 : settings?.minPaymentAmount && cart?.total_amount < settings?.minPaymentAmount ? cart?.total_amount : settings?.minPaymentAmount;

  const ifBonusMore = cart?.total_amount - minPaymentAmount
  const ifBonusLess = cart?.total_amount <= settings?.minPaymentAmount ? 0 : allBonus || 0

  //debugger;

  return normalizeCurrencyValue(cart?.total_amount < allBonus ? ifBonusMore : ifBonusLess)
}

export const useGetMaxBonusesValue = () => {
  const { settings } = useGlobalSettings();
  const { cart } = useCartContext()

  const { bonusCard, selectedPaymentMethod, onChangeSpentBonusCount } = usePayment();

  const maxSliderValue = useMemo(() =>
      getMaxSliderBonusesValue(cart, settings, bonusCard.allBonus),
    [cart, bonusCard, settings]);

  const payWithMaxBonuses = useMemo(() =>
      (settings?.obedruDisplayMaxBonusesByDefault && (selectedPaymentMethod === EBonusSystemType.OBEDRU || (settings.bonusSystemType === EBonusSystemType.OBEDRU))) ||
      (settings?.goPoedimDisplayMaxBonusesByDefault && (selectedPaymentMethod === EBonusSystemType.GOPOEDIM || (settings.bonusSystemType === EBonusSystemType.GOPOEDIM))),
    [selectedPaymentMethod, settings]);

  const canFullPayment = useMemo(() =>
    settings?.payWithBonusPointsOnly ? bonusCard?.allBonus >= cart.total_amount : cart?.total_amount - maxSliderValue === 0,
    [cart, maxSliderValue, settings]);

  useEffect(() => {
    if (settings?.payWithBonusPointsOnly && bonusCard?.cardId) {
      onChangeSpentBonusCount(bonusCard?.allBonus >= cart.total_amount ? cart.total_amount : 0)
    }
  }, [settings, bonusCard, cart])


  useEffect(() => {
    if (payWithMaxBonuses) {
      onChangeSpentBonusCount(maxSliderValue)
    }
  }, [payWithMaxBonuses, maxSliderValue])


  return {
    maxSliderValue,
    canFullPayment,
    payWithMaxBonuses
  }
}

export const useGetBrandName = () => {
  const locale = useGetLocale();
  const currentLocale = locale();
  const { settings } = useGlobalSettings();

  // @ts-ignore
  const countryBrandsName: any = {
    RUS: {
      'ar' : 'eat&go',
      'en' : 'eat&go',
      'sr' : 'eat&go',
      'ru' : 'Го!Поедим',
    },
    SRB: {
      'ar' : 'eat&go',
      'en' : 'eat&go',
      'sr' : 'eat&go',
      'ru' : 'eat&go',
    },
    UAE: {
      'ar' : 'Go Eat',
      'en' : 'Go Eat',
      'sr' : 'Go Eat',
      'ru' : 'Go Eat',
    },
  }[settings?.country || "RUS"] as any;

  return !currentLocale ? 'eat&go' : countryBrandsName[currentLocale];
}
