import styled from "styled-components";
import {ButtonWrapper} from "components/ui";


export const PromoCodeTipWrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.red};
  border-radius: 24px;
  min-height: 170px;
  height: 100%;
  overflow: hidden;
  
  position: relative;
  display: flex;
  
  .promo-tip-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    padding: 16px 20px 20px;
    padding-block: 16px 20px;
    padding-inline: 20px;
    z-index: 3;
  }

  ${ButtonWrapper}  {
    .white-button {
      padding-block: 8px;
      padding-inline: 12px;
      border-radius: 12px;

      ${({ theme: { fonts } }) => fonts.captionXS};
    }
  }

  
  .promo-tip-title {
    ${({ theme: { fonts } }) => fonts.subheaderSBolder};
    color: ${({ theme: { colors } }) => colors.white};    
    white-space: pre-wrap;
    flex: 1;
  }
  
  .promo-tip-img-container {    
    position: absolute;
    inset-inline-end: -21px;
    bottom: -22px;
    min-width: 155px;
    border-radius: 100px;
    display: flex;
    align-items: flex-end;
    z-index: 1;
  }
  
  .promo-tip-img {
    width: 100%;
    height: 155px;
  }
`;