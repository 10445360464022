import {IPromotion} from "types";
import React from "react";
import {useTranslate} from "@refinedev/core";
import {PromotionsWrapper, PromotionWrapper} from "./PromotionList.style";
import {CustomButton} from "components/ui/Button";
import {getImageUrl} from "libs/utils";
import ScrollContainer from "react-indiana-drag-scroll";
import {LocaleName} from '../common/LocaleName';
import {useGlobalSettings} from '../../context';
import {useScrollOnStart} from '../../libs/hooks';


interface IPromotionListProps {
  promotions: IPromotion[],
  onSelectPromotion: (promotion: IPromotion) => void
}

export const PromotionList: React.FC<IPromotionListProps> = React.memo(({ promotions, onSelectPromotion }) => {
  const t = useTranslate()
  const { settings } = useGlobalSettings();
  const setRef = useScrollOnStart();

  return (
    <PromotionsWrapper>
      {/*// @ts-ignore*/}
      <ScrollContainer innerRef={r => setRef(r)} className="scroll-container" vertical={false} horizontal={true}>
        {promotions.map((promotion: IPromotion) => (
          <div className={"swiper-slide"} key={promotion?.id}>
            <PromotionWrapper onClick={() => onSelectPromotion?.(promotion)} imageUrl={getImageUrl(promotion?.imageUrl)}>
              <div className={"promotion-container"}>
                <div className={"promotion-title"}>
                  {!promotion?.imageUrl && (
                    <LocaleName entity={promotion} langs={settings?.languages || []}/>
                  )}

                </div>
                {/*<CustomButton onClick={() => onSelectPromotion?.(promotion)} className={"white-button"}>{t('buttons.show')}</CustomButton>*/}
              </div>

              {/*{!!promotion?.imageUrl && (*/}
              {/*  <div className={"promotion-img-container"}>*/}
              {/*    <img className={"promotion-img"} src={promotion?.imageUrl} alt={promotion?.name}/>*/}
              {/*  </div>*/}
              {/*)}*/}

            </PromotionWrapper>
          </div>
        ))}
      </ScrollContainer>
    </PromotionsWrapper>
  )
})
