import styled from "styled-components";
import {CustomModalContentWrapper} from "components/ui/styled/common.styles";
import {ProductsWrapper} from "components/ProductList/ProductList.style";
import {ButtonWrapper} from "components/ui";

export const CartProductListWrapper = styled.div`
  margin-top: 24px;
  overflow: hidden auto;
  padding-bottom: 36px;
  height: 100%;
  
  margin-inline-end: -14px;
  padding-inline-end: 6px;
  
  .product-card-in-cart {
    max-height: content-box;
  }
  
  .row-with-a-cards {
    width: 100%;
    margin: 0!important;
  }
  
  .content-wrapper {
    min-height: 100%;
    flex-direction: column;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(255 255 255 / 60%);
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 36px;
  }
`

export const CartBonusTooltipWrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.violet};
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  
  max-width: unset;
  min-width: auto;
  height: 100%;
  min-height: 170px;
  width: 100%;
  
  margin-inline-end: 8px;
  cursor: pointer;

  display: flex;
  
  .cart-bonus-tooltip-container {
    display: flex;
    flex: 1;
    padding-block: 20px;
    padding-inline: 16px;
    flex-direction: column;
    height: 100%;
    max-width: 265px;
    z-index: 3;
  }

  ${ButtonWrapper}  {
    .violet-button {
      padding-block: 8px;
      padding-inline: 12px;
      border-radius: 12px;

      ${({ theme: { fonts } }) => fonts.captionXS};
    }
  }

  .cart-bonus-tooltip-title {
    ${({ theme: { fonts } }) => fonts.subheaderSBolder};
    color:  ${({ theme: { colors } }) => colors.white};
    white-space: pre-wrap;
    flex: 1;
  }

  .cart-bonus-tooltip-img-container {
    position: absolute;
    height: 100%;
    inset-inline-end: 0;
    bottom: 0;
    min-width: 155px;
    display: flex;
    align-items: flex-end;
    z-index: 1;
    transform:  ${({theme: {rtl}}) => rtl ? 'scale(-1, 1)' : 'scale(1, 1)'};
  }

  .cart-bonus-tooltip-img {
    width: 100%;
    height: 100%;
    max-width: 256px;
    object-fit: contain;
  }
`;

export const CartPromotionProductListModalWrapper = styled(CustomModalContentWrapper)`
  padding-block: 0;
  padding-inline: 8px;
  max-height: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .modal-header {
    padding-block: 50px 24px;
    padding-inline: 64px;
  }
  
  ${ProductsWrapper}  {
    padding-block: 0 136px;
    padding-inline: 30px 65px;
    overflow: auto;

    @media (max-width: 1090px) {
      padding-block: 0 136px;
      padding-inline: 6px 24px;
    }

    .modal-subheader {
      display: block;
      margin-bottom: 12px;
    }

    p {
      margin-bottom: 44px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 40px;
    }
  }
`

export const CartProductWrapper = styled.div<{
  isDiscount?: boolean
}>`
  padding: 20px;
  background: ${({ theme: { colors } }) => colors.white};
  border-radius: 24px;
  display: flex;    
  //max-height: 170px;
  min-height: 170px;
  
  height: 100%;
  
  .product-img, .image-not-exist {
    border-radius: 8px;
  }
  
  .removed-button {
    cursor: pointer;
    position: absolute;
    width: 15px;
    height: 15px;
    inset-inline-end: 20px;
    top: 20px;

    /* gp-gray-50 */

    color: ${({ theme: { colors } }) => colors.gray};
    
    svg {
      width: 100%;
      height: 100%;
    }
  }
  

  .cart-product-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    .cart-product-header {
      display: flex;
      flex: 1;
    }
    
    .cart-product-content-info {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    
    .product-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: ${({ theme: { colors } }) => colors.textBlack};
      margin-bottom: 2px;
      margin-inline-end: 12px;
      word-break: break-word;
    }
    
    .product-info {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      color: ${({ theme: { colors } }) => colors.gray};
      
      flex: 1
    }
    
    .manage-price-container {
      padding-inline-start: ${({ isDiscount }) => isDiscount ? '0' : '80px'};
      margin-top: 24px;
      display: flex;
      align-items: center;

      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      
      .price-with-discount-wrapper {
        min-width: 9vw;
      }
      
      @media (max-width: 1440px) {
        padding-inline-start: 0;
      }

      @media (max-width: 1199px) {
        padding-inline-start: ${({ isDiscount }) => isDiscount ? '0' : '80px'};
        .price-with-discount-wrapper {
          min-width: 17vw;
        }
      }

      @media (max-width: 840px) {
        padding-inline-start: 0;
      }

      @media (max-width: 767px) {
        padding-inline-start: ${({ isDiscount }) => isDiscount ? '0' : '80px'};
        .price-with-discount-wrapper {
          min-width: 40vw;
        }
      }
      
      .product-price {
        flex: 1;
        margin-inline-end: 12px;
        color: ${({ theme: { colors } }) => colors.textBlack};

        &.discount-price {
          flex: 0;
          color: ${({ theme: { colors } }) => colors.green};
          margin-inline-end: 14px;
        }

        &.old-price {
          flex: 0;
          margin-inline-end: 24px;
          text-decoration-line: line-through;
          color: ${({ theme: { colors } }) => colors.gray};
        }
      }
      
      .counter-container {
        display: flex;
        align-items: center;

        .counter-button {
          cursor: pointer;
          width: 40px;
          height: 40px;
          background: ${({ theme: { colors } }) => colors.lightGray};
          border-radius: 20px;
          
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .counter-text {
          color: ${({ theme: { colors } }) => colors.textBlack};
          margin: 0 12px;
        }
      }
    }
  }
`
