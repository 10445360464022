import styled from "styled-components";


export const ProductWrapper = styled.div<{
  isSmall: boolean
}>`
  cursor: pointer;
  width: ${({isSmall}) => isSmall ? '150px' : '166px'};
  height: ${({isSmall}) => isSmall ? '280px' : '342px'};
  position: relative;
  overflow: hidden;

  background: rgba(0, 0, 0, 0.3);
  border-radius: 24px;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color:  ${({ theme: { colors } }) => colors.white};
  
  margin-block: 0 8px;
  margin-inline: 4px;
  
  .product-info-container {
    overflow: auto;
    height: 100%;
    
    padding-inline: 20px;  
    padding-block: 20px 62px;
    
    &::-webkit-scrollbar {
      width: 1px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    
    .product-image {
      width: 126px;
      height: 126px;
      min-height: 126px;
      margin-block-end: 14px;
      position: relative;
    }

    .product-name {
      margin-block-end: 8px;
      word-break: break-word;
    }

    .product-info {
      opacity: 0.6;    
      display: flex;
      flex-direction: column;
    }

  }
  
  .product-price {
    background: ${({ theme: { colors } }) => colors.green};
    padding-block: 14px 16px;
    
    position: absolute;
    width: 100%;
    bottom: 0;

    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color:  ${({ theme: { colors } }) => colors.white};
  }

  .product-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    
    background: ${({ theme: { colors } }) => colors.white};
    padding: 6px 0;

    position: absolute;
    width: 100%;
    bottom: 0;

    font-size: 20px;
    font-weight: 600;
    line-height: 24px;

    text-align: center;
    color: ${({ theme: { colors } }) => colors.textBlack};
    
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    
    .count {
      margin: 0 12px;
      cursor: auto;
    }
  }
`
