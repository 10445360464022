import styled from "styled-components";

export const SearchbarWrapper = styled.section<{
  showOver: boolean
}>`
  display: flex;
  z-index: ${({showOver}) => showOver ? 2 : 0};   
  width: 100%;
  //justify-content: flex-end;

  .go-input-affix-wrapper {
    display: flex;
    align-items: center;
    max-width: 1000px;
    width: 100%;
    height: 64px;
    
    background: rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    border: none;
    padding: 0 20px 0 36px;

    //@media (max-width: 1360px) {
    //  width: 45vw;
    //}
    //
    //@media (max-width: 810px) {
    //  width: 40vw;
    //}
    //
    //@media (max-width: 760px) {
    //  width: calc(100vw - 126px);
    //}
  }
  
  .go-input-prefix {
    display: flex;
    padding-inline-end: 32px;
    
    @media (max-width: 990px) {
      padding-inline-end: 16px;
    }

    @media (max-width: 820px) {
      padding-inline-end: 11px;
    }
  }
  
  .go-input-suffix {
    display: flex;
    padding-inline-start: 20px;
    cursor: pointer;
  }
  
  .go-input {
    background: transparent;
    width: 100%;
    ${({ theme: { fonts } }) => fonts.subheaderS};
    letter-spacing: 0;
    color: rgba(255, 255, 255, 0.6);
    
    border: 0;
    
    &:focus {
      border: 0;
    }
    
  }
  
  //.go-input:focus {
  //  text-align: left;
  //}

  input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
`;
